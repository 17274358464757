<template>
    <section class="section">
        <div class="container">
            <CountOrderStatuses/>
            <PageHeader :title="lastTitle">
                <div class="field">
                    <div class="control has-text-centered">
                        <div class="select">
                            <select name="range" :value="$route.query.range || defaultRange" @change="changeRange">
                                <option value="day">Дневно ниво</option>
                                <option value="week">Неделно ниво</option>
                                <option value="month">Месечно ниво</option>
                                <option value="year">Годишно ниво</option>
                                <option value="all">Секогаш</option>
                            </select>
                        </div>
                    </div>
                </div>
            </PageHeader>
            <LastXStatistics :stats="stats"/>
            <div class="mb-6 mt-6"></div>
            <HomeGraphs :stats="stats"/>
        </div>
    </section>
</template>

<script>
    import CountOrderStatuses from "../components/Home/CountOrderStatuses";
    import LastXStatistics from "../components/Home/LastXStatistics";
    import HomeGraphs from "../components/Home/HomeGraphs";
    export default {
        name: "Home",
        components: {HomeGraphs, LastXStatistics, CountOrderStatuses},
        data(){
            return{
                defaultRange:'week',
                stats:null,
            }
        },
        computed:{
            lastTitle:function () {
                let ret = "Статистика за ";
                const sw = this.$route.query.range || this.defaultRange;
                switch (sw) {
                    case 'day': ret+= "денес"; break;
                    case 'week': ret+= "оваа недела"; break;
                    case 'month': ret+= "овој месец"; break;
                    case 'year': ret+= "оваа година"; break;
                    case 'all': ret+= "целото постоење"; break;
                }
                return ret;
            }
        },
        methods:{
            changeRange(e){
                let q= {range:e.target.value};
                if(e.target.value === this.defaultRange) q =null;
                this.$router.push({name: 'secured', query: q})
            },
            fetchStats() {
                this.stats = null
                this.$http.get("/stats" + this.$urify(this.$route.query))
                    .then(a => {
                        this.stats = a.data.data;
                    })
            }
        },

        created() {
            this.fetchStats();
        },
        watch: {
            $route: function (to, fr) {
                if (to.name === fr.name && to.query.range !== fr.query.range)
                    this.fetchStats();
            }
        }
    }
</script>