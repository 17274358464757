<template>
    <Message @closed="$router.push({name:'types'})" variant="is-danger">
        <h2 class="subtitle">Дали сте сигурни дека сакате да го избришите селектираниот асортиман?</h2>
        <div class="buttons">
            <button class="button is-danger is-light" v-on:click="deleteSelected">Да</button>
            <button class="button is-success is-light" v-on:click="$router.push({name:'types'})">Не</button>
        </div>
    </Message>
</template>

<script>
    export default {
        name: "DeleteTypeModal",

        methods:{
            deleteSelected() {
                this.$store.dispatch("removeType", this.$route.params.typeId).then(() => {
                    this.$router.push({name:"types"});
                })
            },
        }
    }
</script>