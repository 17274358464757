<template>
    <Modal  title="Нов продукт" @close="$router.replace({name:'products'})">
        <div class="field">
            <label class="label">Назив</label>
            <div class="control">
                <input class="input" :value="product.name" @input="product.name = $l2c($event.target.value)" type="text" placeholder="Ајвар">
            </div>
        </div>

        <div class="field">
            <label class="label">Опис</label>
            <div class="control">
                        <textarea rows="3" class="input" :value="product.description" @input="product.description = $l2c($event.target.value)"
                                  placeholder="Опис за продуктот"></textarea>
            </div>
        </div>
        <template v-slot:footer>
            <button class="button is-success" v-on:click="submitProduct">Зачувај</button>
        </template>
    </Modal>

</template>

<script>

    export default {
        name: "CreateProduct",
        data(){
            return {
                product:{
                    name:"",
                    description:""
                }
            };
        },
        methods:{
            submitProduct() {
                this.$store.dispatch("submitNewProduct", this.product).then(() => {
                    this.$router.replace({name:'products'})
                })
            },
        }
    }
</script>

<style scoped>

</style>