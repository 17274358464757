<template>
    <Modal title="Креирај асортиман" @close="$router.push({name:'types'})">

        <div class="field">
            <label class="label">Назив</label>
            <div class="control">
                <input class="input" :value="type.name" @input="type.name = $l2c($event.target.value)" type="text" placeholder="Малку лут">
            </div>
        </div>

        <template v-slot:footer>
            <button class="button is-success" v-on:click="submitType">Зачувај</button>
        </template>
    </Modal>
</template>

<script>

    export default {
        name: "CreateTypeModal",
        data(){
            return {
                type:{
                    name:"",
                }
            };
        },
        methods: {
            submitType() {
                // const action = this.modal.type.id ? "editType" : "submitNewType";
                this.$store.dispatch("submitNewType", this.type).then(() => {
                    this.$router.push({name:"types"});
                })
            }
        }
    }
</script>

<style scoped>

</style>