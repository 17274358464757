<template>
  <Message @closed="$emit('update:show', false)" variant="is-white">
    <form @submit.prevent="submit">
    <h5 class="title is-5">Сихноризирај нарачка со Брза Пратка</h5>

      <div class="has-text-danger mb-1" v-if="error"> {{error}}</div>
      <template  v-if="!city">

    <div class="field ">
      <label class="label">Локација за брза пратка ({{order.address.municipality}} {{order.address.city.name}})</label>
      <div class="control">
      <input class="input" placeholder="Брза пратка локација"
             type="text"
             :value="search" @input="search =$event.target.value">
      </div>
    </div>
        <ul class="menu-list">
        <li v-for="city in cities" :key="city.name" >
          <a href="javascript:;" @click="chooseCity(city)"> {{city.description}}</a>
        </li>
        </ul>
      </template>
      <div v-else>
        <div class="card mb-1">
         <div class="card-content p-1 is-flex is-justify-content-space-between  is-align-items-center">
           <strong>{{city.description}}</strong>
           <button @click="chooseCity(null)" class="button is-danger is-small">Друго</button>
         </div>
        </div>
      </div>

    <div class="field ">
      <label class="label">Килограми</label>
      <div class="control">
      <input class="input" placeholder="Килограми"
             type="number"
             :value="form.kg" @input="form.kg = $event.target.value">
      </div>
    </div>


    <div class="field">
      <label class="label">Број на пакети</label>
      <div class="control has-icons-left">
        <div class="select is-fullwidth">
            <span class="icon"
            ><i class="fa fa-shopping-bag"></i
            ></span>
          <select
              :value="order.packages"
              @change="form.packages = $event.target.value"
          >
            <option
                :value="index"
                v-for="index in order.packages > 8
                                ? order.packages
                                : 8"
                :key="index"
            >
              {{ index }} пакети
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="buttons is-justify-content-end">
      <button type="button" @click="$emit('update:show', false)" class="button is-danger ">
        Назад
      </button>
      <button :disabled="loading || !city" :class="{'is-loading':loading}" class="button is-success" type="submit">
        Сихноризирај
      </button>
    </div>
    </form>
  </Message>
</template>

<script>

export default {
  name: "SyncPostModal",
  props: {
    order: Object
  },
  data() {
    return {
      form: {
        city: null,
        city_key: null,
        kg: Math.round(this.order.quantity/3),
        packages: this.order.packages
      },
      city:null,
      cities: [],
      search: '',
      timeout: null,
      cancelRequest: null,
      loading:false,
      error:null
    }
  },
  mounted() {
    this.getCities()
  },
  watch: {
    search() {
      this.getCities()
    }
  },
  methods: {
    chooseCity(city){
      if (!city){
        this.city = null;
        this.form.city = null
        this.form.city_key = null
        return;
      }
      this.city = city;
      this.form.city = city.name
      this.form.city_key = this.cities.indexOf(city)
    },
    submit(){

        this.loading=true
        this.form.city = this.city.name
        this.form.city_key = this.cities.indexOf(this.city)
        this.$http.post("/orders/"+this.order.id+"/sync", this.form)
            .then((res)=> {
              if (res.data.data.success){
                this.$emit('update:show', false)
              }else{
                this.error = res.data
              }
              this.loading=false
            })
    },
    getCities() {
      if (this.cancelRequest) this.cancelRequest.cancel();
      clearTimeout(this.timeoutQuery);
      this.timeout = setTimeout(() => {
        this.cancelRequest = this.$http.CancelToken.source();

        this.$http.get('/brza-pratka/cities?query=' + this.search, {cancelToken: this.cancelRequest.token})
            .then(a => {
              if (!a) return;
              this.cities = a.data
            })
      }, 300)

    }
  }
}
</script>

