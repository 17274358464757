<template>
    <div class="google-map"  ref="googleMap">
        <template v-if="Boolean(this.google) && Boolean(this.map)">
            <slot
                    :google="google"
                    :map="map"
            />
        </template>
    </div>

</template>

<script>
    import GoogleMapsApiLoader from 'google-maps-api-loader'

    export default {
        props: {
            mapConfig: Object,
        },
        data() {
            return {
                google: null,
                map: null
            }
        },
        async mounted() {
            const googleMapApi = await GoogleMapsApiLoader({
                apiKey: "AIzaSyBHMnqnCzhQKrlGQAbHKaUc6xb41jGHlsY"
            })
            this.google = googleMapApi
            this.initializeMap()
        },
        methods: {
            initializeMap() {
                const mapContainer = this.$refs.googleMap
                this.map = new this.google.maps.Map(mapContainer, this.mapConfig)
                this.map.addListener('click', (e) => this.$emit("mapClick", e));
            },
            setCenter(pos) {
                this.map.setCenter(pos);
            }
        }
    }
</script>