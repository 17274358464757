<template>
    <Message @closed="$router.push({name:'products', params:{productId: $route.params.productId}})" variant="is-danger">
        <p v-if="error">{{error}}</p>
        <h2 class="subtitle">Дали сте сигурни дека сакате да ја избришете варијантата?</h2>
        <div class="buttons">
            <button class="button is-danger is-light" v-on:click="deleteSelectedVariant">Да</button>
            <router-link :to="{name:'products', params:{productId: $route.params.productId}}" class="button is-success is-light">Не</router-link>
        </div>
    </Message>
</template>

<script>
    export default {
        name: "DeleteVariantModal",
        data(){
            return {
                error:null,
            };
        },
        methods:{
            deleteSelectedVariant() {
                const productId = this.$route.params.productId;
                const variantId = this.$route.params.variantId;
                this.$store.dispatch("removeVariant", {product_id: productId, id: variantId}).then(() => {
                    this.$router.push({name:'products', params:{productId: productId}})
                }).catch((a) => {
                    this.error = a.data.error;
                })
            },
        }
    }
</script>
