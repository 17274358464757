<template>
    <section class="section">

        <div class="container">
            <h3 class="title is-3">Останати работи</h3>

            <div class="columns is-multiline">
                <div class="column is-half" v-for="(option, i) in options" :key="i">
                    <router-link tag="div" :to="{name:option.route}" class="box">
                        <article class="media">
                            <div class="media-left">
                                <figure class="image is-64x64">
                                    <span class="icon has-text-warning is-large is-vcentered"><i class="fa fa-4x" :class="option.icon"></i></span>
                                </figure>
                            </div>
                            <div class="media-content">
                                <div class="content">
                                    <h2 class="title">{{option.title}}</h2>
                                    <p class="subtitle">{{option.desc}}</p>
                                </div>
                            </div>
                        </article>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

    export default {
        name: "Options",
        data(){
            return{
                options:[
                    {
                        title:"Градови",
                        desc:"Види и менаџирај ги сите градови",
                        route:"cities",
                        icon:"fa-map",
                    },
                    {
                        title:"Начин на достава",
                        desc:"Види и менаџирај ги сите начини на достава",
                        route:"shippingMethods",
                        icon:"fa-car",
                    },
                    {
                        title:"Амбалажа",
                        desc:"Види и менаџирај ги сите видови на амбалажи",
                        route:"sizes",
                        icon:"fa-balance-scale",
                    },
                    {
                        title:"Асортиман",
                        desc:"Види и менаџирај ги сите видови на асортимани",
                        route:"types",
                        icon:"fa-file-text",
                    },
                    {
                        title:"Внеси корисници",
                        desc:"Внеси поголем број на корисници",
                        route:"importClients",
                        icon:"fa-user",
                    }
                    ,
                    {
                        title:"Корисници дупликати",
                        desc:"Провери за евентуални дупликат-корисници",
                        route:"clientDuplicates",
                        icon:"fa-users",
                    },
                ]
            }
        }
    }
</script>
<style scoped>
    .box{
        cursor: pointer;
    }
</style>
