<template>
    <Modal title="Статистика за градови" @close="close">

        <OrdersStatisticsCitiesTable :stats="stats" v-if="!loading"/>
        <Loading v-else variant="is-white"/>
        <template v-slot:footer>

        </template>
    </Modal>
</template>

<script>
    import Loading from "../Loading";
    import OrdersStatisticsCitiesTable from "./OrdersStatisticsCitiesTable";
    export default {
        name: "OrdersStatisticsCitiesModal",
        components: {OrdersStatisticsCitiesTable, Loading},
        data() {
            return {
                stats: [],
                loading: true,
            };
        },
        props:{
            query:Object,
        },
        methods: {
            close() {
                this.$emit("update:show", false);
            },
        },

        created() {
            const url = this.$urify(Object.assign({}, this.query, {statistics_cities: true}), ["page"]);
            this.$http.get("/orders" + url)
                .then(a => {
                    this.stats = a.data.data;
                    this.loading = false
                });
        }
    }
</script>

<style scoped>

</style>