<template>
    <div class="columns is-multiline is-mobile">
<!--        <div class="column is-one-third-mobile has-text-centered">-->
<!--            <p class="heading">Креирани</p>-->
<!--            <p class="title" v-if="!loading">{{total("CREATED").toLocaleString('mk')}}</p>-->
<!--            <Loading v-else/>-->
<!--        </div>-->
        <div class="column is-half-mobile has-text-centered">
            <p class="heading">Поднесени</p>
            <p class="title" v-if="!loading">{{total("SUBMITTED").toLocaleString('mk')}}</p>
            <Loading v-else/>
        </div>
        <div class="column is-half-mobile has-text-centered">
            <p class="heading">Спакувани</p>
            <p class="title" v-if="!loading">{{total("PACKED").toLocaleString('mk')}}</p>
            <Loading v-else/>
        </div>
        <div class="column is-half-mobile has-text-centered">
            <p class="heading">Испратени</p>
            <p class="title" v-if="!loading">{{total("SHIPPED").toLocaleString('mk')}}</p>
            <Loading v-else/>
        </div>
        <div class="column is-half-mobile has-text-centered">
            <p class="heading">Завршени</p>
            <p class="title" v-if="!loading">{{total("DONE").toLocaleString('mk')}}</p>
            <Loading v-else/>
        </div>
<!--        <div class="column is-one-third-mobile has-text-centered">-->
<!--            <p class="heading">Откажани</p>-->
<!--            <p class="title" v-if="!loading">-->
<!--                {{total(["CANCELED", "RETURNED"]) }}-->
<!--            </p>-->
<!--            <Loading v-else/>-->
<!--        </div>-->
    </div>
</template>

<script>
    import Loading from "../Loading";

    export default {
        name: "CountOrderStatuses",
        components: {Loading},
        data() {
            return {
                stats: [],
                loading:true,
            };
        },
        created() {
            this.$http.get("/stats/count-order-statuses")
                .then(a => {
                    this.stats = a.data.data;
                    this.loading = false
                })
        },
        computed: {
            total() {
                return statuses => {
                    if (statuses.reduce)
                        return statuses.reduce((acc, status) => {
                            const el = this.stats.find(a => a.current_status === status);
                            if (!el) return acc;
                            acc = acc + el.total
                            return acc;
                        }, 0)
                    const el = this.stats.find(a => a.current_status === statuses);
                    if (!el) return 0;
                    return el.total;
                }
            }
        }
    }
</script>

