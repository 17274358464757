<template>
    <Message @closed="$emit('update:notFinishedOrder', false)" variant="is-danger">
        <h5 class="title is-5">Овој клиент има нарачка која не е завршена.</h5>
        <h4 class="subtitle is-4">Дали сакате да ја измените постоечката нарачка?</h4>
            <div class="buttons" v-if="!loading">
                <button class="button is-success is-light" v-on:click="updateCurrentOrder">Да, сакам</button>
                <button  class="button is-danger is-light" v-on:click="$emit('update:notFinishedOrder', false)">Не
                </button>
            </div>
        <Loading v-else variant="is-danger"/>
    </Message>
</template>

<script>
    import Loading from "../Loading";
    export default {
        name: "NotFinishedOrderForUser",
        components: {Loading},
        props: ["notFinishedOrder", "order", "orderLoading"],
        data(){
            return {
                loading:false
            }
        },
        methods: {
            updateCurrentOrder() {
                this.loading = true;
                this.$http.get("/orders/"+this.order.id+"/get_duplicate")
                .then(a=>{
                    this.$router.replace({
                        name: "editOrder",
                        params: {orderId: a.data.data.id},
                    });
                    this.$emit('update:notFinishedOrder', false)
                    this.$emit("update:order", a.data.data)
                }).catch(()=>{
                    this.loading = false;
                })
            }
        }
    }
</script>
