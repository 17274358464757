<template>
    <section class="section">
        <div class="container">
            <article class="panel is-danger">
                <div class="panel-heading">
                    Клиенти
                    <div class="pull-right dropdown is-right is-hoverable">
                        <div class="dropdown-trigger">
                            <button class="button is-small is-success is-light" aria-haspopup="true"
                                    aria-controls="dropdown-menu">
                                <span>Опции</span>
                                <span class="icon is-small"><i class="fa fa-angle-down"
                                                               aria-hidden="true"/></span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <a @click="showClientsNumbers=true" class="dropdown-item">
                                    Земи броеви
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-block is-block">
                    <div class="columns is-mobile is-gapless">
                        <div class="column is-6-mobile">
                            <div class="field has-addons">
                                <div class="control is-expanded">
                                    <input :value="$route.query.search"
                                           @input="handleSearchQueryChanged($event)"
                                           placeholder="Пребарај клиенти" class="input">
                                </div>
                                <div class="control" v-if="$route.query.search">
                                    <button class="button is-danger " @click="handleSearchQueryChanged(null)">
                                        <span class="icon"><i class="fa fa-times"></i></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="column is-3-mobile">
                            <div class="select is-fullwidth">
                                <select :value="$route.query.city_id || 0" @change="handleCityChanged" name="city">
                                    <option value="0">Сите градови</option>
                                    <option v-for="city in cities" :key="city.id" :value="city.id">{{city.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="column is-3-mobile">
                            <div class="select is-fullwidth">
                                <select :value="$route.query.sort || 'newest'" @change="handleSortChanged" name="order">
                                    <option value="newest">Најнови прво</option>
                                    <option value="loyal">Лојални прво</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-block" v-if="loading">
                    <Loading variant="is-white"/>
                </div>
                <ClientListItem v-for="user in users" v-bind:key="user.id" :user="user"/>
                <div class="panel-block" v-if="!loading && users.length">
                    Вкупно {{totalItems | clientsCount}}
                </div>
                <div class="panel-block" v-if="!loading && users.length ===0">
                    Нема пронајдено клиенти
                </div>
                <div class="panel-block" v-if="has_more && !loading">
                    <button v-on:click="loadMore" class="button is-primary  is-fullwidth">Вчитај повеќе</button>
                </div>
            </article>
        </div>
        <ClientsNumbersModal v-if="showClientsNumbers" :show.sync="showClientsNumbers" :query="query" endpoint="users"/>
    </section>
</template>
<script>

    import ClientListItem from "../../components/Clients/ClientListItem";
    import Loading from "../../components/Loading";
    import ClientsNumbersModal from "../../components/Clients/ClientsNumbersModal";

    export default {
        name: "Clients",
        components: {
            ClientsNumbersModal,
            Loading,
            ClientListItem,
        },
        data() {
            const cache = JSON.parse(localStorage.getItem("clientsCache"));
            return {
                showClientsNumbers: false,
                users: cache || [],
                loading: true,
                has_more: false,
                cancelRequest: null,
                timeoutQuery: false,
                totalClients: 0,
                page: 1
            };
        },
        methods: {
            fetchClients: function (timeout = false, reset = true) {
                this.loading = true;
                if (reset) this.page = 1;

                if (this.cancelRequest) this.cancelRequest.cancel();
                clearTimeout(this.timeoutQuery);
                this.timeoutQuery = setTimeout(() => {
                    this.cancelRequest = this.$http.CancelToken.source();
                    const url = this.$urify({...this.$route.query, page: this.page});
                    this.$http.get("/users" + url, {cancelToken: this.cancelRequest.token})
                        .then(a => {
                            if (!a) return;
                            this.loading = false;
                            if (reset)
                                this.users = a.data.data
                            else
                                this.users = this.users.concat(a.data.data)
                            // localStorage.setItem("clientsCache", JSON.stringify(this.users.slice(0, 50)))
                            this.$asyncLocalStorage.setItem("clientsCache", JSON.stringify(this.users.slice(0, 50)))
                            this.totalItems = a.data.total;
                            this.has_more = a.data.last_page > a.data.current_page;
                        })
                }, (timeout ? 300 : 0))
            },
            handleCityChanged(e) {
                const id = e.target.value;
                let copy = this.$deepCopy(this.$route.query);
                copy["city_id"] = id;
                if (isNaN(id) || id === "0") delete copy["city_id"];
                this.$router.push({name: 'clients', query: copy})
            },
            handleSortChanged(e) {
                const id = e.target.value;
                let copy = this.$deepCopy(this.$route.query);
                copy["sort"] = id;
                if (id === "newest") delete copy["sort"];
                this.$router.push({name: 'clients', query: copy})
            },
            handleSearchQueryChanged(e) {
                const val = this.$l2c(this.$rbn(e ? e.target.value : ''));
                let copy = this.$deepCopy(this.$route.query);
                copy["search"] = val;
                const changeRoute = copy["search"];
                if (!val) delete copy["search"];

                let start = 0;
                if (e) start = e.target.selectionStart;

                if (changeRoute)
                    this.$router.replace({name: 'clients', query: copy})
                else
                    this.$router.push({name: 'clients', query: copy})
                if (e)
                    this.$nextTick(() => {
                        e.target.selectionStart = e.target.selectionEnd = start;
                    })
            },
            loadMore() {
                this.page += 1;
                this.fetchClients(false, false);

            }
        },
        computed: {
            cities: function () {
                return this.$store.getters.getCities;
            },
            query: function () {
                return this.$route.query;
            }
        },
        created() {
            this.fetchClients();

        },
        watch: {
            $route: function (to, fr) {
                if (to.name === fr.name)
                    this.fetchClients(to.query.search !== fr.query.search);
            }
        }
    }
</script>