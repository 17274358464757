import Vue from 'vue'
import VueRouter from 'vue-router'
import Secured from '../views/Secured.vue'
import Types from '../views/products/Types.vue'
import Sizes from '../views/products/Sizes.vue'
import Clients from '../views/clients/Clients.vue'
import Home from '../views/Home.vue'
import Login from "../views/Login";
import Products from "../views/products/Products";
import Orders from "../views/orders/Orders";
import NewEditOrder from "../views/orders/NewEditOrder";
import ViewOrder from "../views/orders/ViewOrder";
import ViewClient from "../views/clients/ViewClient";
import CreateTypeModal from "../components/Types/CreateTypeModal";
import EditTypeModal from "../components/Types/EditTypeModal";
import DeleteTypeModal from "../components/Types/DeleteTypeModal";
import CreateSizeModal from "../components/Sizes/CreateSizeModal";
import EditSizeModal from "../components/Sizes/EditSizeModal";
import DeleteSizeModal from "../components/Sizes/DeleteSizeModal";
import DeleteProductModal from "../components/Products/DeleteProductModal";
import EditProductModal from "../components/Products/EditProductModal";
import CreateProductModal from "../components/Products/CreateProductModal";
import DeleteVariantModal from "../components/Products/Variants/DeleteVariantModal";
import EditVariantModal from "../components/Products/Variants/EditVariantModal";
import CreateVariantModal from "../components/Products/Variants/CreateVariantModal";
import CreateProductionModal from "../components/Products/Variants/Production/CreateProductionModal";
import DeleteProductionModal from "../components/Products/Variants/Production/DeleteProductionModal";
import Cities from "../views/Cities";
import ShippingMethods from "../views/shippingMethods/ShippingMethods";
import PrintOrderContainer from "../components/Prints/PrintOrderContainer";
import PrintOrderTableContainer from "../components/Prints/PrintOrderTableContainer";
import Options from "../views/Options";
import CreateShippingMethodModal from "../components/ShippingMethods/CreateShippingMethodModal";
import EditShippingMethodModal from "../components/ShippingMethods/EditShippingMethodModal";
import EditClientModal from "../components/Clients/EditClientModal";
import ImportUsers from "../views/ImportUsers";
import ClientDuplicates from "../views/ClientDuplicates";
import Production from "../views/Production";
import EditProductionModal from "../components/Products/Variants/Production/EditProductionModal";
import OrdersMap from "../views/OrdersMap";
import PrintOrderDeliveryDocument from "../components/Prints/PrintOrderDeliveryDocument";

Vue.use(VueRouter)
const title = "Домашно направени производи";
const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            requiresAuth: false,
            title: "Најава - " + title
        },
    },
    {
        path: "/orders/print-shipping-layout",
        name: 'printOrders',
        component: PrintOrderContainer,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/orders/print-delivery-document",
        name: 'printOrderDeliveryDocument',
        component: PrintOrderDeliveryDocument,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/orders/print-table-layout",
        name: 'printOrdersTable',
        component: PrintOrderTableContainer,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/',
        component: Secured,
        meta: {
            requiresAuth: true,
            title: title

        },
        children: [
            {
                name: 'secured',
                path: '/',
                component: Home,
            },
            {
                path:"/map",
                name:"map",
                component: OrdersMap,
                meta: {title: "Мапа - " + title},

            },

            {
                name: 'types',
                path: '/types',
                component: Types,
                meta: {title: "Асротимани - " + title},
                children: [
                    {
                        name: 'createType',
                        path: '/types/create',
                        component: CreateTypeModal
                    },
                    {
                        name: 'editType',
                        path: '/types/:typeId/edit',
                        component: EditTypeModal
                    },
                    {
                        name: 'deleteType',
                        path: '/types/:typeId/delete',
                        component: DeleteTypeModal
                    }
                ]
            },
            {
                name: "importClients",
                path: "/import-clients",
                meta: {title: "Внеси корисници"},
                component: ImportUsers
            },
            {
                name: "clientDuplicates",
                path: "/client-duplicates",
                meta: {title: "Дупликат-корисници"},
                component: ClientDuplicates
            },
            {
                name: "cities",
                path: "/cities",
                meta: {title: "Градови - " + title},
                component: Cities
            },
            {
                name: "shippingMethods",
                path: "/shipping-methods",
                meta: {title: "Начини на достава - " + title},

                component: ShippingMethods,
                children: [
                    {
                        name: 'createShippingMethod',
                        path: '/shipping-methods/create',
                        component: CreateShippingMethodModal
                    },
                    {
                        name: 'editShippingMethod',
                        path: '/shipping-methods/:shippingMethodId/edit',
                        component: EditShippingMethodModal
                    },
                ]
            },
            {
                name: 'sizes',
                path: '/sizes',
                meta: {title: "Амбалажа - " + title},
                component: Sizes,
                children: [
                    {
                        name: 'createSize',
                        path: '/sizes/create',
                        component: CreateSizeModal
                    },
                    {
                        name: 'editSize',
                        path: '/sizes/:sizeId/edit',
                        component: EditSizeModal
                    },
                    {
                        name: 'deleteSize',
                        path: '/sizes/:sizeId/delete',
                        component: DeleteSizeModal
                    }
                ]
            },
            {
                name: 'clients',
                path: '/clients',
                meta: {title: "Клиенти - " + title},
                component: Clients,
            },
            {
                name: 'viewClient',
                path: '/clients/:clientId',
                component: ViewClient,
                children: [
                    {
                        name: "editClient",
                        path: '/clients/:clientId/edit',
                        component: EditClientModal

                    }
                ]
            },
            {
                name: 'createOrder',
                path: '/orders/create',
                component: NewEditOrder,
                meta: {
                    title: "Нова нарачка - " + title
                },
            },
            {
                name: 'editOrder',
                path: '/orders/:orderId/edit',
                meta: {title: "Измени нарачка - " + title},
                component: NewEditOrder
            },
            {
                name: 'orders',
                path: '/orders',
                meta: {title: "Нарачки - " + title},
                component: Orders,
            },
            {
                name: 'viewOrder',
                meta: {title: "Види нарачка - " + title},
                path: '/orders/:orderId',
                component: ViewOrder,
            },
            {
                name: 'options',
                path: '/options',
                component: Options
            },
            {
                name: 'products',
                path: '/products/:productId?',
                meta: {title: "Продукти - " + title},
                component: Products,
                children: [
                    {
                        name: "deleteProduct",
                        path: "/products/:productId/delete",
                        component: DeleteProductModal
                    },
                    {
                        name: "editProduct",
                        path: "/products/:productId/edit",
                        component: EditProductModal
                    },
                    {
                        name: "createProduct",
                        path: "/products/:productId/create",
                        component: CreateProductModal
                    },
                    {
                        name: "deleteVariant",
                        path: "/products/:productId/variants/:variantId/delete",
                        component: DeleteVariantModal
                    },
                    {
                        name: "editVariant",
                        path: "/products/:productId/variants/:variantId/edit",
                        component: EditVariantModal
                    },
                    {
                        name: "createVariant",
                        path: "/products/:productId/variants/create",
                        component: CreateVariantModal
                    },
                ]
            },
            {
                name: "viewProduction",
                path: "/production/:productId/variant/:variantId/",
                component: Production,
                children: [
                    {
                        name: "createProduction",
                        path: "/production/:productId/variant/:variantId/create",
                        component: CreateProductionModal
                    },
                    {
                        name: "deleteProduction",
                        path: "/production/:productId/variant/:variantId/:productionId/delete",
                        component: DeleteProductionModal
                    },
                    {
                        name: "editProduction",
                        path: "/production/:productId/variant/:variantId/:productionId/edit",
                        component: EditProductionModal
                    }
                ]
            },

        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})

export default router
