<template>
    <button :class="variant" :style="{height: height+'em'}" class="button is-fullwidth is-loading"></button>
</template>

<script>
    export default {
        name: "Loading",
        props: {
            variant:{
                type: String,
                default:"is-white"
            },
            height:{
                type:String,
                default:"2"
            }
        }
    }
</script>
