<template>
  <section class="section">
    <div class="container">
      <article class="panel" :class="order.status | statusClass">
        <div class="panel-heading is-flex" v-if="!loading">
          <div class="text-truncate is-flex-grow-1">
            Нарачка број: {{ order.id }}
          </div>
          <div class="is-flex">
            <button @click="copyOrder" class="button is-small is-link">
              <span class="icon"><i class="fa fa-copy"></i></span></button
            >&nbsp;
            <div class="dropdown is-right is-hoverable">
              <div class="dropdown-trigger">
                <button
                  class="button is-small is-success is-light"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                >
                  <span>Опции</span>
                  <span class="icon is-small">
                    <i class="fa fa-angle-down" aria-hidden="true"
                  /></span>
                </button>
              </div>
              <div class="dropdown-menu" id="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <a
                    @click="printModal = true"
                    v-if="order.current_status !== 'CREATED'"
                    class="dropdown-item"
                  >
                    Испечати за пакување
                  </a>


                  <a
                      @click="patchOrder({ priority: !order.priority })"
                      class="dropdown-item"
                  >
                   {{order.priority ? 'Отстрани приоритет' : 'Постави приоритет'}}
                  </a>
<!--                  <a @click="syncPostModal=true" class="dropdown-item">-->
<!--                    Сихноризирај со Нова Пошта-->
<!--                  </a>-->
                  <a :href="'sms:/open?addresses='+order.user.phones.map(a=> a.number).join(',')+'&body=Vashata narachka od Domasno napraveni proizvodi ke bide dostavena vo narednite 30 minuti. Vkupnata suma za plakanje e: '+order.final_total.toLocaleString()+' den. Ve molime bidete dostapni na telefon.'" class="dropdown-item">
                    СМС за пристигнување
                  </a>
                  <a
                    @click="showTips = true"
                    v-if="order.current_status !== 'CREATED'"
                    class="dropdown-item"
                  >
                    Додај типс
                  </a>

                  <router-link
                    :to="{ name: 'editOrder', params: $route.params }"
                    class="dropdown-item"
                  >
                    Измени нарачка
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-heading" v-else>
          <Loading variant="is-light" />
        </div>
        <div class="panel-block is-block ">
          <div class="columns  ">
            <div class="column ">
              <div
                class="is-block panel-block"
                style="background: rgb(235, 235, 235);"
              >
                <b>Порачани производи</b>
              </div>
              <div v-if="!loading">
                <div
                  class="panel-block is-block"
                  v-for="item in order.items"
                  :key="item.variant_id"
                >
                  <div class="columns is-mobile is-gapless">
                    <div class="column">
                      {{ variant(item.variant_id).fullName }}
                    </div>
                    <div class="column has-text-right">
                      {{ item.quantity }} × {{ item.price }} ден.
                    </div>
                    <div class="column has-text-right">
                      <b>{{ item.total }} ден.</b>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!loading && order.items.length"
                  class="panel-block is-block "
                >
                  <div class="tags  is-right mb-0 are-medium has-addons">
                    <span class="tag is-light">Вкупно</span>
                    <span class="tag is-link is-light"
                      >{{ order.quantity }} ставки</span
                    >
                    <span class="tag is-success is-light"
                      >{{ order.total }} ден.</span
                    >
                  </div>
                  <div
                    v-if="order.delivery"
                    class="tags  mb-0 is-right are-medium has-addons"
                  >
                    <span class="tag is-light">Достава</span>
                    <span class="tag is-danger "
                      >+{{ order.delivery }} ден.</span
                    >
                  </div>
                  <div
                    v-if="order.discount"
                    class="tags is-right mb-0 are-medium has-addons"
                  >
                    <span class="tag is-light">Попуст</span>
                    <span class="tag is-success is-light"
                      >-{{ order.discount }} ден.</span
                    >
                  </div>
                  <div class="tags is-right mb-0 are-medium has-addons">
                    <span class="tag is-light">За плаќање</span>
                    <span class="tag is-success "
                      >{{ order.final_total }} ден.</span
                    >
                  </div>
                  <div
                    class="tags is-right mb-0 are-medium has-addons"
                    v-if="order.tips"
                  >
                    <span class="tag is-light">Типс:</span>
                    <span class="tag is-link ">{{ order.tips }} ден.</span>
                  </div>
                </div>
                <div class="panel-block" v-if="!order.items.length">
                  <i>Оваа нарачка се уште нема производи</i>
                </div>
              </div>
              <div class="panel-block" v-else>
                <Loading variant="is-white" />
              </div>
            </div>
            <div class="column ">
              <p style="background: rgb(235, 235, 235);" class="panel-block">
                <b>Клиент</b>
                <router-link
                  v-if="!loading && order.user"
                  :to="{
                    name: 'viewClient',
                    params: { clientId: order.user.id },
                  }"
                  class="button is-link is-small"
                  style="margin-left:auto"
                  >Види клиент
                </router-link>
              </p>
              <div class="panel-block is-block" v-if="!loading && order.user">
                <p class="title is-3 has-text-centered">
                  {{ order.user.name }}
                </p>
                <p
                  v-if="order.user.description"
                  class="subtitle is-6 has-text-centered"
                >
                  {{ order.user.description }}
                </p>
                <p v-if="order.address" class="subtitle is-5 has-text-centered">
                  {{ order.address | formatStreet }}
                  <br v-if="order.address.street_name" />
                  {{ order.address | formatCity }}

                  <a :href="order.address | linkToAddress" target="_blank">
                    <br />
                    <span>Види адреса </span>
                    <span class="icon"
                      ><i class="fa fa-external-link"></i
                    ></span>
                  </a>
                  &emsp;
                  <a
                    :href="order.address | linkToCoords"
                    target="_blank"
                    v-if="order.address.lat && order.address.long"
                  >
                    <span>Кординати </span>
                    <span class="icon"
                      ><i class="fa fa-external-link"></i
                    ></span>
                  </a>
                  <br v-if="order.address.description" />
                  <i>{{ order.address.description }}</i>
                </p>
                <div class="buttons is-centered">
                  <a
                    :href="'tel:' + phone.number"
                    :key="phone.number"
                    class="button is-primary is-light "
                    v-for="phone in order.user.phones"
                  >
                    <span class="icon"><i class="fa fa-phone"></i></span>
                    <span>{{ phone.number | phoneNumber }}</span>
                  </a>
                </div>
                <div class="buttons is-centered" v-if="order.address">
                  <button
                    class="is-link button is-light"
                    @click="editAddress = true"
                  >
                    <span class="icon"><i class="fa fa-pencil"></i></span>
                    <span>Измени адреса</span>
                  </button>
                  <button
                    class="is-success button is-light"
                    @click="saveCoordsToAddress"
                  >
                    <span class="icon"
                      ><i class="fa fa-location-arrow"></i
                    ></span>
                    <span>Координати</span>
                  </button>
                  <button
                    v-if="
                      order.current_status !== 'DONE' &&
                        order.current_status !== 'RETURNED' &&
                        order.current_status !== 'CANCELLED'
                    "
                    class="is-success button"
                    :class="{ 'is-loading': setDoneLoading }"
                    @click="
                      patchOrder({ status: 'DONE' });
                      setDoneLoading = true;
                    "
                  >
                    <span class="icon"><i class="fa fa-star"></i></span>
                    <span>Заврши нарачка</span>
                  </button>
                  <button
                    @click="saveCachedCoords"
                    v-if="cachedCoords"
                    class="is-warning button is-light"
                  >
                    <span class="icon"><i class="fa fa-reply"></i></span>
                    <span>Врати ги старите</span>
                  </button>
                </div>
              </div>
              <div
                class="panel-block is-block"
                v-else-if="!order.user && !loading"
              >
                <p class="title is-3 has-text-centered">
                  Нарачката се уште нема клиент
                </p>
              </div>
              <div class="panel-block" v-else>
                <Loading variant="is-white" />
              </div>
              <div style="background: rgb(235, 235, 235);" class="panel-block is-flex is-justify-content-space-between">
                <b>Информации</b>
                <div>
                  <span class="tag is-success" v-if="order.online">Online</span>
                  <span class="tag is-danger" v-if="order.priority">Приоритет</span>
                </div>
              </div>
              <div class="panel-block is-block" v-if="!loading">
                <div class="columns is-mobile is-gapless">
                  <div class="column is-5">
                    Коментар:
                  </div>
                  <div class="column is-7">
                    <p v-if="!editComment">
                      <span
                        v-if="order.description"
                        :inner-html.prop="order.description | linkPhoneNumber"
                      ></span>
                      <i v-else>Нема</i>
                      <a @click="editComment = true">
                        <span class="icon"><i class="fa fa-pencil"></i></span>
                      </a>
                    </p>
                    <div v-else class="field has-addons">
                      <div class="control is-expanded  has-icons-left">
                        <span class="icon is-size-4-tablet is-size-6-desktop"
                          ><i class="fa fa-info-circle"></i
                        ></span>
                        <textarea
                          :value="order.description"
                          @input="handleDescriptionChange"
                          class="input is-size-4-tablet is-size-6-desktop"
                          placeholder="Коментар за нарачката"
                          type="text"
                          style="height: 80px"
                        ></textarea>
                      </div>
                      <div class="control">
                        <div class="control">
                          <button
                            :class="{ 'is-loading': descriptionLoading }"
                            class="button is-success"
                            @click="
                              descriptionLoading = true;
                              patchOrder({ description: order.description });
                            "
                          >
                            <span class="icon"><i class="fa fa-save"></i></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns is-mobile is-gapless">
                  <div class="column is-5">
                    Достава:
                  </div>
                  <div class="column is-7">
                    <div class="field ">
                      <div class="control has-icons-left">
                        <div class="select is-fullwidth">
                          <span class="icon"><i class="fa fa-truck"></i></span>
                          <select
                            :disabled="!order.can_edit"
                            :value="order.shipping_method_id"
                            @change="
                              patchOrder({
                                shipping_method_id: $event.target.value,
                              })
                            "
                          >
                            <option
                              v-if="
                                !shippingMethods.find(
                                  (a) => a.id === order.shipping_method_id
                                )
                              "
                              :value="order.shipping_method_id"
                            >
                              {{ order.shipping_method | shippingMethod }}
                            </option>
                            <option
                              v-for="sm in shippingMethods"
                              :value="sm.id"
                              v-bind:key="sm.id"
                            >
                              {{ sm | shippingMethod }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns is-mobile is-gapless">
                  <div class="column is-5">
                    Статус:
                  </div>
                  <div class="column is-7">
                    <div class="field">
                      <div class="control has-icons-left">
                        <div class="select is-fullwidth">
                          <span class="icon"><i class="fa fa-star"></i></span>
                          <select
                            :value="order.current_status"
                            @change="
                              patchOrder({ status: $event.target.value })
                            "
                          >
                            <option
                              :value="key"
                              v-for="(val, key) in statuses"
                              :key="key"
                            >
                              {{ val }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns is-mobile is-gapless">
                  <div class="column is-5">
                    Број пакети:
                  </div>
                  <div class="column is-7">
                    <div class="field">
                      <div class="control has-icons-left">
                        <div class="select is-fullwidth">
                          <span class="icon"
                            ><i class="fa fa-shopping-bag"></i
                          ></span>
                          <select
                            :value="order.packages"
                            @change="
                              patchOrder({ packages: $event.target.value })
                            "
                          >
                            <option
                              :value="index"
                              v-for="index in order.packages > 8
                                ? order.packages
                                : 8"
                              :key="index"
                            >
                              {{ index }} пакети
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns is-mobile is-gapless">
                  <div class="column is-5">
                    Достава:
                  </div>
                  <div class="column is-7">
                    <div class="field has-addons ">
                      <div class="control is-expanded has-icons-left">
                        <span class="icon is-size-4-tablet is-size-6-desktop"
                          ><i class="fa fa-info-circle"></i
                        ></span>
                        <input
                          :disabled="!order.can_edit"
                          :value="order.delivery"
                          @input="order.delivery = $event.target.value"
                          class="input is-size-4-tablet is-size-6-desktop"
                          placeholder="Цена за достава"
                          type="number"
                        />
                      </div>
                      <div class="control">
                        <button
                          :disabled="!order.can_edit"
                          :class="{ 'is-loading': deliveryLoading }"
                          class="button is-success"
                          @click="
                            deliveryLoading = true;
                            patchOrder({ delivery: order.delivery });
                          "
                        >
                          <span class="icon"><i class="fa fa-save"></i></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-block" v-else>
                <Loading variant="is-white" />
              </div>
            </div>
          </div>
        </div>
        <div class="panel-block" style="justify-content: center">
          <div class="tags">
            <span
              class="tag "
              :title="status.created_at | statusTime"
              :class="status | statusClass"
              v-for="status in order.statuses"
              :key="status.status"
            >
              {{ status | status }}
            </span>
          </div>
        </div>
      </article>
    </div>
    <PrintModal
      :queryParent="{ ids: [this.order.id] }"
      v-if="printModal"
      :printModal.sync="printModal"
    />
    <EditClientAddress
      @save="saveAddress"
      v-if="editAddress"
      :show.sync="editAddress"
      :address="order.address"
    />
    <SyncPostModal
        v-if="syncPostModal"
        :show.sync="syncPostModal"
        :order="order"
    />
    <AddTipsModal v-if="showTips" :show.sync="showTips" :tips="order.tips" @save="patchOrder" />
    <router-view></router-view>
  </section>
</template>
<script>
import Loading from "../../components/Loading";
import PrintModal from "../../components/Orders/PrintModal";
import EditClientAddress from "../../components/Clients/Address/EditClientAddress";
import AddTipsModal from "../../components/Orders/Order/AddTipsModal.vue";
import SyncPostModal from "../../components/Orders/SyncPostModal";

export default {
  name: "ViewOrder",
  components: {SyncPostModal, EditClientAddress, PrintModal, Loading, AddTipsModal, },
  data() {
    return {
      showTips: false,
      editComment: false,
      setDoneLoading: false,
      editAddress: false,
      syncPostModal: false,
      loading: true,
      descriptionLoading: false,
      deliveryLoading: false,
      delivery: null,
      printModal: false,
      output: null,
      order: {
        stock: null,
        items: [],
        shipping_method: {
          name: "",
        },
      },
      cachedCoords: null,
    };
  },
  methods: {
    handleDescriptionChange(e) {
      let sel = e.target.selectionStart;
      this.order.description = this.$l2c(e.target.value).trim();
      this.$nextTick(() => {
        e.target.selectionStart = e.target.selectionEnd = sel;
      });
    },
    saveAddress(address) {
      this.$http
        .patch(
          "/users/" + this.order.user.id + "/addresses/" + address.id,
          address
        )
        .then(() => {
          this.editAddress = false;
          this.$toast({
            message: "Успешно ја изменивте адресата",
            type: "is-success",
          });
          this.fetchOrder();
        });
    },
    copyOrder() {
      const add = this.order.address;
      let copy = `Нарачка за: ${this.order.user.name}\n`;
      if (add) {
        copy += "Адреса: ";
        const addS = this.$options.filters.formatStreet(add);
        if (addS) copy += `${addS} во `;
        copy += `${this.$options.filters.formatCity(add)}`;
        if (add.description) copy += ` [${add.description}]`;
        copy += "\n";
      }
      if (this.order.user.phones.length)
        copy +=
          "Телефонски број: " +
          this.order.user.phones
            .map((a) => this.$options.filters.phoneNumber(a.number))
            .join(" или ") +
          "\n";
      copy += "\nНарачани производи:\n------------------\n";

      this.order.items.forEach((item) => {
        copy +=
          this.variant(item.variant_id).fullName +
          " | " +
          item.quantity +
          " × " +
          item.price +
          " ден  = " +
          item.total +
          "ден. \n";
      });
      copy += "------------------\n\n";
      copy += "Вкупно ставки: " + this.order.quantity + "\n";
      if (this.order.delivery || this.order.discount)
        copy += "Вкупно производи: " + this.order.total + " ден.\n";
      if (this.order.delivery)
        copy += "Достава: " + this.order.delivery + " ден.\n";
      if (this.order.discount)
        copy += "Попуст: -" + this.order.discount + " ден.\n";
      copy += "За плаќање: " + this.order.final_total + " ден.\n";

      this.$copyText(copy).then(() => {
        this.$toast({ message: "Нарачката е копирана", type: "is-success" });
      });
    },
    fetchOrder() {
      const id = this.$route.params.orderId;
      this.$http.get("/orders/" + id).then((a) => {
        if (a.data.data.current_status === "CREATED")
          this.$router.replace({
            name: "editOrder",
            params: { orderId: id },
          });
        this.order = a.data.data;
        this.loading = false;
      });
    },
    patchOrder(data) {
      this.$http.patch("/orders/" + this.order.id, data).then((a) => {
        this.$toast({ message: "Нарачката е зачувана", type: "is-success" });
        this.order = a.data.data;
        this.setDoneLoading = false;
        this.deliveryLoading = false;
        this.editComment = false;
        this.descriptionLoading = false;
      });
    },
    saveCachedCoords() {
      if (this.cachedCoords) this.saveCoordinates(this.cachedCoords);
    },
    saveCoordsToAddress() {
      this.$getLocation({
        enableHighAccuracy: true,
        maximumAge: 0,
      })
        .then((coords) => {
          this.saveCoordinates({
            lat: coords.lat,
            long: coords.lng,
          });
        })
        .catch(() => {
          this.$toast({
            message: "Не може да се добие локација",
            type: "is-danger",
          });
        });
    },
    saveCoordinates(coords) {
      this.$http
        .patch(
          "/users/" +
            this.order.user_id +
            "/addresses/" +
            this.order.address_id,
          coords
        )
        .then(() => {
          this.$toast({
            message: `Успешно зачувани координати (${coords.lat.toFixed(
              5
            )}, ${coords.long.toFixed(5)})`,
            type: "is-success",
          });
          const { lat, long } = this.order.address;
          if (lat || long) {
            if (!this.cachedCoords)
              this.cachedCoords = { lat: lat, long: long };
            else this.cachedCoords = null;
          }

          this.fetchOrder();
        });
    },
  },
  computed: {
    shippingMethods: function() {
      return this.$store.getters.getShippingMethods;
    },
    variant: function() {
      return (id) => {
        return this.$store.getters.getVariant(id);
      };
    },
    statuses: function() {
      let clone = Object.assign({}, this.$orderStatuses);
      delete clone.CREATED;
      return clone;
    },
  },
  mounted() {
    this.fetchOrder();
  },
};
</script>
