<template>
    <Message @closed="$router.push({name:'products'})" variant="is-danger">
        <p v-if="error">{{error}}</p>
        <h2 class="subtitle">Дали сте сигурни дека сакате го избришете продуктот?</h2>
        <div class="buttons">
            <button class="button is-danger is-light" v-on:click="deleteSelectedProduct()">Да</button>
            <router-link :to="{name:'products'}" class="button is-success is-light">Не</router-link>
        </div>
    </Message>
</template>

<script>
    export default {
        name: "DeleteProduct",
        data(){
            return {
                error:null,
            }
        },
        methods:{
            deleteSelectedProduct() {
                this.$store.dispatch("removeProduct", this.$route.params.productId).then(() => {
                    this.$router.push({name:'products'})
                }).catch((a) => {
                    this.error = a.data.error;
                })
            },
        }
    }
</script>
