<template>
    <Modal  title="Измени продукт" @close="$router.replace({name:'products'})">
        <div v-if="product">
        <div class="field">
            <label class="label">Назив</label>
            <div class="control">
                <input class="input" :value="product.name" @input="product.name = $l2c($event.target.value)" type="text" placeholder="Ајвар">
            </div>
        </div>

        <div class="field">
            <label class="label">Опис</label>
            <div class="control">
                        <textarea rows="3" class="input" :value="product.description" @input="product.description = $l2c($event.target.value)"
                                  placeholder="Опис за продуктот"></textarea>
            </div>
        </div>
        </div>
        <Loading v-else variant="is-white"/>
        <template v-slot:footer>
            <button class="button is-success" v-on:click="submitProduct">Зачувај</button>
        </template>
    </Modal>

</template>

<script>
    import Loading from "../Loading";

    export default {
        name: "EditProductModal",
        components: {Loading},
        data(){
            return {
                product:null
            };
        },
        methods:{
            submitProduct() {
                this.$store.dispatch("editProduct", this.product).then(() => {
                    this.$router.replace({name:'products'})
                })
            },
        },
        computed: {
            getProduct() {
                return this.$store.getters.getProductById(this.$route.params.productId);
            }
        },
        watch: {
            getProduct: function (val) {
                if (!this.product) this.product = this.$deepCopy(val);
            }
        },
        mounted() {
            this.product = this.$deepCopy(this.$store.getters.getProductById(this.$route.params.productId));
        }
    }
</script>

<style scoped>

</style>