<template>
    <Message @closed="close" variant="is-danger">
        <h2 class="subtitle">Дали сте сигурни дека сакате da ја избришете одредената количина?</h2>
        <div class="buttons">
            <button class="button is-danger is-light" v-on:click="deleteSelectedProduction">Да</button>
            <button class="button is-success is-light" v-on:click="close">Не</button>
        </div>
    </Message>
</template>

<script>
    export default {
        name: "DeleteProductionModal",
        methods: {
            close(){
                this.$router.replace({name:'viewProduction', params:{productId:this.$route.params.productId, variantId:this.$route.params.variantId}})
            },
            deleteSelectedProduction() {
                const productId  = this.$route.params.productId;
                const variantId = this.$route.params.variantId
                this.$http.delete("/products/" + productId + "/variants/" + variantId + "/production/" + this.$route.params.productionId)
                    .then(() => {
                        this.$store.dispatch('getProducts');
                        this.close()
                    })
            },
        }
    }
</script>
