<template>
    <Modal title="Креирај амбалажа" @close="$router.push({name:'sizes'})">

        <div class="field">
            <label class="label">Назив</label>
            <div class="control">
                <input class="input" :value="size.name" @input="size.name = $l2c($event.target.value)" type="text" placeholder="Мало">
            </div>
        </div>

        <div class="field">
            <label class="label">Големина</label>
            <div class="control">
                <input class="input" v-model="size.size" type="text" placeholder="0.320ml">
            </div>
        </div>
        <template v-slot:footer>
            <button class="button is-success" v-on:click="submitSize">Зачувај</button>
        </template>
    </Modal>
</template>

<script>

    export default {
        name: "CreateSizeModal",
        data(){
            return {
                size:{
                    name:"",
                    size:"",
                }
            }
        },
        methods:{
            submitSize() {
                this.$store.dispatch("submitNewSize", this.size).then(() => {
                    this.$router.push({name:'sizes'})
                })
            }
        }
    }
</script>

<style scoped>

</style>