<template>
    <nav class="level">
        <div class="level-left">
            <div class="level-item">
                <h2 class="title">{{$props.title}}</h2>
            </div>
        </div>

        <!-- Right side -->
        <div class="level-right">
            <slot></slot>

        </div>
    </nav>
</template>

<script>
    export default {
        name: 'PageHeader',
        props: ["title"]
    }
</script>
