<template>
    <div class="panel-block is-block">
        <div class="field has-addons">
            <p class="control is-expanded">
                <input class="input is-size-4-tablet is-size-6-desktop" inputmode="tel" placeholder="07XXXXXXX" v-model="phoneText" type="number" v-on:keypress.enter="addPhoneNumber">
            </p>
            <p class="control">
                <a class="button is-success is-size-4-tablet is-size-6-desktop" :class="{'is-loading':loading}" v-on:click="addPhoneNumber">
                    <span class="icon"><i class="fa fa-check"/></span>
                    <span>Зачувај</span>
                </a>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AddClientNumber",
        props:["user"],
        data(){
            return {
                phoneText: "",
                loading:false,
            };
        },
        methods:{
            addPhoneNumber() {
                this.loading=true;
                this.$http.post("/users/" + this.user.id + "/phones", {number: this.phoneText})
                    .then((a) => {
                        this.$emit("reload", a.data.data);
                        this.$toast({message: "Додаден телефонски број", type: "is-success"})

                    }).finally(()=> this.loading=false)
            },
        }
    }
</script>

<style scoped>

</style>