<template>
    <Modal title="Нова достава" @close="$router.replace({name:'shippingMethods'})">

        <div class="field">
            <label class="label">Назив</label>
            <div class="control">
                <input class="input" :value="sm.name" @input="sm.name = $l2c($event.target.value)" type="text"
                       placeholder="Достава 20.08.2020">
            </div>
        </div>
        <div class="field">
            <label class="label">Минимална цена за нарачки</label>
            <div class="control">
                <input class="input" v-model="sm.min_price" min="0" type="number"
                       placeholder="0">
            </div>
        </div>

        <div class="field">
            <div class="control">

                <input class="is-checkradio " id="enabled" type="checkbox"
                       v-model="sm.enabled">
                <label for="enabled">
                    Дали е дозволено да се креираат нови нарачки?
                </label>
            </div>

        </div>

<!--        <div class="field">-->
<!--            <div class="control">-->

<!--                <input class="is-checkradio " id="require_address" type="checkbox"-->
<!--                       v-model="sm.require_address">-->
<!--                <label for="require_address">-->
<!--                    Дали е потребна адреса?-->
<!--                </label>-->
<!--            </div>-->

<!--        </div>-->

        <div class="field">
            <div class="control">
                <input class="is-checkradio " id="labeled" type="checkbox"
                       v-model="sm.labeled">
                <label for="labeled">
                    Дали нарачките се подредени со број и буква?
                </label>
            </div>
        </div>


        <template v-slot:footer>
            <button class="button is-success" v-on:click="submitShippingMethod">Зачувај</button>
        </template>
    </Modal>
</template>

<script>

    export default {
        name: "CreateShippingMethodModal",
        data() {
            return {
                sm: {
                    name: "",
                    min_price: 0,
                    enabled: true,
                    labeled: true,
                }
            }
        },
        methods: {
            submitShippingMethod() {
                this.$http.post("/shipping_methods", this.sm)
                    .then(() => {
                        this.$toast({message: "Успешно креиравте нов начин на достава", type: "is-success"});
                        this.$store.dispatch("getShippingMethods");
                        this.$router.replace({name: 'shippingMethods'})
                    })
            }
        }
    }
</script>
