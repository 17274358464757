<template>
    <section class="hero is-link is-fullheight">
        <div class="hero-body">
            <div class="container">
                <div class="columns is-centered">
                    <div class="column is-5-tablet is-4-desktop is-4-widescreen">
                        <form @submit.prevent="login" class="box">
                            <div class="field">
                                <label for="" class="label is-medium">Електронска пошта:</label>
                                <div class="control has-icons-left">
                                    <input type="email" name="email" v-model="email" placeholder="пр. ime.prezime@gmail.com"
                                           class="input is-medium" required autocomplete="email">
                                    <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
                                </div>
                            </div>
                            <div class="field">
                                <label for="" class="label is-medium">Лозинка:</label>
                                <div class="control has-icons-left">
                                    <input type="password" name="password" v-model="password" placeholder="*******" class="input is-medium"
                                           autocomplete="password" required>
                                    <span class="icon is-small is-left">
                  <i class="fa fa-lock"></i>
                </span>
                                </div>
                            </div>
                            <div class="field is-spaced">
                                <button type="submit" v-bind:class="{'is-loading': authStatus==='loading'}" class="button is-success is-fullwidth is-medium">
                                    Најава
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'Login',
        data() {
            return {
                email: "",
                password: ""
            };
        },
        computed:{
            authStatus: function () {
                return this.$store.getters.authStatus;
            }
        },
        methods: {
            login: function () {
                const {email, password} = this
                this.$store.dispatch("login", {email, password}).then(() => {
                    this.$router.push({name:"secured"})
                }).catch(err => console.log(err))
            }
        },
        mounted() {
            document.body.classList.remove("has-navbar-fixed-top")
        }
    }
</script>
