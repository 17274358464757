<template>
    <GoogleMapLoader
            :mapConfig="mapConfig"
            ref="map"
    >
        <template slot-scope="{ google, map }">
            <GoogleMapMarker
                    v-for="marker in markers"
                    :key="marker.id"
                    :marker="marker"
                    :google="google"
                    :map="map"
            />
            <GoogleMapMarker
                    v-if="myLocation"
                    :marker="myLocation"
                    :google="google"
                    :map="map"
            />
        </template>
    </GoogleMapLoader>
</template>

<script>
    import GoogleMapLoader from "../components/GoogleMap/GoogleMapLoader";
    import { mapSettings} from "../assets/functions";
    import GoogleMapMarker from "../components/GoogleMap/GoogleMapMarker";

    export default {
        name: "OrdersMap",
        components: {GoogleMapMarker, GoogleMapLoader},
        data() {
            return {
                markers: [],
                location: null,
                watchID: null
            }
        },


        computed: {
            myLocation() {
                if (!this.location) return null;
                return {
                    id: "GPS",
                    position: this.location,
                    center:true,
                    icon: {url: "https://admin.dnp.mk/img/icons/favicon-32x32.png"},
                    zIndex: 10,
                }
            },
            mapConfig() {
                return {
                    ...mapSettings,
                    disableDefaultUI: true,
                    gestureHandling: "cooperative",
                    center: {
                        lat: 41.1841091,
                        lng: 22.4798533
                    },
                }
            },
            apiKey() {
                return "mapsApiKey";
            },
        },
        mounted() {
            this.fetchOrders();
            const options = {
                enableHighAccuracy: true,
                maximumAge: 0
            };
            this.watchID = this.$watchLocation(options)
                .then((e)=> this.location = e)
                .catch(() => this.$toast({type:"is-danger", message:"Не може да се добие локација"}))
        },
        beforeDestroy() {
            this.$clearLocationWatch(this.watchID)
        },
        methods: {
            fetchOrders(){
                this.$http.get("/orders"+this.$urify({...this.$route.query, timestamp:new Date().getTime(),  print:true}))
                    .then(a => {
                      const withoutCoords = a.data.data.orders.filter(a=> !a.address.lat || !a.address.long)
                        if(withoutCoords.length){
                          this.$toast({type:'is-danger', message:`${withoutCoords.length} нарачки немаат кординати`})
                        }
                        this.markers  = a.data.data.orders
                            .filter(a=> a.address.lat && a.address.long)
                            .map(a=>{
                            const pos =  {
                                lat:a.address.lat,
                                lng:a.address.long,
                            };

                            let obj = {
                                id: a.id,
                                position: pos,
                                title:a.user.name,
                                label:a.shipping_order,
                                orderInfoWindow:true,
                                order:a,
                                important: !!a.description
                            }
                            let color;
                            switch (a.current_status) {
                                case "DONE" : color = "35AC5E"; break;
                                case "PACKED" : color = "FFDD57"; break;
                                case "SUBMITTED" :
                                case "CREATED" : color = "363636";break;
                                case "CANCELLED" :
                                case "RETURNED" : color = "FF3860";break;
                                default: color = "174287";
                            }
                            Object.assign(obj, {iconColor: color})
                            return obj;
                        });
                    })
            },
        }
    }
</script>
<style scoped>
    .google-map {
        position: relative;
        height: calc(100vh - 3.25rem);
    }

    body {
        overflow: hidden;
    }

</style>
