<template>
    <Modal title="Измени амбалажа" @close="$router.push({name:'sizes'})">
        <div v-if="size">

        <div class="field">
            <label class="label">Назив</label>
            <div class="control">
                <input class="input" :value="size.name" @input="size.name = $l2c($event.target.value)" type="text" placeholder="Мало">
            </div>
        </div>

        <div class="field">
            <label class="label">Големина</label>
            <div class="control">
                <input class="input" v-model="size.size" type="text" placeholder="0.320ml">
            </div>
        </div>
        </div>
        <Loading v-else variant="is-white"/>

        <template v-slot:footer>
            <button class="button is-success" v-on:click="submitSize" :disabled="!size">Зачувај</button>
        </template>
    </Modal>
</template>

<script>
    import Loading from "../Loading";

    export default {
        name: "EditSizeModal",
        components: {Loading},
        data(){
            return {
                size:null,
            }
        },
        methods:{
            submitSize() {
                this.$store.dispatch("editSize", this.size).then(() => {
                    this.$router.push({name:'sizes'})
                })
            }
        },
        computed: {
            getSize() {
                return this.$store.getters.getSizeById(this.$route.params.sizeId);
            }
        },
        watch: {
            getSize: function (val) {
                if (!this.size) this.size = this.$deepCopy(val);
            }
        },
        created() {
            this.size = this.$deepCopy(this.$store.getters.getSizeById(this.$route.params.sizeId));
        }
    }
</script>

<style scoped>

</style>