import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import './assets/font-awesome/css/font-awesome.min.css'
import './assets/global.css'

import 'bulma-checkradio/dist/css/bulma-checkradio.min.css'
import 'bulma/bulma.sass'
import api from "./api/api";
import PageHeader from "./components/PageHeader";
import Modal from "./components/Modal";
import Message from "./components/Message";
import VueClipboard from 'vue-clipboard2'


import VueGeolocation from 'vue-browser-geolocation';
import {
    asyncLocalStorage,
    clientsCountFilter,
    colors,
    deepCopy,
    formatCityFilter,
    formatStreetFilter,
    l2c, linkPhoneNumberFilter,
    linkToAddressFilter,
    linkToCoordsFilter,
    ordersCountFilter,
    orderStatuses,
    phoneNumberFilter,
    rbn,
    shippingMethodFilter,
    sizeClassFilter,
    statusByFilter,
    statusClassFilter,
    statusFilter,
    statusTimeFilter, statusTimeNowFilter,
    svgMarker,
    urify
} from "./assets/functions";
import {toast} from "bulma-toast";
import './registerServiceWorker'

Vue.config.productionTip = true

const moment = require('moment')
require('moment/locale/mk')
Vue.use(require('vue-moment'), {
    moment
})
Vue.use(VueGeolocation);

Vue.use(VueClipboard)
Vue.prototype.$toast = toast;
Vue.prototype.$http = api;
Vue.prototype.$orderStatuses = orderStatuses;
Vue.prototype.$urify = urify;
Vue.prototype.$deepCopy = deepCopy;
Vue.prototype.$l2c = l2c;
Vue.prototype.$rbn = rbn;
Vue.prototype.$asyncLocalStorage = asyncLocalStorage;
Vue.prototype.$svgMarker = svgMarker;
// Vue.prototype.$openCoords = openCoords;
// Vue.prototype.$openAddress = openAddress;
Vue.prototype.$colors = colors;
Vue.component("PageHeader", PageHeader);
Vue.component("Modal", Modal);
Vue.component("Message", Message);
Vue.filter("phoneNumber", phoneNumberFilter)
Vue.filter("formatCity", formatCityFilter)
Vue.filter("formatStreet", formatStreetFilter)
Vue.filter("status", statusFilter)
Vue.filter("statusBy", statusByFilter)
Vue.filter("statusTime", statusTimeFilter)
Vue.filter("statusTimeNow", statusTimeNowFilter)
Vue.filter("shippingMethod", shippingMethodFilter)
Vue.filter("statusClass", statusClassFilter)
Vue.filter("linkToCoords", linkToCoordsFilter)
Vue.filter("linkToAddress", linkToAddressFilter)
Vue.filter("ordersCount", ordersCountFilter)
Vue.filter("clientsCount", clientsCountFilter)
Vue.filter("sizeClass", sizeClassFilter)
Vue.filter("linkPhoneNumber", linkPhoneNumberFilter)
// Vue.filter("variantName", variantNameFilter)

router.beforeEach((to, from, next) => {
    const auth = store.getters.isAuthenticated;
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (auth) {
            next()
            return
        }
        next({name:'login'})
    } else if (to.matched.some(record => record.meta.requiresAuth === false)) {
        if (!auth) {
            next()
            return;
        }
        next({name:"secured"})
    } else {
        next()
    }
})

router.afterEach(to => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if(nearestWithTitle) document.title = nearestWithTitle.meta.title;
})

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
