<template>
    <section class="section">
        <div class="container">
            <article class="panel is-info">
                <div class="panel-heading">
                    Градови
                </div>
                <div class="panel-block is-block" v-for="city in cities" v-bind:key="city.id">
                    <div class="columns is-paddingless">
                        <div class="column">
                            <div class="columns is-mobile is-paddingless">
                                <div class="column is-2">{{city.id}}</div>
                                <div class="column">{{city.name}}</div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="columns is-mobile is-paddingless">
                                <div class="column ">
                                    <span class="icon has-text-dark"><i class="fa fa-address-card-o"></i></span>
                                    {{city.addresses_count}}
                                </div>
                                <div class="column ">
                                    <span class="icon has-text-dark"><i class="fa fa-shopping-cart"></i></span>
                                    {{city.orders_count}}
                                </div>
                                <div class="column  ">
                                    <div class="buttons is-right">
                                        <router-link :to="{name:'orders', query:{city_id:city.id}}"
                                                     class="is-small button is-light">
                                            <span>Нарачки</span>
                                            <span class="icon">
                                    <i class="fa fa-external-link"></i>
                                </span>
                                        </router-link>
                                        <!--                                <router-link :to="{name:'editShippingMethod', params:{shippingMethodId: sp.id}}"-->
                                        <!--                                             class="is-small button is-link">-->
                                        <!--                                <span class="icon is-small">-->
                                        <!--                                    <i class="fa fa-pencil"></i>-->
                                        <!--                                </span>-->
                                        <!--                                </router-link>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-block" v-if="cities.length === 0">
                    <Loading variant="is-white"/>
                </div>
            </article>
        </div>
        <router-view></router-view>
    </section>
</template>
<script>


    import Loading from "../components/Loading";

    export default {
        name: "Cities",
        components: {Loading},
        computed: {
            cities: function () {
                return this.$store.getters.getCities
            }
        }
    }
</script>
