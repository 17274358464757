<template>
    <nav class="navbar is-fixed-top is-link" role="navigation" aria-label="main navigation">
        <div class="container">
            <div class="navbar-brand">
                <router-link :to="{name:'secured'}" class="navbar-item">
                    <img src="../assets/img/logo.png" height="28" alt="">
                </router-link>

                <router-link :to="{name:'createOrder'}" class="navbar-item ">
                    <span class="icon"><i class="fa fa-plus"></i></span>
                    <span>Нарачка</span>
                </router-link>

                <a role="button" class="navbar-burger burger" v-bind:class="{'is-active':navActive}"
                   v-on:click="navActive=!navActive" ref="navBarButton"  aria-label="menu"
                   aria-expanded="false"
                   data-target="navbarBasicExample">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>


            <div id="navbarBasicExample" v-bind:class="{'is-active':navActive}" v-click-outside="hideIt" class="navbar-menu">
                <div class="navbar-start">
<!--                    <router-link :to="{name:'createOrder'}" class="navbar-item">-->
<!--                        Нова нарачка-->
<!--                    </router-link>-->
                    <router-link :to="{name:'orders'}" class="navbar-item">
                        Нарачки
                    </router-link>
                    <router-link :to="{name:'clients'}" class="navbar-item ">
                        Клиенти
                    </router-link>
                    <router-link :to="{name:'products'}" class="navbar-item">
                        Продукти
                    </router-link>
                    <router-link :to="{name:'options'}" class="navbar-item">
                        Останато
                    </router-link>
                </div>

                <div class="navbar-end">
                    <div class="navbar-item has-dropdown is-hoverable">
                        <a class="navbar-link">
                            {{user.name}}
                        </a>

                        <div class="navbar-dropdown">
                            <a @click="logout" class="navbar-item ">
                                Излез
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </nav>
</template>

<script>
    import ClickOutside from 'vue-click-outside'
    export default {
        name: 'NavBar',
        data() {
            return {
                navActive: false
            }
        },
        computed: {
            user: function () {
                return this.$store.getters.getUser;
            }
        },
        methods: {
            logout: function () {
                this.$store.dispatch('logout')
                    .then(() => {
                        this.$router.push('/login')
                    })
            },
            hideIt(){
                this.navActive=false
            }
        },
        mounted() {
            this.popupItem = this.$refs.navBarButton;
        },
        directives:{
            ClickOutside
        },
        watch:{
            $route:function () {
                this.navActive=false;
            }
        }
    }
</script>
