<template>
  <Modal title="Измени типс" @close="$emit('update:show', false)">
    <div class="field">
      <label class="label">Денари</label>
      <div class="control">
        <input
          class="input"
          :value="value"
          @input="value = $event.target.value || 0"
          type="number"
          step="10"
          placeholder="Денари"
        />
      </div>
    </div>

    <template v-slot:footer>
      <button class="button is-success" v-on:click="submitTips">Зачувај</button>
    </template>
  </Modal>
</template>

<script>
export default {
  name: "AddTipsModal",
  props: {
    tips: Number,
  },
  data() {
    return {
      value: this.tips,
    };
  },
  methods: {
    submitTips() {
      this.$emit("save", { tips: this.value || 0 });
      this.$emit("update:show", false);
    },
  },
};
</script>
