<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>

    export default {
        created() {
            this.$store.dispatch("getProducts");
            this.$store.dispatch("getSizes");
            this.$store.dispatch("getTypes");
            this.$store.dispatch("getCities");
        }
    }
</script>

