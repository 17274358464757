<template>
    <div>
        <div v-if="!loading1 && !loading2">
            <OrdersStatisticsTable  :stats="stats1"/>
            <div class="pagebreak"></div>
            <OrdersStatisticsCitiesTable :stats="stats2"/>
        </div>
        <Loading v-else/>
    </div>
</template>

<script>
    import Loading from "../Loading";
    import OrdersStatisticsTable from "../Orders/OrdersStatisticsTable";
    import OrdersStatisticsCitiesTable from "../Orders/OrdersStatisticsCitiesTable";
    export default {
        name: "PrintOrderTableContainer",
        components: {OrdersStatisticsCitiesTable, OrdersStatisticsTable, Loading},
        data(){
            return {
                loading1:true,
                stats1:[],
                loading2:true,
                stats2:[]
            }
        },
        methods:{

        },
        updated() {
            if(!this.loading1 && !this.loading2)
                window.print();
        },
        created() {
            const url1 = this.$urify(Object.assign({}, this.$route.query, {statistics: true}), ["page"]);
            this.$http.get("/orders" + url1)
                .then(a => {
                    this.stats1 = a.data.data;
                    this.loading1 = false
                });

            const url2 = this.$urify(Object.assign({}, this.$route.query, {statistics_cities: true}), ["page"]);
            this.$http.get("/orders" + url2)
                .then(a => {
                    this.stats2 = a.data.data;
                    this.loading2 = false
                });

        }
    }
</script>
<style>
    @media print{

        thead
        {
            display: table-header-group;
        }
        tfoot{
            display: table-row-group;
        }

    }

</style>
