<template>
    <div class="modal is-active">
        <div class="modal-background" v-on:click="$emit('close')"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">{{title}}</p>
                <button class="delete" aria-label="close" v-on:click="$emit('close')"></button>
            </header>
            <section class="modal-card-body">
                <slot></slot>
            </section>
            <footer class="modal-card-foot">
                <slot name="footer"></slot>
                <button class="button" v-on:click="$emit('close')">Затвори</button>
            </footer>
        </div>
    </div>
</template>

<script>
    export default {
        name:"Modal",
        props:["title", "active"],
    }
</script>