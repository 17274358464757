<template>
    <nav class="panel is-info">
        <div v-if="user">

            <UserInfo :orderLoading="orderLoading" @setOrder="setOrder"
                      :order="order"
                      :user="user"
                      v-on:update:orderLoading="$emit('update:orderLoading', $event)"/>
        </div>
        <div v-else>
            <div class="panel-heading">
                <div class="field has-addons">
                    <p class="control has-icons-left is-expanded">
                        <input class="input is-size-5-tablet is-size-6-desktop"
                               :value="searchQuery" v-on:keypress.enter="setUserName(searchQuery)"
                               @input="handleSearchQuery"

                               type="text" placeholder="Пребарувај">
                        <span class="icon is-size-5-tablet is-size-6-desktop is-left"><i class="fa fa-search"
                                                                                         aria-hidden="true"/></span>
                    </p>
                    <p class="control">
                        <button @click="tempSavePhone" class="button is-light"><span class="fa fa-phone"></span>
                        </button>
                    </p>
                </div>
            </div>
            <a v-if="searchQuery" class="panel-block" v-on:click.once="setUserName(searchQuery)">
                <div class="tags has-addons ">
                    <span class="tag is-medium is-size-4-tablet is-size-6-desktop">Креирај:</span>
                    <span class="tag is-medium is-primary is-size-4-tablet is-size-6-desktop">{{searchQuery}}</span>
                </div>
            </a>
            <div v-if="!usersLoading">
                <SelectClientItem v-for="c in users" v-bind:key="c.id" :client="c" @setUserId="setUserId"/>
            </div>
            <Loading v-else variant="is-white" height="10"/>
        </div>
    </nav>

</template>

<script>
    import UserInfo from "./UserInfo";
    import SelectClientItem from "./SelectClientItem";
    import Loading from "../Loading";

    export default {
        name: "NewOrderClient",
        props: ["user", "order", "orderLoading", "notFinishedOrder"],
        components: {Loading, SelectClientItem, UserInfo},
        data() {
            return {
                users: [],
                searchQuery: "",
                tempPhone: null,
                cancelUsers: null,
                timeoutUsers: null,
                usersLoading: false
            }
        },
        methods: {
            handleSearchQuery(e){
                let sel = e.target.selectionStart;
                this.searchQuery = this.$l2c(this.$rbn(e.target.value))
                this.$nextTick(()=>{
                    e.target.selectionStart = e.target.selectionEnd = sel;
                })
            },
            tempSavePhone() {
                if (new RegExp(/^\+*[0-9]{9,12}$/).exec(this.searchQuery)) {
                    this.tempPhone = this.searchQuery;
                    this.searchQuery = "";
                    this.$toast({message: "Привремено зачуван телефонски број", type: 'is-success'});
                } else
                    this.$toast({message: "Не валиден телефонски број", type: 'is-danger'});

            },
            setUserName(event) {
                if (!new RegExp(/^[љњертѕуиопшѓасдфгхјклчќзџцвбнмжЉЊЕРТЅУИОПШЃЖАСДФГХЈКЛЧЌЗЏЦВБНМ. \-()]{2,}$/).exec(event)) {
                    this.$toast({message: "Не валидно име и презиме", type: 'is-danger'});
                    return;
                }
                this.$emit("update:orderLoading", true);
                let send = {name: event};
                if (this.tempPhone) Object.assign(send, {phone_number: this.tempPhone})
                this.$http.post("/orders/" + this.order.id + "/user", send)
                    .then(a => {
                        this.tempPhone = null;
                        this.setOrder(a.data.data)
                        this.$emit("update:orderLoading", false);

                    });
            },
            setUserId(event) {
                this.$emit("update:orderLoading", true);
                this.$http.patch("/orders/" + this.order.id + "/user/" + event)
                    .then(a => {
                        this.setOrder(a.data.data)
                        this.$emit("update:orderLoading", false);
                        //check if user has order with status submited or created
                        this.$emit("update:notFinishedOrder", !!a.data.data.not_finished)
                    });
            },
            searchUsers() {
                this.usersLoading = true;
                if (this.cancelUsers) this.cancelUsers.cancel();
                clearTimeout(this.timeoutUsers);
                this.timeoutUsers = setTimeout(() => {
                    this.cancelUsers = this.$http.CancelToken.source();
                    this.$http.get("/users?search=" + this.searchQuery+"&simple", {cancelToken: this.cancelUsers.token}).then(a => {
                        if (!a) return;
                        this.users = a.data.data;
                        this.usersLoading = false;
                    });
                }, 400);

            },
            setOrder(order) {
                this.$emit("setOrder", order);
            }
        },
        watch: {
            searchQuery: function () {
                this.searchUsers()
            }
        },
        created() {
            this.searchUsers();
        },

    }
</script>

<style>
</style>