<template>
    <table class="table is-narrow is-bordered is-fullwidth">
        <thead>
        <tr>
            <th>Град</th>
            <th>Пакети</th>
            <th>Луѓе</th>
            <th>Вкупно</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="s in stats" :key="s.city_id">
            <td>{{cityName(s.city_id)}}</td>
            <td>{{s.packages}}</td>
            <td>{{s.users}}</td>
            <td>
                <span class="icon"><i class="fa fa-truck"></i></span>: <span>{{s.delivery}} ден.</span><br>
                <span class="icon"><i class="fa fa-minus"></i></span>: <span>{{s.discount}} ден.</span><br>
                <span class="icon"><i class="fa fa-check-square"></i></span>: <span>{{s.final_total}} ден.</span>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        name: "OrdersStatisticsCitiesTable",
        props:["stats"],
        computed:{
            cityName: function () {
                return id => this.$store.getters.getCityNameById(id)
            }
        },

    }
</script>
