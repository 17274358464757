<template>
    <nav class="panel is-info">
        <div class="panel-block is-block">
            <div class="field ">
                <div class="control is-expanded has-icons-left">
                    <div class="select is-size-4-tablet is-size-6-desktop is-fullwidth">
                        <span class="icon"><i class="fa fa-truck"></i></span>
                        <select :disabled="!order.can_edit" :value="order.shipping_method_id"
                                @change="patchOrder({shipping_method_id:$event.target.value})">
                            <option v-if="!shippingMethods.find(a=> a.id === order.shipping_method_id)"
                                    :value="order.shipping_method_id">
                                {{order.shipping_method | shippingMethod}}
                            </option>
                            <option v-for="sm in shippingMethods" :value="sm.id" v-bind:key="sm.id">
                                {{sm | shippingMethod}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="field ">
                <div class="control  has-icons-left">
                    <span class="icon is-size-4-tablet is-size-6-desktop"><i class="fa fa-info-circle"></i></span>
                    <input :value="order.description"
                           @input="handleDescriptionChange"
                           class="input is-size-4-tablet is-size-6-desktop"
                           placeholder="Коментар за нарачката" type="text">
                </div>
            </div>
            <div class="field ">
                <div class="control is-size-4-tablet is-size-6-desktop  has-icons-left is-expanded">
                    <div class="select is-fullwidth">
                        <span class="icon"><i class="fa fa-star"></i></span>
                        <select :value="order.status.status" @change="patchOrder({status:$event.target.value})">
                            <option :disabled="(key==='CREATED' && key !== order.status.status) || (key !== 'CREATED' && order.status.status === 'CREATED')"
                                    :value="key" v-for="(val,key) in $orderStatuses" :key="key">
                                {{val}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="field has-addons">
                <div class="control is-expanded">
                    <div class="control has-icons-left">
                        <span class="icon is-size-4-tablet is-size-6-desktop"><i class="fa fa-minus"></i></span>
                        <input :value="order.discount || ''" @input="changeDiscount($event.target.value)"
                               class="input is-size-4-tablet is-size-6-desktop"
                               placeholder="Попуст" step="10" min="0" :max="order.total" type="number"
                               :disabled="!order.can_edit">
                    </div>
                </div>
                <div class="control  ">
                    <span class="tag is-success is-fullwidth is-large is-size-3-tablet is-size-5-desktop">Вкупно: {{(order.final_total)+' ден.'}}</span>
                </div>
            </div>
            <div class="field has-addons">
                <div class="control is-expanded">
                    <button class="button is-success is-fullwidth is-size-4-tablet is-size-6-desktop"
                            :disabled="order.current_status === 'CREATED'"
                            v-on:click="$emit('newOrder')">Нова нарачка
                    </button>
                </div>
                <div class="control is-expanded">
                    <router-link :disabled="order.current_status === 'CREATED'" :to="{name:'viewOrder', params:{orderId:order.id}}"
                                 class="button is-link is-fullwidth is-size-4-tablet is-size-6-desktop">
                        Види нарачка
                    </router-link>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>

    export default {
        name: "FinishOrder",
        props: ["order"],
        data() {
            return {
                cancelDescription: null,
                cancelDiscount: null,
                timeoutDescription: null,
                timeoutDiscount: null,

            };
        },
        methods: {
            handleDescriptionChange(e) {
                let sel = e.target.selectionStart;
                e.target.value = this.$l2c(e.target.value)
                this.changeDescription(e.target.value)

                this.$nextTick(() => {
                    e.target.selectionStart = e.target.selectionEnd = sel;
                })
            },
            patchOrder(data, token = null) {
                this.$http.patch("/orders/" + this.order.id, data, {cancelToken: token})
                    .then(a => {
                        if (!a) return;
                        this.setOrder(a.data.data);
                        this.$toast({message: "Зачувано", type: "is-success"})

                    })
            },
            changeDiscount(discount) {
                clearTimeout(this.timeoutDiscount);
                if (this.cancelDiscount) this.cancelDiscount.cancel()
                this.timeoutDiscount = setTimeout(() => {
                    if (!discount) discount = 0;
                    this.cancelDiscount = this.$http.CancelToken.source();
                    this.patchOrder({discount: discount}, this.cancelDiscount.token);
                }, 500)
            },

            changeDescription(desc) {
                clearTimeout(this.timeoutDescription);
                if (this.cancelDescription) this.cancelDescription.cancel()
                this.timeoutDescription = setTimeout(() => {
                    this.cancelDescription = this.$http.CancelToken.source();
                    this.patchOrder({description: desc}, this.cancelDescription.token);
                }, 500)

            },
            setOrder(order) {
                this.$emit("setOrder", order);
            },
        },

        computed: {
            shippingMethods: function () {
                return this.$store.getters.getShippingMethods;
            },

        },

    }
</script>
