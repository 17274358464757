<template>
    <div class="page-wrapper">
        <NavBar/>
        <router-view></router-view>
    </div>
</template>

<script>
    // @ is an alias to /src
    // import HelloWorld from '@/components/HelloWorld.vue'
    import NavBar from "../components/NavBar";

    export default {
        name: 'Secured',
        components: {
            NavBar
        },
        created: function () {
            this.init();

        },
        methods: {
            init: function () {
                this.$store.dispatch("getShippingMethods");
            }
        },
        mounted() {
            document.body.classList.add("has-navbar-fixed-top")
        }
    }
</script>