<template>
    <a class="panel-block is-size-4-tablet is-size-6-desktop" v-on:click.self="dial(number)">
                            <span class="panel-icon">
                                <i class="fa fa-phone" aria-hidden="true"/>
                            </span>
        {{number | phoneNumber}}
        <button v-on:click="removePhoneNumber(number)" class=" is-size-6-tablet is-size-7-desktop button is-danger is-small"
                style="margin-left:auto;"><span
                class="icon is-small "><i class="fa fa-remove"/></span></button>
    </a>
</template>
<script>
    export default {
        name: "ListPhoneItem",
        props: ["number", "user"],
        methods: {
            dial(number) {
                window.location = 'tel:' + number;
            },
            removePhoneNumber(number) {
                this.$http.delete("/users/" + this.user.id + "/phones/" + number)
                    .then((a) => {
                        this.$emit("reload", a.data.data)
                    });
            },
        }
    }

</script>