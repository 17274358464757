<template>
    <section class="section">
        <div class="container">
            <PageHeader title="Нова нарачка">
                <div class="level-item" v-if="order">

                  <div class="tags">
                        <span class="tag " :title="status.created_at | statusTime"  :class="status | statusClass" v-for="status in order.statuses" :key="status.status">{{status | status}}</span>
                    </div>
                </div>
            </PageHeader>
            <div class="columns is-multiline">
                <div class="column is-8-desktop is-full-touch">
                    <div v-for="(p,i) in products" v-bind:key="p.id" class="card">
                        <header class="card-header" v-if="allAvailable || p.variants.some(v=> v.can_order || getItemQuantity(v))"
                                @click="clickProduct(i, $event)">
                            <p class="card-header-title ">
                                <span class="tag is-medium is-white is-size-4-tablet is-size-6-desktop" v-bind:class="{'is-success': getItemCalc(p).quantity}">
                                    {{p.name}}
                                </span>
                            </p>
                            <p class="card-header-icon">
                                <span class="tag is-medium is-rounded"
                                      v-bind:class="{'is-success': getItemCalc(p).quantity}">
                                    <b>{{getItemCalc(p).quantity}} ставки: {{getItemCalc(p).sum}} ден</b>
                                </span>

                            </p>

                        </header>
                        <div class="card-content"
                             v-show="activeProduct===i && (allAvailable || p.variants.some(v=> v.can_order || getItemQuantity(v)))">
                            <div class="columns  is-multiline">
                                <EditOrderVariant
                                        :allAvailable="allAvailable"
                                        :price="getItemPrice(v)"
                                        :quantity="getItemQuantity(v)"
                                        v-bind:key="v.id" v-for="v in p.variants"
                                        :variant="v"
                                        :can_edit="order.can_edit"
                                        @setItem="setItem"
                                        :itemCache="itemsCache.find(a=> a.variant_id === v.id)"
                                        @setItemQuantity="setItemQuantity"
                                />
                            </div>
                        </div>
                    </div>
                    <Loading variant="is-light" v-if="!products.length"/>
                    <div class="card">
                        <div class="card-footer">
                            <div class="card-footer-item" style="flex-grow:initial">
                              <button @click="allAvailable = !allAvailable" class="button is-small is-block" :class="{'is-danger': allAvailable}">
                                <span class="icon"><i class="fa" :class="{'fa-check': !allAvailable, 'fa-minus': allAvailable}" ></i></span>
                              </button>
                            </div>
                            <p class="card-footer-item">
                                <b>Вкупно:</b>
                            </p>
                            <p class="card-footer-item ">
                                <span class="tag is-medium is-light is-rounded">{{getOrder.quantity}} ставки</span>
                            </p>
                            <p class="card-footer-item ">
                                <span class="tag is-medium is-success is-rounded">{{getOrder.total}} ден.</span>
                            </p>
                        </div>
                    </div>

                </div>
                <div class="column is-4-desktop is-full-touch">
                    <div v-show="!orderLoading">
                        <NewOrderClient :notFinishedOrder.sync="notFinishedOrder" :orderLoading.sync="orderLoading"
                                        :user="order.user" :order="order" @setOrder="setOrder"/>
                        <FinishOrder v-bind:order="order" v-if="order.user" @newOrder="fetchLast" @setOrder="setOrder"/>
                    </div>
                    <Loading v-show="orderLoading" variant="is-light" height="20"/>
                </div>
            </div>
        </div>
        <NotFinishedOrderForUser :notFinishedOrder.sync="notFinishedOrder" :order.sync="order" v-if="notFinishedOrder"/>
    </section>
</template>
<script>
    import NewOrderClient from "../../components/NewEditOrder/NewOrderClient";
    import FinishOrder from "../../components/NewEditOrder/FinishOrder";
    import EditOrderVariant from "../../components/NewEditOrder/EditOrderVariant";
    import Loading from "../../components/Loading";
    import NotFinishedOrderForUser from "../../components/NewEditOrder/NotFinishedOrderForUser";

    export default {
        name: "NewEditOrder",
        components: {NotFinishedOrderForUser, Loading, EditOrderVariant, NewOrderClient, FinishOrder},
        data() {
            return {
                activeProduct: -1,
                orderLoading: true,
                order: {
                    items: [],
                    shipping_method_id: null,
                },
                notFinishedOrder: false,
                cancelItems: null,
                timeoutItems: null,
                itemsCache:[],
                allAvailable:false
            }
        },
        methods: {
            clickProduct(i) {
                this.activeProduct = this.activeProduct !== i ? i : -1;
            },
            setOrder(order) {
                this.order = order;
            },
            setItemQuantity(variant, q) {
                let element = this.order.items.find(a => a.variant_id === variant.id);
                let toSet = 0;
                if (element) {
                    let next = element.quantity + parseInt(q);
                    toSet = next >= 0 ? next : 0;
                } else {
                    if (q < 0) q = 0;
                    toSet = q;
                }
                this.setItem("quantity", toSet, variant)
            },
            setItem(type, value, variant) {
                let element = this.order.items.find(a => a.variant_id === variant.id);
                let q = parseInt(value) || 0;
                q = q > 10000 ? 10000 : q;
                if (element) {
                    element[type] = q;
                } else {
                    element = {
                        order_id: this.order.id,
                        variant_id: variant.id,
                        quantity: type === "quantity" ? q : 0,
                        price: type === "price" ? q : variant.price
                    }
                    this.order.items.push(element);
                }
                clearTimeout(this.timeoutItems);
                if (this.cancelItems) this.cancelItems.cancel();
                this.timeoutItems = setTimeout(() => {
                    this.cancelItems = this.$http.CancelToken.source();
                    this.$http.patch("/orders/" + this.order.id + "/order_items",
                        {"items": this.order.items},
                        {cancelToken: this.cancelItems.token})
                        .then(a => {
                            if (!a) return;
                            this.setOrder(a.data.data);
                            this.$toast({message: "Зачувано", type: "is-success"})
                        }).catch(() => {
                        this.$toast({message: "Грешка", type: "is-danger"})

                    })
                }, 700);

            },
            fetchLast() {
                this.$http.get("/orders/exists_or_create")
                    .then(a => {
                        this.$router.replace({
                            name: "editOrder",
                            params: {orderId: a.data.data.id},
                        });

                        this.order = a.data.data;
                        this.itemsCache = this.$deepCopy(a.data.data.items);
                        this.orderLoading = false;
                    });
            },
            fetchById(id) {
                this.$http.get("/orders/" + id)
                    .then(a => {
                        this.order = a.data.data;
                        this.itemsCache = this.$deepCopy(a.data.data.items);
                        this.orderLoading = false;
                    });
            },

        },
        computed: {

            products: function () {
                return this.$store.getters.getProducts;
            },
            getItemQuantity: function () {
                return variant => {
                    let o = this.order.items.find(a => a.variant_id === variant.id);
                    return o ? o.quantity : 0;
                }
            },
            getItemCalc: function () {
                return product => {
                    return product.variants.reduce((acc, v) => {
                        const item = this.order.items.find(a => a.variant_id === v.id);
                        if (!item) return acc;
                        return {quantity: acc.quantity + item.quantity, sum: acc.sum + item.quantity * item.price}
                    }, {quantity: 0, sum: 0});
                }
            },
            getOrder: function () {
                return this.products.reduce((acc, p) => {
                    const val = this.getItemCalc(p);
                    acc.quantity += val.quantity;
                    acc.total += val.sum;
                    return acc;
                }, {quantity: 0, total: 0});
            },
            getItemPrice: function () {
                return variant => {
                    let o = this.order.items.find(a => a.variant_id === variant.id);
                    return o ? o.price : variant.price;
                }
            },


        },
        watch: {
            $route(to) {
                if (to.name === "createOrder"){
                    this.fetchLast();
                    this.$store.dispatch("getProducts");
                }
            }
        },
        created() {
            this.$store.dispatch("getProducts");
            const id = this.$route.params.orderId;
            if (id)
                this.fetchById(id)
            else
                this.fetchLast();
        },

    }
</script>
<style scoped>
    .card-header {
        background: rgb(235, 235, 235);
    }

    .card-footer-item {
        justify-content: flex-end;
    }
</style>

