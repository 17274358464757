<template>
    <div >
        <div class="columns is-mobile is-vcentered is-marginless" >
            <div class="column is-3 pb-0 pt-0">
                <img src="../../assets/img/logo-new.png" width="100" alt="">
            </div>
            <div class="column ">
                <p class="is-size-6 has-text-right mr-4">
                    {{$route.query.date ? $route.query.date : "___________20____год."}}
                </p>
                <h1 class="title is-5">Испратница бр. {{$route.query.deliveryId ? $route.query.deliveryId : "________"}}</h1>
            </div>
        </div>
        <div class="columns is-mobile is-size-7 is-marginless">
            <div class="column">
                <h1 class="title is-6 is-uppercase is-marginless">ДНП Ѓорге</h1>
                <p class="has-text-weight-bold">Маршал Тито 99, Негорци, Гевгелија</p>
                <p class="has-text-weight-bold">Дејност: 10.39-Друга преработка и конзервирање на овошје и зеленчук</p>
            </div>
            <div class="column has-text-weight-bold">
                <p>За: </p>
                <p>Приватни лица <strong>по нарачка</strong></p>
            </div>
        </div>
        <b class="is-size-7">
            По документ:
        </b>
        <table class="table is-fullwidth">
            <thead class="is-size-7">
            <tr >
                <th>Ред. бр.</th>
                <th>Артикал</th>
                <th>Мера</th>
                <th>Количина</th>
                <th>Цена</th>
                <th>ИЗНОС денари</th>
            </tr>
            </thead>
            <tbody>
            <tr  class="is-size-7" v-for="(item, key) in variants" :key="item.variant_id">
                <td :style="{paddingTop: rowHeight, paddingBottom: rowHeight}">{{key+1}}</td>
                <td :style="{paddingTop: rowHeight, paddingBottom: rowHeight}">{{item.name}}</td>
                <td :style="{paddingTop: rowHeight, paddingBottom: rowHeight}">БР</td>
                <td :style="{paddingTop: rowHeight, paddingBottom: rowHeight}">{{item.quantity}}</td>
                <td :style="{paddingTop: rowHeight, paddingBottom: rowHeight}" style="white-space: nowrap">{{item.price}} ден.</td>
                <td :style="{paddingTop: rowHeight, paddingBottom: rowHeight}" style="white-space: nowrap">{{item.total}} ден.</td>
            </tr>
            </tbody>
        </table>
        <div>
            <div class="is-size-7">
                <p class="has-text-weight-bold" v-if="$route.query.vehicle">Возило: {{$route.query.vehicle}}</p>
                <p class="has-text-weight-bold" v-if="$route.query.driver">Возач: {{$route.query.driver}}</p>
            </div>
            <div class="columns is-mobile is-size-7 is-marginless">
                <div class="column">
                    <p class="has-text-centered">
                        Примил,<br/><br/>
                        _______________________
                    </p>
                </div>
                <div class="column">
                    <p class="has-text-centered">
                        Издал,<br/><br/>
                        _______________________
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PrintOrderDeliveryDocument",

        data() {
            return {
                stats: null,
            };
        },

        updated() {
            if (!this.loading)
                window.print();
        },
        computed: {
            variants: function () {
                return this.stats ? this.stats.variants.map(a => {
                    const variant = this.variant(a.variant_id);
                    a.name = variant.fullName
                    a.price = variant.price
                    return a;
                }): [];
            },
            variant: function () {
                return (id) => this.$store.getters.getVariant(id)
            },
            rowHeight:function () {
                if(this.variants.length > 14)
                    return (2/this.variants.length)+'em';
                return '0.5em';
            }
        },
        created() {
            const url = this.$urify(Object.assign({}, this.$route.query, {statistics: true}), ["page"]);
            this.$http.get("/orders" + url)
                .then(a => {
                    this.stats = a.data.data;
                    this.loading = false
                });
        }
    }
</script>

<style scoped>

</style>
