<template>
    <Modal title="Измени адреса" @close="$emit('update:show', false)">
        <div class="field has-addons">
            <p class="control is-expanded">
                <input class="input is-fullwidth" type="text"
                       :value="city.name" readonly>
            </p>
            <p class="control is-expanded">
                <input class="input is-fullwidth" list="municipalities" placeholder="Населба..." type="text"
                       :value="add.municipality" @change="lazyMun = $event.target.value" @input="handleInputChange($event, 'municipality')">
            </p>
        </div>
        <div class="field has-addons">
            <p class="control is-expanded">
                <input class="input" placeholder="Адреса" list="streetNames" type="text" :value="add.street_name"
                       @input="handleInputChange($event, 'street_name')">
            </p>
            <p class="control">
                <input class="input" placeholder="Број" style="width:6em" type="text"
                       :value="add.street_number" @input="handleInputChange($event, 'street_number')"
                       :disabled="!add.street_name">
            </p>
        </div>
        <div class="field is-grouped">
            <p class="control is-expanded">
                <input class="input is-fullwidth" :value="add.description"
                       @input="handleInputChange($event, 'description')" placeholder="Опис за адреса"
                       type="text">
            </p>
        </div>
        <div class="field is-grouped ">
            <p class="control is-expanded">
                <input class="input" placeholder="геог. ширина" type="number" v-model="add.lat">
            </p>
            <p class="control is-expanded">
                <input class="input" placeholder="геог. должина" type="number" v-model="add.long">
            </p>
            <p class="control" v-if="add.lat || add.long">
                <button class="button is-danger" @click="add.lat = ''; add.long ='' ">
                    <span class="icon"><span class="fa fa-remove"></span></span>
                </button>
            </p>
        </div>

        <PickAddressMap v-if="map" :locations="locations" :address.sync="add"/>

        <template v-slot:footer>
            <button :class="{'is-loading':loading}" v-on:click="$emit('save', add); loading=true"
                    class="button is-success">
                <span class="icon is-small"><i class="fa fa-check"/></span>
                <span>Зачувај</span>
            </button>
            <button v-on:click="openMap" v-if="!map" class="button is-link">
                <span class="icon"><i class="fa fa-map-marker"/></span>
                <span>Мапа</span>
            </button>
            <button v-else @click="map=false" class="button is-link">
                <span class="icon"><i class="fa fa-map-marker"/></span>
                <span>Затвори</span>
            </button>
        </template>
        <Fragment>
            <datalist id="municipalities">
                <option v-for="(mun, i) in municipalities" :key="i">{{mun}}</option>
            </datalist>
            <datalist id="streetNames">
                <option v-for="(str, i) in streetNames" :key="i">{{str}}</option>
            </datalist>
        </Fragment>
    </Modal>
</template>

<script>
    import PickAddressMap from "../../GoogleMap/PickAddressMap";
    import {Fragment} from "vue-fragment";

    export default {
        name: "EditClientAddress",
        components: {PickAddressMap, Fragment},
        props: ["address"],
        data() {
            return {
                lazyMun:"",
                loading: false,
                map: false,
                add: this.$deepCopy(this.address),
                locations: [],
                samples: {}

            }
        },
        methods: {
            handleInputChange(e, input) {
                let sel = e.target.selectionStart;
                let val = this.$l2c(e.target.value).trim()
                if (input === 'street_number') val = val.toUpperCase();
                this.add[input] = val;
                this.$nextTick(() => {
                    e.target.selectionStart = e.target.selectionEnd = sel;
                })
            },
            openMap() {
                this.locations = [];
                this.map = true;
                this.$http.get(`/addresses/${this.add.id}?geocode=osm`)
                    .then(a => {
                        this.locations = this.locations.concat(a.data.data.osm_geo)
                    })
                this.$http.get(`/addresses/${this.add.id}?geocode=google`)
                    .then(a => {
                        this.locations = this.locations.concat(a.data.data.google_geo)
                    })
                this.$http.get(`/addresses/${this.add.id}?geocode=here`)
                    .then(a => {
                        this.locations = this.locations.concat(a.data.data.here_geo)
                    })
                this.$http.get(`/addresses/${this.add.id}?geocode=dnp`)
                    .then(a => {
                        this.locations = this.locations.concat(a.data.data.dnp_geo)
                    })

            }
        },
        created() {
            this.$http.get("/addresses?city_id=" + this.address.city_id)
                .then(a => {
                    this.samples = a.data.data;
                })
            this.lazyMun = this.add.municipality;
        },
        computed: {

            municipalities(){
                const muns = Object.keys(this.samples).filter(a=> this.samples[a].find(b=> b===this.add.street_name));
                return !this.add.street_name
                    ? Object.keys(this.samples)
                    : muns.length ? muns : [];
            },
            streetNames(){
                return !this.lazyMun //if not selected
                || !this.samples[this.lazyMun] //if not exists
                    ? Object.values(this.samples).reduce((prev, acc) => acc.concat(prev),[]).filter((v, i, a) => a.indexOf(v) === i).sort()
                    :this.samples[this.lazyMun] ? this.samples[this.lazyMun] : [];
            },
            city: function () {
                return this.$store.getters.getCityById(this.address.city_id);
            },
        },
        watch: {
            "add.street_name": function (val) {
                if (val === "") this.add.street_number = "";
            }
        }
    }
</script>