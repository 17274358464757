<template functional>
    <router-link :to="{name:'viewProduction', params:{productId:props.product.id, variantId:props.variant.id}}"
                 class="panel-block is-block">
        <div class="columns is-mobile is-multiline py-3">
            <div class="column is-one-third-mobile" :class="props.variant.size.name | sizeClass"><b>{{props.variant.size.name}}
                - {{props.variant.type.name}}</b></div>
            <div class="column is-one-third-mobile">{{props.variant.price}} ден.</div>
            <div class="column is-one-third-mobile has-text-right-mobile ">
                <div class="tags has-addons">
                    <span class="tag"
                          :class="{'is-success': props.variant.available > 0, 'is-danger' : props.variant.available <=0}">
                    {{props.variant.available}} ставки
                    </span>
                    <span class="tag"
                          :class="{'is-success': props.variant.available_to_pack > 0, 'is-danger' : props.variant.available_to_pack <=0}">
                    {{props.variant.available_to_pack}} на палета
                    </span>
                </div>

            </div>
            <div class="column">
              <span
                    v-bind:class="{'is-success': props.variant.can_order, 'is-danger': !props.variant.can_order}"
                    class="tag">{{props.variant.can_order ? "Дозволени се нарачки" : "Не се дозволени нарачки"}}</span>
              <span
                  v-bind:class="{'is-success': props.variant.can_order_online, 'is-danger': !props.variant.can_order_online}"
                  class="tag">{{props.variant.can_order_online ? "Дозволени се online нарачки" : "Не се дозволени online нарачки"}}</span>
            </div>
            <div class="column is-narrow has-text-right">
                <div class="dropdown is-hoverable  is-right">
                    <div class="dropdown-trigger">
                        <button class="button is-small is-link" @click.prevent aria-haspopup="true"
                                aria-controls="dropdown-menu">
                            <span>Опции</span>
                            <span class="icon is-small">
                                            <i class="fa fa-angle-down" aria-hidden="true"/>
                                        </span>
                        </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                            <router-link
                                    :to="{name:'editVariant', params:{productId:props.product.id, variantId:props.variant.id}}"
                                    class="dropdown-item">
                                Измени
                                <span class="icon is-small is-right">
                                                <i class="fa fa-pencil" aria-hidden="true"/>
                                            </span>
                            </router-link>

                            <router-link
                                    :to="{name:'deleteVariant', params:{productId:props.product.id, variantId:props.variant.id}}"
                                    class="dropdown-item">
                                Избриши
                                <span class="icon is-small ">
                                            <i class="fa fa-remove"/>
                                            </span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
    export default {
        name: "ProductVariantListItem",
        props: {
            variant: Object,
            product: Object
        }
    }
</script>
