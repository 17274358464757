<template>
    <Message @closed="$emit('update:show', false)" variant="is-white">
        <h5 class="title is-5">Информации за испратница</h5>
        <div class="field ">
            <div class="control">
                <label>Испратница број</label>
                <input type="text" placeholder="1/20" class="input" v-model="deliveryId">
            </div>
        </div>
        <div class="field ">
            <div class="control">
                <label>Дата</label>
                <input type="text" class="input" v-model="date">
            </div>
        </div>
        <div class="field ">
            <div class="control">
                <label>Возило</label>
                <input type="text" class="input" v-model="vehicle">
            </div>
        </div>
        <div class="field ">
            <div class="control">
                <label>Возач</label>
                <input type="text" class="input" v-model="driver">
            </div>
        </div>
        <div class="buttons">
            <a  @click="print" class="button is-success " >
                Испечати
            </a>
            <button @click="$emit('update:show', false)" class="button is-danger ">
                Назад
            </button>
        </div>
    </Message>
</template>

<script>
    export default {
        name: "DeliveryDocumentModal",
        props:[
            "query",
            "show"
        ],
        data(){
            return {
                deliveryId:"",
                date: this.$moment(new Date()).format("DD.MM.YYYY[ год.]"),
                vehicle:"Приватно возило FIAT DOBLO GE9831 AB, сопственост: Ѓорге Бојмалиев",
                driver:"Мартин Бојмалиев",
            }
        },
        methods:{
            print(){
                let query = this.$deepCopy(this.query);
                query = Object.assign(query, {deliveryId: this.deliveryId, date:this.date, vehicle:this.vehicle, driver:this.driver})
                this.$router.push({name:'printOrderDeliveryDocument', query:query})
            }
        }

    }
</script>
