<template>
    <Modal title="Статистика за продукти" @close="close">
        <OrdersStatisticsTable v-if="!loading" :stats="stats"/>
        <Loading v-else variant="is-white"/>
        <template v-slot:footer>

        </template>
    </Modal>
</template>

<script>
    import Loading from "../Loading";
    import OrdersStatisticsTable from "./OrdersStatisticsTable";

    export default {
        name: "OrdersStatisticsProductsModal",
        components: {OrdersStatisticsTable, Loading},
        props:{
            query:Object,
        },
        data() {
            return {
                stats: [],
                loading: true,
            };
        },
        methods: {
            close() {
                this.$emit("update:show", false);
            },
        },
        created() {
            const url = this.$urify(Object.assign({}, this.query, {statistics: true}), ["page"]);
            this.$http.get("/orders" + url)
                .then(a => {
                    this.stats = a.data.data;
                    this.loading = false
                });
        }
    }
</script>
