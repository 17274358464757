<template>
    <Message  @closed="$router.push({name:'sizes'})" variant="is-danger">

        <h2 class="subtitle">Дали сте сигурни дека сакате да ја избришите селектираната амбалажа?</h2>
        <div class="buttons">
            <button class="button is-danger is-light" v-on:click="deleteSelected">Да</button>
            <button class="button is-success is-light" v-on:click="$router.push({name:'sizes'})">Не</button>
        </div>
    </Message>
</template>

<script>
    export default {
        name: "DeleteSizeModal",

        methods:{
            deleteSelected() {
                this.$store.dispatch("removeSize", this.$route.params.sizeId).then(() => {
                    this.$router.push({name:'sizes'})
                })
            },
        }
    }
</script>
