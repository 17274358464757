<template>
    <div>
        <h3 class="title is-6">{{order.user.name}}</h3>
        <h3 class="subtitle is-6 mb-1">{{order.address | formatStreet}}</h3>
        <i v-if="order.address.description">{{order.address.description}}</i>
        <p>Вкупно ставки: <b>{{order.quantity}}</b></p>
        <p v-if="order.delivery">Достава: <b>{{order.delivery}} ден.</b></p>
        <i v-if="order.description" :inner-html.prop="order.description | linkPhoneNumber"></i>
        <p>За плаќање: <b>{{order.final_total}} ден.</b></p>
        <p>
            <a v-for="a in order.user.phones" :key="a.number" :href="'tel:'+a.number">
                <span class="icon"><i class="fa fa-phone"></i></span>
                <span>{{a.number}}</span>
            </a>
        </p>
        <a :href="order.address | linkToCoords">Навигација</a> - <router-link :to="{name:'viewOrder', params:{orderId: order.id}}">Види нарачка</router-link>
    </div>
</template>

<script>
    export default {
        name: "OrderInfoBox",
        props:["order"],
    }
</script>
