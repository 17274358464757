<template>
    <div class="columns is-multiline is-mobile">
        <div class="column is-one-third-mobile has-text-centered">
            <p class="heading">Нови нарачки</p>
            <p class="title" v-if="stats">{{stats.orders_total.toLocaleString('mk')}}</p>
            <Loading v-else/>
        </div>
        <div class="column is-one-third-mobile has-text-centered">
            <p class="heading">Нови клиенти</p>
            <p class="title" v-if="stats">{{stats.clients_total.toLocaleString('mk')}}</p>
            <Loading v-else/>
        </div>
        <div class="column is-one-third-mobile has-text-centered">
            <p class="heading">Произведено ставки</p>
            <p class="title" v-if="stats">{{stats.production_total.toLocaleString('mk')}}</p>
            <Loading v-else/>
        </div>
        <div class="column is-half-mobile has-text-centered">
            <p class="heading">Продадено ставки</p>
            <p class="title" v-if="stats">{{stats.sold_quantity_total.toLocaleString('mk')}}</p>
            <Loading v-else/>
        </div>
        <div class="column is-half-mobile has-text-centered">
            <p class="heading">Вкупно ден.</p>
            <p class="title" v-if="stats">{{stats.sold_total_total.toLocaleString('mk')}}</p>
            <Loading v-else/>
        </div>

    </div>
</template>

<script>
    import Loading from "../Loading";
    export default {
        name: "LastXStatistics",
        components: {Loading},
        props:["stats"]
    }
</script>

