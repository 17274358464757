<template>
    <router-link :to="{ name: 'viewOrder', params: {orderId: order.id}}" class="panel-block is-block">
        <div class="columns is-paddingless is-mobile is-multiline">
            <div class="column is-full-mobile">
                <strong>{{order.quantity}}</strong> ставки,
                <strong>{{order.final_total}}</strong> ден.
            </div>
            <div class="column">
                <span class="tag is-link">{{order.shipping_method.name}}</span>
            </div>
            <div class="column is-narrow has-text-right">
                <span class="tag" :class="order.status | statusClass">
                {{order.status | status}}
            </span>
            </div>
        </div>
    </router-link>
</template>

<script>

    export default {
        name: "ClientOrderListItem",
        props: ["order"],
    }
</script>

<style scoped>

</style>