<template>
    <section class="section">
        <div class="container">
            <article class="panel is-info">
                <div class="panel-heading">
                    Дупликат корисници
                </div>
                <div v-for="num in phones" :key="num.key">
                    <div class="panel-block is-block">
                        <div class="columns is-paddingless">
                            <div class="column is-8 has-text-centered-mobile">
                                <b class="is-size-5">
                                    <span class="icon"><i class="fa fa-user"></i></span>
                                    <span>{{num.key}}</span>
                                </b>
                            </div>
                            <div class="column is-4">
                                <div class="field is-grouped is-grouped-right" >
                                    <div class="control is-expanded">
                                        <input type="text" v-model="num.name" class="input is-small">
                                    </div>
                                    <div class="control">
                                        <button :class="{'is-loading':num.loading}" @click="mergePhoneNumber(num)"
                                                class="button is-danger is-small">
                                            <span class="icon"><i class="fa fa-compress"></i></span>
                                            <span>Спои</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ClientListItem v-for="user in num.users" v-bind:key="user.id" :user="user"/>
                </div>

                <div class="panel-block" v-if="loading">
                    <Loading variant="is-white"/>
                </div>
                <div class="panel-block" v-if="!loading && phones.length ===0">
                    Нема пронајдено клиенти
                </div>
            </article>
        </div>
        <router-view></router-view>
    </section>

</template>

<script>
    import Loading from "../components/Loading";
    import ClientListItem from "../components/Clients/ClientListItem";

    export default {
        name: "ClientDuplicates",
        components: {ClientListItem, Loading},
        data() {
            return {
                phones: [],
                loading: true
            }
        },
        methods: {
            mergePhoneNumber(number) {
                number.loading = true;
                this.$http.patch("/users?merge", {data:number})
                .then(()=>{
                    this.fetchUsers();
                }).finally(()=>number.loading=false)
            },
            fetchUsers() {
                this.$http.get("/users?duplicates")
                    .then(a => {
                        const phones = a.data.data;
                        phones.forEach(phone => {
                            phone.name = phone.users[0].name;
                            phone.loading = false
                        });
                        this.phones = phones;
                    }).finally(() => this.loading = false)
            }
        },
        created() {
            this.fetchUsers();
        }
    }
</script>
