<template>
    <Modal title="Нова достава" @close="$router.replace({name:'shippingMethods'})">
        <div v-if="!loading">
            <div class="field">
                <label class="label">Назив</label>
                <div class="control">
                    <input class="input" :value="sm.name" @input="sm.name = $l2c($event.target.value)" type="text"
                           placeholder="Струмица достава 20.08.2020">
                </div>
            </div>
            <div class="field">
                <label class="label">Минимална цена за нарачки</label>
                <div class="control">
                    <input class="input" v-model="sm.min_price" min="0" type="number"
                           placeholder="0">
                </div>
            </div>

            <div class="field">
                <div class="control">

                    <input class="is-checkradio " id="enabled" type="checkbox"
                           v-model="sm.enabled">
                    <label for="enabled">
                        Дали е дозволено да се креираат нови нарачки?
                    </label>
                </div>

            </div>

<!--            <div class="field">-->
<!--                <div class="control">-->

<!--                    <input class="is-checkradio " id="require_address" type="checkbox"-->
<!--                           v-model="sm.require_address" disabled>-->
<!--                    <label for="require_address">-->
<!--                        Дали е потребна адреса?-->
<!--                    </label>-->
<!--                </div>-->

<!--            </div>-->

            <div class="field">
                <div class="control">
                    <input class="is-checkradio " id="labeled" type="checkbox"
                           v-model="sm.labeled" disabled>
                    <label for="labeled">
                        Дали нарачките се подредени со број и буква?
                    </label>
                </div>
            </div>

        </div>
        <Loading variant="is-white" v-else/>
        <template v-slot:footer>
            <button class="button is-success" v-on:click="submitShippingMethod" :disabled="loading">Зачувај</button>
        </template>
    </Modal>
</template>

<script>

    import Loading from "../Loading";

    export default {
        name: "EditShippingMethodModal",
        components: {Loading},
        data() {
            return {
                loading:true,
                sm: {
                    id:null,
                    name: "",
                    min_price: 0,
                    enabled: true,
                    labeled: true,
                }
            }
        },
        methods: {
            submitShippingMethod() {
                this.$http.patch("/shipping_methods/"+this.sm.id, this.sm)
                    .then(() => {
                        this.$toast({message: "Успешно ја зачувавте доставата", type: "is-success"});
                        this.$store.dispatch("getShippingMethods");
                        this.$router.replace({name: 'shippingMethods'})
                    })
            }
        },
        created() {
            this.$http.get("/shipping_methods/"+this.$route.params.shippingMethodId)
            .then(a=>{
                this.sm = a.data.data;
                this.loading=false;
            })
        }
    }
</script>
