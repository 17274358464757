<template>
    <section class="section">
        <div class="container">
            <article class="panel is-info">
                <div class="panel-heading">
                    Внеси корисници
                </div>
                <div class="panel-block">
                    Име и презиме, град, телефон:друг телефон, стартна сума, црна листа
                </div>
                <div  class="panel-block ">
                    <textarea class="input is-fullwidth" name="users" v-model="csv" style="height: 200px"
                              placeholder="име и презиме, град, телефон:друг телефон, стартна сума, црна листа">

                    </textarea>
                </div>
                <div class="panel-block is-block">
                    <div class="table-container is-fullwidth">
                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>Име и презиме</th>
                                <th>Град</th>
                                <th>Телефонски броеви</th>
                                <th>Стартна сума</th>
                                <th>Црна листа</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr  :class="{'has-text-danger':user.duplicated}" v-for="(user, key) in users" :key="key">

                            <td>{{user.name}}</td>
                            <td>{{user.city_id ? user.city+"#"+user.city_id : "Нема"}}</td>
                            <td><p v-for="phone in user.phones" :key="phone">{{phone}}</p></td>
                            <td>{{user.start_spent}} ден.</td>
                            <td>{{user.black_list ? "Да" : "Не"}}</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                <div class="panel-block">
                    <button :class="{'is-loading': loading}"  @click="saveIt" class="button is-link  is-fullwidth">
                        Зачувај
                    </button>
                </div>
            </article>
        </div>
        <router-view></router-view>
    </section>
</template>
<script>


    export default {
        name: "ImportUsers",
        data(){
            return{
                csv:"",
                loading:false,
            }
        },
        methods:{
            saveIt(){
                this.loading = true;
                this.$http.post("/users", {users:this.users})
                .then((a)=>{
                    this.csv=""
                    this.$toast({type:"is-success", message: a.data.data.success})
                }).finally(()=> this.loading=false)
            }
        },
        computed:{
            getCity(){
                return (city) => this.$store.getters.getCityByName(city);
            },
            users(){
                return this.csv.split("\n").map(user=> {
                    const u = user.split(",");
                    const city = this.getCity(u[1] ? u[1].trim() : "fDFSDfsdfsdf")
                    return {
                        name: u[0].trim(),
                        city_id: city ? city.id : null,
                        city: city ? city.name : "Нема",
                        phones: u[2] ? u[2].split(":").map(phone=>
                            phone.trim().replace(/-/g, "").replace(/ /g, "")
                        ) : [],
                        start_spent: parseInt(u[3] ? u[3].trim() : 0) || 0,
                        black_list: u[4] ? u[4].trim() === "1" : false
                    }
                }).map((user, i, users)=>{
                    user.duplicated = user.phones.some(a=>{
                        return users.some(user2=>{
                            if(user2===user)return false;
                            return user2.phones.some(phone=> phone === a)
                        })
                    })
                    return user;
                });
            }
        }

    }
</script>
