<template>
    <section class="section">
        <div class="container">
            <article class="panel is-info">
                <div class="panel-heading">
                    Асортиман
                    <router-link :to="{name:'createType'}" class="button is-success is-light pull-right is-small">
                        Креирај асортиман
                    </router-link>
                </div>
                <div class="panel-block is-block" v-for="type in types" v-bind:key="type.id">
                    <div class="columns is-mobile is-paddingless">
                        <div class="column is-1">{{type.id}}</div>
                        <div class="column">{{type.name}}</div>
                        <div class="column">
                            <div class="buttons is-right">
                                <router-link :to="{name:'editType', params:{typeId:type.id}}"
                                             class="is-small button is-link">
                                <span class="icon is-small">
                                    <i class="fa fa-pencil"></i>
                                </span>
                                </router-link>
                                <router-link :to="{name:'deleteType', params:{typeId: type.id}}"
                                             class="is-small button is-danger">
                                <span class="icon is-small">
                                    <i class="fa fa-remove"></i>
                                </span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-block" v-if="types.length === 0">
                    <Loading variant="is-white"/>
                </div>
            </article>
        </div>
        <router-view></router-view>
    </section>
</template>
<script>


    import Loading from "../../components/Loading";
    export default {
        name: "Types",
        components: {Loading},
        computed: {
            types: function () {
                return this.$store.getters.getTypes
            }
        }
    }
</script>
