<template>
    <div class="modal is-active">
        <div class="modal-background" v-on:click="$emit('closed')"></div>
        <div class="modal-content">
            <div class="notification" v-bind:class="variant">
                <button class="delete" v-on:click="$emit('closed')"></button>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"Message",
        props:["variant"],
    }
</script>