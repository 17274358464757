<template>
    <Modal title="Измени асортиман" @close="$router.push({name:'types'})">
        <div v-if="type">

            <div class="field">
                <label class="label">Назив</label>
                <div class="control">
                    <input class="input" :value="type.name" @input="type.name = $l2c($event.target.value)" type="text" placeholder="Малку лут">
                </div>
            </div>
        </div>
        <Loading v-else variant="is-white"/>
        <template v-slot:footer>
            <button class="button is-success" v-on:click="submitType" :disabled="!type">Зачувај</button>
        </template>
    </Modal>
</template>

<script>
    import Loading from "../Loading";

    export default {
        name: "EditTypeModal",
        components: {Loading},
        data() {
            return {
                type: null
            };
        },
        methods: {
            submitType() {
                this.$store.dispatch("editType", this.type).then(() => {
                    this.$router.push({name: "types"});
                })
            }
        },
        computed: {
            getType() {
                return this.$store.getters.getTypeById(this.$route.params.typeId);
            }
        },
        watch: {
            getType: function (val) {
                if (!this.type) this.type = this.$deepCopy(val);
            }
        },
        created() {
            this.type = this.$deepCopy(this.$store.getters.getTypeById(this.$route.params.typeId));

        }
    }
</script>

<style scoped>

</style>