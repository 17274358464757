<script>
    import {Line} from "vue-chartjs"
    import {months} from "../../assets/functions";

    export default {
        name: "UserOrdersChart",
        extends: Line,
        props: ['chartData'],
        data: () => ({
            options: {
                responsive: true,
                maintainAspectRatio: false
            }
        }),
        mounted() {
            const obj = {labels: [], datasets: []};
            obj.labels = this.chartData.map(a => months[parseInt(a.month.split("-")[1])] + " " + a.month.substr(2, 2));
            obj.datasets.push({
                label: "Сума",
                backgroundColor: "red",
                data: this.chartData.map(a => a.total)
            });
            this.renderChart(obj, this.options);
        }
    }
</script>
