<template>
    <Modal  title="Измени производство" @close="close">
        <div class="field">
            <label class="label">Количина</label>
            <div class="control" :class="{'is-loading':loading}">
                <input  class="input" min="0" :disabled="loading" v-model="data.quantity" type="number"
                       placeholder="0"/>
            </div>
        </div>
        <template v-slot:footer>
            <button class="button is-success" :disabled="loading" :class="{'is-loading':submitting}" v-on:click="submitProduction">Внеси</button>
        </template>
    </Modal>
</template>

<script>

    export default {
        name: "EditProductionModal",
        data(){
            return {
                submitting:false,
                loading:true,
                data:{
                    quantity:"",
                    product_id: this.$route.params.productId,
                    variant_id: this.$route.params.variantId
                }
            }
        },
        methods:{
            close(){
                this.$router.replace({name:'viewProduction', params:{productId:this.$route.params.productId, variantId:this.$route.params.variantId}})
            },
            submitProduction() {
                this.submitting = true;
                this.$http.patch("/products/" + this.data.product_id + "/variants/" + this.data.variant_id + "/production/"+this.data.id, this.data)
                    .then(() => {
                        this.$toast({message:"Успешно додадено производство", type:"is-success"})
                        this.$store.dispatch("getProducts")
                        this.close();
                    })
            },
        },

        created() {
            const productId = this.$route.params.productId;
            const variantId = this.$route.params.variantId;
            const productionId = this.$route.params.productionId;
            this.$http.get(`/products/${productId}/variants/${variantId}/production/${productionId}`)
            .then(a=>{
                this.loading=false
                this.data = Object.assign({}, this.data, a.data.data);
            });
        }
    }
</script>
