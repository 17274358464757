<template>
  <section class="section">
    <div class="container">
      <article class="panel is-info">
        <div class="panel-heading">
          Достави
          <router-link :to="{name:'createShippingMethod'}"
                       class="button is-success is-light pull-right is-small">
            Креирај
          </router-link>
        </div>
        <div class="panel-block is-block" v-for="sp in shippingMethods" v-bind:key="sp.id">
          <div class="columns is-mobile is-multiline is-paddingless">

            <div class="column is-1">{{ sp.id }}</div>
            <div class="column is-7-mobile"><b>{{ sp.name }}</b></div>
            <div class="column is-4-mobile" style="white-space: nowrap;">
              <span class="icon"><i class="fa fa-angle-right"></i></span>
              <span>{{ sp.min_price }} ден.</span>
            </div>

            <div class="column" v-if="sp.requires_address"><span class="icon"><i
                class="fa fa-address-book-o"></i></span></div>

            <div class="column">
              <span class="icon"><i v-if="sp.labeled" class="fa fa-tag"/></span>
            </div>
            <div class="column">
              <span v-if="sp.orders_count">{{ sp.orders_count }}</span>
              <span v-else>?</span>
              <span class="icon has-text-dark"><i class="fa fa-shopping-cart"/></span>
            </div>
            <div class="column is-narrow">
              <span v-if="sp.enabled" class="icon tag is-success"><i class="fa fa-check"></i></span>
              <span v-else class="icon tag is-danger"><i class="fa fa-ban"></i></span>
            </div>
            <div class="column is-narrow">
              <div class="buttons is-right">
                <router-link :to="{name:'orders', query:{shipping_method_id:sp.id}}"
                             class="is-small button is-light">
                  <span>Нарачки</span>
                  <span class="icon">
                                    <i class="fa fa-external-link"></i>
                                </span>
                </router-link>
                <router-link :to="{name:'editShippingMethod', params:{shippingMethodId: sp.id}}"
                             class="is-small button is-link">
                                <span class="icon is-small">
                                    <i class="fa fa-pencil"></i>
                                </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-block" v-if="loading">
          <Loading variant="is-white"/>
        </div>
        <div class="panel-block" v-if="!loading && shippingMethods.length ===0">
          Нема пронајдено начини на достава
        </div>
        <div class="panel-block" v-if="has_more && !loading">
          <button v-on:click="loadMore" class="button is-primary is-fullwidth">Вчитај повеќе</button>
        </div>
      </article>
    </div>
    <router-view></router-view>
  </section>
</template>
<script>


import Loading from "../../components/Loading";

export default {
  name: "ShippingMethods",
  components: {Loading},
  data() {
    return {
      shippingMethods: [],
      loading: true,
      has_more: false,
      page: 1
    };
  },

  methods: {
    loadMore() {
      this.page += 1;
      this.fetchShippingMethods()
    },
    fetchShippingMethods(reset = false) {
      if (reset) this.page = 1;
      this.loading = true;
      this.$http.get('/shipping_methods' + this.$urify({...this.$route.query, page: this.page}))
          .then(a => {
            if (reset) this.shippingMethods = [];

            this.has_more = a.data.last_page > a.data.current_page;
            this.shippingMethods = this.shippingMethods.concat(a.data.data)
            this.loading = false;
          })
    }
  },

  created() {
    this.fetchShippingMethods(true);
  },
  mounted() {
    this.shippingMethods = this.$store.getters.getShippingMethods;
  },
  watch: {
    $route: function (to, fr) {
      if (to.name === fr.name || fr.name === "createShippingMethod" || fr.name === "editShippingMethod")
        this.fetchShippingMethods(to.query.page === fr.query.page);
    },

  },
}
</script>
