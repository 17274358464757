<template>
    <div>
        <p class="panel-heading ">
            <router-link style="color: white" class="is-size-3-tablet is-size-5-desktop" :to="{name:'viewClient', params:{clientId:user.id}}">
                {{user.name}}
                <span class="icon"><i class="fa fa-external-link"/></span>
            </router-link>
            <a class="delete is-medium pull-right" v-on:click="detachUser"/>
        </p>
        <div class="panel-block is-block" v-if="order.possible_duplicate">
            <article class="message is-warning">
                <div class="message-body">
                    Овој клиент има нарачка во последните 2 денa. <br />{{order.possible_duplicate.status | statusBy}}
                    <router-link :to="{name:'viewOrder', params:{orderId:order.possible_duplicate.id}}">Види нарачка</router-link>
                </div>
            </article>
        </div>
        <div class="panel-block is-block" v-if="user.black_list">
            <article class="message is-dark">
                <div class="message-body">
                    Овој клиент е на црната листа.
                </div>
            </article>
        </div>
        <p class="panel-block " style="background: rgb(235, 235, 235);">
            <b class="is-size-4-tablet is-size-6-desktop">Телефони:</b>
            <button v-on:click="showNewPhone = !showNewPhone" class="button is-link is-small is-rounded is-size-6-tablet is-size-7-desktop"
                    style="margin-left: auto">
                <span class="icon is-small"><i class="fa fa-plus"/></span>
                <span v-if="showNewPhone">Затвори</span>
                <span v-else>Нов</span>
            </button>
        </p>
        <AddClientNumber
                @reload="syncUser"
                v-if="showNewPhone"
                :user="user"
        />

        <ListPhoneItem
                :number="phone.number"
                :user="user" class="panel-block" v-bind:key="phone.number"
                v-for="phone in user.phones"
                @reload="syncUser"
        />
        <div class="panel-block" style="background: rgb(235, 235, 235);">
            <b class="is-size-4-tablet is-size-6-desktop">Адреси:</b>
            <button v-on:click="showNewAddress = !showNewAddress" class="button is-link is-small is-rounded is-size-6-tablet is-size-7-desktop"
                    style="margin-left: auto">
                <span class="icon is-small"><i class="fa fa-plus"/></span>
                <span v-if="showNewAddress">Затвори</span>
                <span v-else>Нова</span>
            </button>
        </div>
        <AddClientAddress v-if="showNewAddress" :url="'/orders/' + order.id + '/user/'+user.id+'/addresses'"
                          @setState="setOrder"/>

        <AddressListItem @setState="setOrder" @click.native="setAddress(add.id)" v-for="add in user.addresses" v-bind:key="add.id"
                         :address="add" :selected="order.address && add.id === order.address.id" :user="user"/>

        <div class="panel-block" v-if="order.address_id && !user.addresses.find(a=>a.id === order.address_id)" style="background: rgb(235, 235, 235);">
            <b>Селектирана адреса:</b>
        </div>
        <AddressListItem @setState="setOrder" v-if="order.address_id && !user.addresses.find(a=>a.id === order.address_id)" :address="order.address" :selected="true" :user="user"/>

        <div class="panel-block" v-if="user.description" style="background: rgb(235, 235, 235);">
            <b>Опис за клиент:</b>
        </div>
        <div v-if="user.description" class="panel-block is-block" >
            <i v-text="user.description"></i>
        </div>
    </div>
</template>

<script>
    import AddClientNumber from "../Clients/AddClientNumber";
    import AddClientAddress from "../Clients/Address/AddClientAddress";
    import ListPhoneItem from "../Clients/ListPhoneItem";
    import AddressListItem from "../Clients/Address/AddressListItem";

    export default {
        name: "UserInfo",
        components: {AddressListItem, ListPhoneItem, AddClientAddress, AddClientNumber},
        props: ["user", "order", "orderLoading"],
        data() {
            return {
                showNewAddress: false,
                showNewPhone: false,
            }
        },

        methods: {
            detachUser() {
                this.$emit("update:orderLoading", true);
                this.$http.delete("/orders/" + this.order.id + "/user")
                    .then(a => {
                        this.setOrder(a.data.data)
                        this.$emit("update:orderLoading", false);
                    })
            },

            syncUser(user) {
                let order = {...this.order};
                order.user = user;
                this.setOrder(order)
            },
            setAddress(id) {
                this.$http.patch("/orders/" + this.order.id + "/address/" + id)
                    .then(a => {
                        this.setOrder(a.data.data)
                    });
            },
            setOrder(order) {
                let update = Object.assign({}, this.order, order)
                this.$emit("setOrder", update);
            },
            checkEditStatus(order) {
                if (order.user) {
                    this.showNewPhone = order.user.phones.length === 0;
                    this.showNewAddress = order.user.addresses.length === 0;
                }
            }
        },
        watch: {
            order: {
                deep: true,
                handler(order) {
                    this.checkEditStatus(order);
                }
            }
        },
        created() {
            this.checkEditStatus(this.order);
        }
    }
</script>
<style>
</style>