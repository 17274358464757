<template>
    <div class="panel-block is-block">
        <div class="field has-addons">
            <p class="control is-expanded">
                        <span class="select is-fullwidth ">
                            <select class="is-size-4-tablet is-size-6-desktop" v-model="add.city_id">
                                <option :value="-1">Одбери град...</option>
                                <option v-for="c in cities" v-bind:key="c.id" :value="c.id">{{c.name}}</option>
                            </select>
                        </span>
            </p>
            <p class="control is-expanded">
                <input class="input is-fullwidth is-size-4-tablet is-size-6-desktop" placeholder="Населба..."
                       type="text"
                       list="municipalities"
                       @change="lazyMun = $event.target.value"
                       :value="add.municipality" @input="handleInputChange($event, 'municipality')">

            </p>

        </div>
        <div class="field has-addons">
            <p class="control is-expanded">
                <input class="input is-size-4-tablet is-size-6-desktop" list="streetNames" placeholder="Адреса"
                       type="text"
                       :value="add.street_name" @input="handleInputChange($event, 'street_name')">
            </p>
            <p class="control">
                <input class="input is-size-4-tablet is-size-6-desktop" placeholder="Број" style="width:6em" type="text"
                       :value="add.street_number" @input="handleInputChange($event, 'street_number')"
                       :disabled="!add.street_name">
            </p>
        </div>
        <div class="field is-grouped">
            <p class="control is-expanded">
                <input class="input is-size-4-tablet is-size-6-desktop is-fullwidth" :value="add.description"
                       @input="handleInputChange($event, 'description')" placeholder="Опис за адреса"
                       type="text">
            </p>
            <p class="control">
                <button v-on:click="addAddress" class="is-size-4-tablet is-size-6-desktop button is-success"
                        :class="{'is-loading':loading}">
                    <span class="icon is-small"><i class="fa fa-check"/></span>
                    <span>Зачувај</span>
                </button>
            </p>
        </div>
        <Fragment>
            <datalist id="municipalities">
                <option v-for="(mun, i) in municipalities" :key="i" >{{mun}}</option>
            </datalist>
            <datalist id="streetNames">
                <option v-for="(str, i) in streetNames" :key="i">{{str}}</option>
            </datalist>
        </Fragment>
    </div>
</template>

<script>
    import {Fragment} from "vue-fragment";

    export default {
        name: "AddClientAddress",
        props: ["url"],
        components: {Fragment},
        data() {
            return {
                lazyMun: "",
                loading: false,
                add: {
                    id: null,
                    city_id: -1,
                    street_name: "",
                    street_number: "",
                    description: "",
                    municipality: "",
                },
                samples: {}
            };
        },
        methods: {
            handleInputChange(e, input) {
                let sel = e.target.selectionStart;
                let val = this.$l2c(e.target.value).trim()
                if (input === 'street_number') val = val.toUpperCase();
                this.add[input] = val;
                this.$nextTick(() => {
                    e.target.selectionStart = e.target.selectionEnd = sel;
                })
            },
            addAddress() {
                this.loading = true;
                this.$http.post(this.url, this.add)
                    .then(a => {
                        this.$emit("setState", a.data.data);
                        this.$toast({message: "Успешно додадена адреса", type: "is-success"})

                    }).finally(() => this.loading = false);
            },
        },
        computed: {
            municipalities(){
                const muns = Object.keys(this.samples).filter(a=> this.samples[a].find(b=> b===this.add.street_name));
                return !this.add.street_name
                    ? Object.keys(this.samples)
                    : muns.length ? muns : [];
            },
            streetNames(){
                return !this.lazyMun //if not selected
                || !this.samples[this.lazyMun] //if not exists
                    ? Object.values(this.samples).reduce((prev, acc) => acc.concat(prev),[]).filter((v, i, a) => a.indexOf(v) === i).sort()
                    :this.samples[this.lazyMun] ? this.samples[this.lazyMun] : [];
            },
            cities: function () {
                return this.$store.getters.getCities;
            },
        },
        watch: {
            "add.city_id": function (val) {
                if (parseInt(val) === -1) {
                    this.samples = {};
                    return;
                }
                this.$http.get("/addresses?city_id=" + val)
                    .then(a => {
                        this.samples = a.data.data;
                    })
            },
            "add.street_name": function (val) {
                if (val === "") this.add.street_number = "";
            }
        }
    }
</script>

<style scoped>

</style>