import Vue from 'vue'
import Vuex from 'vuex'
import api from "../api/api";
import {asyncLocalStorage} from "../assets/functions";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('user-token') || '',
        status: '',
        user: JSON.parse(localStorage.getItem('user')) || {},
        sizes: JSON.parse(localStorage.getItem('sizes')) || [],
        types: JSON.parse(localStorage.getItem('types')) || [],
        products: JSON.parse(localStorage.getItem('products')) || [],
        cities: JSON.parse(localStorage.getItem('cities')) || [],
        shipping_methods:  JSON.parse(localStorage.getItem('shipping_methods')) || [],
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, payload) {
            state.status = 'success'
            state.token = payload.token
            state.user = payload.user
        },
        auth_error(state) {
            state.status = 'error'
        },
        set_sizes(state, sizes) {
            state.sizes = sizes
            asyncLocalStorage.setItem('sizes', JSON.stringify(sizes))
        },
        set_cities(state, cities) {
            state.cities = cities
            asyncLocalStorage.setItem('cities', JSON.stringify(cities))
        },
        set_shipping_methods(state, shipping_methods) {
            state.shipping_methods = shipping_methods
            asyncLocalStorage.setItem('shipping_methods', JSON.stringify(shipping_methods))

        },
        set_products(state, products) {
            state.products = products
            asyncLocalStorage.setItem('products', JSON.stringify(products))
        },

        set_types(state, types) {
            state.types = types
            asyncLocalStorage.setItem('types', JSON.stringify(types))

        },
        logout(state) {
            state.status = ''
            state.token = ''
            state.user = {}
        },
    },
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                api.post('/login', user)
                    .then(resp => {

                        const token = "Bearer " + resp.data.token
                        const user = resp.data.user

                        asyncLocalStorage.setItem('user-token', token)
                        asyncLocalStorage.setItem('user', JSON.stringify(user))
                        api.defaults.headers.common['Authorization'] = token
                        commit('auth_success', {token, user})
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('user-token')
                        reject(err)
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('user-token')
                localStorage.removeItem('user')
                delete api.defaults.headers.common['Authorization']
                resolve()
            })
        },
        getCities({commit}) {
            return new Promise((resolve, reject) => {
                api.get('/cities')
                    .then(resp => {
                        commit('set_cities', resp.data.data)
                        resolve(resp)
                    }).catch(err => {
                    reject(err)
                })

            })
        },
        getShippingMethods({commit}) {
            return new Promise((resolve, reject) => {
                api.get('/shipping_methods?enabled')
                    .then(resp => {
                        commit('set_shipping_methods', resp.data.data)

                        resolve(resp)
                    }).catch(err => {
                    reject(err)
                })

            })
        },
        getSizes({commit}) {
            return new Promise((resolve, reject) => {
                api.get('/sizes')
                    .then(resp => {
                        commit('set_sizes', resp.data.data)

                        resolve(resp)
                    }).catch(err => {
                    reject(err)
                })

            })
        },
        submitNewSize({dispatch}, size) {
            return new Promise((res, rej) => {
                api.post("/sizes", size)
                    .then(resp => {
                        dispatch('getSizes');
                        res(resp);
                    })
                    .catch(err => {

                        rej(err);
                    })
            });
        },
        editSize({dispatch}, size) {
            return new Promise((res, rej) => {
                api.patch("/sizes/" + size.id, size)
                    .then(resp => {
                        dispatch('getSizes');
                        res(resp);
                    })
                    .catch(err => {
                        rej(err);
                    })
            })
        },
        removeSize({dispatch}, sizeId) {
            return new Promise((res, rej) => {
                api.delete("/sizes/" + sizeId)
                    .then(resp => {
                        dispatch('getSizes');
                        res(resp);
                    })
                    .catch(err => {
                        rej(err);
                    })
            })
        },
        getTypes({commit}) {
            return new Promise((resolve, reject) => {
                api.get('/types')
                    .then(resp => {
                        commit('set_types', resp.data.data)

                        resolve(resp)
                    }).catch(err => {
                    reject(err)
                })

            })
        },
        submitNewType({dispatch}, type) {
            return new Promise((res, rej) => {
                api.post("/types", type)
                    .then(resp => {
                        dispatch('getTypes');
                        res(resp);
                    })
                    .catch(err => {
                        rej(err);
                    })
            });
        },
        editType({dispatch}, type) {
            return new Promise((res, rej) => {
                api.patch("/types/" + type.id, type)
                    .then(resp => {
                        dispatch('getTypes');
                        res(resp);
                    })
                    .catch(err => {
                        rej(err);
                    })
            })
        },
        removeType({dispatch}, typeId) {
            return new Promise((res, rej) => {
                api.delete("/types/" + typeId)
                    .then(resp => {
                        dispatch('getTypes');
                        res(resp);
                    })
                    .catch(err => {
                        rej(err);
                    })
            })
        },
        getProducts({commit}) {
            return new Promise((resolve, reject) => {
                api.get('/products')
                    .then(resp => {
                        if(!resp) reject()
                        commit('set_products', resp.data.data)

                        resolve(resp)
                    }).catch(err => {
                    reject(err)
                })

            })
        },
        submitNewProduct({dispatch}, product) {
            return new Promise((res, rej) => {
                api.post("/products", product)
                    .then(resp => {
                        dispatch('getProducts');
                        res(resp);
                    })
                    .catch(err => {

                        rej(err);
                    })
            });
        },
        editProduct({dispatch}, product) {
            return new Promise((res, rej) => {
                api.patch("/products/" + product.id, product)
                    .then(resp => {
                        dispatch('getProducts');
                        res(resp);
                    })
                    .catch(err => {
                        rej(err);
                    })
            })
        },
        removeProduct({dispatch}, productId) {
            return new Promise((res, rej) => {
                api.delete("/products/" + productId)
                    .then(resp => {
                        dispatch('getProducts');
                        res(resp);
                    })
                    .catch(err => {
                        rej(err);
                    })
            })
        },

        submitNewVariant({dispatch}, variant) {
            return new Promise((res, rej) => {
                api.post("/products/" + variant.product_id + "/variants", variant)
                    .then(resp => {
                        dispatch('getProducts');
                        res(resp);
                    })
                    .catch(err => {

                        rej(err);
                    })
            });
        },
        editVariant({dispatch}, variant) {
            return new Promise((res, rej) => {
                const form = new FormData();
                form.append('price', variant.price)
                form.append('can_order', variant.can_order ? '1' : '0')
                form.append('can_order_online', variant.can_order_online ? '1' : '0')
                if(variant.image){
                    form.append('image', variant.image)
                }
                form.append('_method', 'PATCH')
                api.post("/products/" + variant.product_id + "/variants/" + variant.id, form)
                    .then(resp => {
                        dispatch('getProducts');
                        res(resp);
                    })
                    .catch(err => {
                        rej(err);
                    })
            })
        },
        removeVariant({dispatch}, variant) {
            console.log(variant)
            return new Promise((res, rej) => {
                api.delete("/products/" + variant.product_id + "/variants/" + variant.id)
                    .then(resp => {
                        dispatch('getProducts');
                        res(resp);
                    })
                    .catch(err => {
                        console.log(err)
                        rej(err);
                    })
            })
        },

    },
    modules: {},
    getters: {
        isAuthenticated: state => !!state.token,
        authStatus: state => state.status,
        getUser: state => state.user,
        getCities: state => state.cities,
        getSizes: state => state.sizes,
        getTypes: state => state.types,
        getTypeById: state => (typeId)=> state.types.find(a=> a.id === parseInt(typeId)),
        getSizeById: state => (sizeId)=> state.sizes.find(a=> a.id === parseInt(sizeId)),
        getCityById: state => (cityId)=> state.cities.find(a=> a.id === parseInt(cityId)),
        getCityByName: state => (cityName)=> state.cities.sort((a,b)=>a.name.length-b.name.length).find(a=> a.name.toUpperCase().indexOf(cityName.toUpperCase()) !== -1),
        getCityNameById: (state, getters) => (cityId)=> {
            let city = getters.getCityById(cityId);
            return city ? city.name : "Нема град";
            },
        getShippingMethodById: state => (shippingMethodId)=> state.shipping_methods.find(a=> a.id === parseInt(shippingMethodId)),
        getProductById: state => (productId) => state.products.find(a=> a.id === parseInt(productId)),
        getProducts: state => state.products,
        getVariant: (state) => (variantId) => {
            let toReturn = null;
            state.products.forEach(p => {
                p.variants.forEach(v => {
                    if (v.id === parseInt(variantId)) {
                        toReturn = {...v, product: p.name, size: v.size.name, type: v.type.name};
                        toReturn.fullName = toReturn.product + " | " + toReturn.size.toLowerCase() + "-" + toReturn.type.toLowerCase();
                    }
                });
            });
            return toReturn;
        },
        getVariantNameById: (state, getters) => (variantId)=> {
            let variant = getters.getVariant(variantId);
            return variant ? variant.fullName : "Нема варијанта";
        },
        getShippingMethods: state => state.shipping_methods,
    }
})
