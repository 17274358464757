<template>
    <Message @closed="$emit('update:printModal', false)" variant="is-white">
        <h5 class="title is-5">Како сакате да продолжите?</h5>
        <CanPackStats :query="queryParent" @notIds="(ids)=> this.notIds = ids" class="mb-4"/>
        <div class="field ">
            <div class="control" >
                <input class="is-checkradio" id="freeDeliveryMessage" type="checkbox" v-model="freeDeliveryMessage">
                <label for="freeDeliveryMessage">Назначи дека се работи за бесплатна достава.</label>
            </div>
            <div class="control" >
                <input class="is-checkradio" id="printTable" type="checkbox" v-model="printTable">
                <label for="printTable">Испечати табела со производи.</label>
            </div>
            <div class="control" >
                <input class="is-checkradio" id="printMultiple" type="checkbox" v-model="printMultiple">
                <label for="printMultiple">Испечати белешка за секој пакет.</label>
            </div>
            <div class="control" >
                <input class="is-checkradio" id="setPacked" type="checkbox" v-model="setPacked">
                <label for="setPacked">Постави статус спакуван.</label>
            </div>
        </div>
        <div class="buttons">
            <router-link class="button is-success " :to="{name:'printOrders', query:query}">
                Испечати
            </router-link>
            <button @click="$emit('update:printModal', false)" class="button is-danger ">
                Назад
            </button>
        </div>
    </Message>
</template>

<script>

    import CanPackStats from "./CanPackStats";
    export default {
        name: "PrintModal",
        components: {CanPackStats},
        props:["queryParent"],
        data(){
            return{
                notIds:[],
                setPacked:true,
                printMultiple:true,
                printTable:false,
                freeDeliveryMessage:false,
            }
        },
        computed:{
            query(){
                let query = this.$deepCopy(this.queryParent)
                if(this.notIds.length)
                    Object.assign(query, {notIds: this.notIds.join(",")})
                if(this.setPacked)
                    Object.assign(query,{set_packed:true})
                if(this.printMultiple)
                    Object.assign(query, {print_multiple:true})
                if(this.printTable)
                    Object.assign(query, {print_table:true})
                if(this.freeDeliveryMessage)
                    Object.assign(query, {free_delivery_message:true})
                return query;
            }
        }
    }
</script>

