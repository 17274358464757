<template>
    <div class="column is-half my-4">
        <p v-if="$route.query.free_delivery_message" class="has-text-weight-bold has-text-centered pb-2">Бесплатна достава ✅</p>
        <p class="has-text-centered">Нарачка број: #{{order.id}} | {{order.shipping_method.name}}</p>
        <h3 class="title is-2 has-text-centered">{{order.user.name}}</h3>
        <h4 class="subtitle is-4 has-text-centered">
            <span v-for="phone in order.user.phones" :key="phone.number">☎ {{phone.number | phoneNumber}}</span>
        </h4>
        <div v-if="order.address">
            <h4 class="title is-3 has-text-centered is-uppercase">{{order.address | formatCity}}</h4>
            <h5 class="subtitle is-4 has-text-centered">
                {{order.address | formatStreet}}
                <span class="icon" v-if="order.address.lat || order.address.long"><i class="fa fa-location-arrow"></i></span>
            </h5>
            <p class="has-text-centered is-size-5" v-if="order.address.description">
                {{order.address.description}}</p>
        </div>
        <div class="has-text-centered is-size-5" v-if="order.description">
            {{order.description}}
        </div>
        <div class="columns mt-5">
            <div class="column has-text-left" >
                <span v-if="order.packages !== 1 && $route.query.print_multiple" class="has-text-weight-bold ml-3 is-5 title">{{id}}/{{order.packages}}</span>
            </div>
            <div class="column has-text-centered is-italic ">
                <span v-if="id === 1">Сметката е под овој лист</span>
            </div>
            <div class="column has-text-right" style="white-space: nowrap">
                {{order.items.reduce((acc, a)=> acc+a.quantity, 0)}} <span style="white-space:nowrap; border: 1px solid dimgray" class=" has-text-weight-bold py-1 px-1 title is-5 ">{{order.final_total.toLocaleString("mk")}} ден</span>
            </div>
        </div>

    </div>

</template>

<script>
    export default {
        name: "PrintOrderUserInfo",
        props:{
            id:Number,
            order:Object,
        }
    }
</script>