<template functional>
    <span @click.capture="props.showOrdering && $event.preventDefault()">
    <router-link :to="{name:'viewOrder', params:{orderId:props.order.id}}" class="panel-block is-block "
                 style="border-bottom: 1px solid rgb(220,220,220)">
        <div class="columns is-multiline is-vcentered">
            <!--            first row-->
            <div class="column is-full-touch">
                <div class="columns is-mobile">
                    <div class="column is-narrow">
                        <div class="field" @click.prevent="listeners.toggle">
                            <input class="is-checkradio " :id="'order_'+props.order.id" type="checkbox"
                                   :checked="props.order.selected">
                            <label :for="'order_'+props.order.id" v-text="props.order.id"></label>
                        </div>
                    </div>
                    <div class="column">
                        <h5 class="title is-5">{{props.order.user ? props.order.user.name : "Нема"}}</h5>
                        <h6 class="subtitle is-6">
                            <span v-if="props.order.address">
                                {{props.order.address | formatCity}}
                            </span>
                            <i v-else>Нема</i>
                        </h6>
                    </div>
                    <div class="column has-text-right-touch">
                        <b v-if="!parent.$route.query.shipping_method_id">{{props.order.shipping_method.name}}</b>
                        <div v-else>
                            <p><b><span>{{props.order.address | formatStreet}}</span><span class="icon"
                                                                                           v-if="props.order.address && props.order.address.lat"><i
                                    class="fa fa-location-arrow"></i></span></b></p>
                            <p><i v-if="props.order.address && props.order.address.description">{{props.order.address.description}}</i></p>

                        </div>
                    </div>
                </div>
            </div>

            <!--                second row-->
            <div class="column is-full-touch ">
                <div class="columns is-mobile">
                    <div class="column ">
                        <strong>{{props.order.quantity}}</strong> ставки | <strong>{{props.order.final_total}}</strong> ден.
                    </div>
                    <div class="column has-text-right ">
                        <p><span class="tag" :class="props.order.status | statusClass">
                            {{props.order.status | statusBy}}
                        </span><br>
                        <span class="tag" :title="props.order.status | statusTime">
                            {{props.order.status | statusTimeNow}}
                        </span>
                        </p>
                        <p class="is-italic" v-if="props.order.description">
                            {{props.order.description}}
                        </p>
                          <span class="tag is-success" v-if="props.order.online">Online</span>
                  <span class="tag is-danger" v-if="props.order.priority">Приоритет</span>
                    </div>
                </div>
            </div>
            <!--            description if exists-->
            <!--            third row-->
            <div class="column is-full-touch is-narrow-desktop" v-if="props.showOrdering && props.orderingPossible">
                <div class="columns is-mobile" v-if="props.showOrdering">
                    <div class="column">
                        <div class="field">
                            <div class="control has-icons-left">
                                <input class="input  is-narrow" style="width: 7em" type="number"
                                       @input="listeners.setShippingOrder($event.target.value)"
                                       :value="props.order.shipping_order"
                                       min="1"
                                       max="500"
                                       step="1"
                                       @click.prevent>
                                <span class="icon is-small is-left">
                                    <i class="fa fa-arrows-v"></i>
                                </span>
                            </div>
                        </div>

                    </div>
                    <div class="column">
                        <div class="field">
                            <div class="control has-icons-left">
                                <input class="input  is-narrow" style="width: 7em" type="number"
                                       @input="listeners.setDelivery($event.target.value)"
                                       :value="props.order.delivery"
                                       min="0"
                                       max="500"
                                       step="10"
                                       @click.prevent>
                                <span class="icon is-small is-left">
                                    <i class="fa fa-truck"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</span>
</template>

<script>

    export default {
        name: "OrderListItem",

        props: {
            order: Object,
            showOrdering: Boolean,
            orderingPossible: Boolean
        },
    }
</script>

