<template>
    <a class="panel-block">
        <span class="panel-icon">
            <i class="fa fa-address-book-o" aria-hidden="true"/>
        </span>
        <div>
            <h6 class="title is-6 is-size-4-tablet is-size-6-desktop">
                {{address | formatCity}}
                <span class="icon" v-if="address.lat && address.long"><i class="fa fa-location-arrow"></i></span>
            </h6>
            <h6 class="subtitle is-6 is-size-4-tablet is-size-6-desktop">
                {{address | formatStreet}}<br v-if="address.description && address.street_name">
                <i v-if="address.description">{{address.description}}</i>

            </h6>
        </div>

        <div style="margin-left:auto">
             <span v-if="selected" style="color:green" class="icon is-small">
                    <i class="fa fa-check"/>
             </span>
            <div class="dropdown is-hoverable is-right">
                <div class="dropdown-trigger">
                    <button class="button is-small ml-2 is-size-5-tablet is-size-7-desktop" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span class="icon is-small ">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                        <a :href="address | linkToCoords" target="_blank" v-if="address.lat && address.long" class="dropdown-item">Види координати на мапа</a>
                        <a :href="address | linkToAddress" target="_blank" class="dropdown-item">Види адреса на мапа</a>
                        <a @click="editAddress=true" class="dropdown-item">Измени</a>
                        <a @click="removeAddress(address.id)" class="dropdown-item">Избриши</a>
                    </div>
                </div>
            </div>
        </div>
        <EditClientAddress @save="saveAddress" v-if="editAddress" :show.sync="editAddress" :address="address"/>
    </a>
</template>

<script>
    import EditClientAddress from "./EditClientAddress";

    export default {
        name: "AddressListItem",
        components: {EditClientAddress},
        props: ["address","user", "selected", "linkable"],
        data() {
            return {
                editAddress: false
            };
        },
        methods:{
            saveAddress(address){
                let url ="/users/"+this.user.id+"/addresses/"+address.id;
                if(this.$route.name === "editOrder")
                    url = "/orders/"+this.$route.params.orderId+"+/user/"+this.user.id+"/addresses/"+address.id;
                this.$http.patch(url, address)
                    .then((a)=>{
                        this.editAddress=false;
                        this.$emit("setState", a.data.data);
                        this.$toast({message:"Успешно ја изменивте адресата", type:"is-success"});
                    });
            },
            removeAddress(id){
                this.$http.delete("/users/"+this.user.id+"/addresses/"+id)
                .then((a)=>{
                    this.$emit("setState", a.data.data);
                    this.$toast({message:"Успешно избришена адреса", type:"is-success"});
                });
            }
        }
    }
</script>