<template>
    <div>
    <div v-if="!loading && variants.length">
        <PrintOrder :order="order" v-for="order in data" :key="order.id"/>
        <div class="pagebreak"></div>
        <OrdersStatisticsTable  v-if="$route.query.print_table" :stats="stats"/>
    </div>
    <Loading v-else/>
    </div>
</template>

<script>
    import PrintOrder from "./PrintOrder";
    import Loading from "../Loading";
    import OrdersStatisticsTable from "../Orders/OrdersStatisticsTable";
    export default {
        name: "PrintOrderContainer",
        components: {OrdersStatisticsTable, Loading, PrintOrder},
        data(){
            return {
                loading:true,
                stats:[],
            }
        },
        methods:{
            fetchOrders() {
                this.$http.get("/orders"+this.$urify({...this.$route.query, print:true}))
                    .then(a => {
                        this.data = a.data.data.orders;
                        this.stats = a.data.data.stats;
                        this.loading = false;
                    })

            },

        },
        computed:{
            variants: function () {
                return this.$store.getters.getProducts;
            }
        },
        updated() {
            if(this.data.length && this.variants.length)
                window.print();
        },
        created() {
            this.fetchOrders()

        }
    }
</script>
