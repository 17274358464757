<template>
    <section class="section">
        <div class="container">
            <article class="panel is-danger">
                <div class="panel-heading">
                    Продукти
                    <div class="pull-right">
                    <button @click="copyProducts" class="button is-small is-link">
                        <span class="icon"><i class="fa fa-copy"></i></span>
                    </button>&nbsp;
                    <div class="dropdown is-right is-hoverable" >
                        <div class="dropdown-trigger">
                            <button :class="{'is-loading': !product}" class="button is-small is-success is-light" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>Опции</span>
                                <span class="icon is-small">
                                            <i class="fa fa-angle-down" aria-hidden="true"/>
                                        </span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu" v-if="product">
                            <div class="dropdown-content">
                                <router-link :to="{name:'createProduct'}" class="dropdown-item">
                                   Нов продукт
                                </router-link>
                                <router-link :to="{name:'editProduct', params:{productId: product.id}}" class="dropdown-item">
                                    Измени продукт
                                </router-link>
                                <router-link :to="{name:'deleteProduct', params:{productId: product.id}}" class="dropdown-item">
                                    Избриши
                                </router-link>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="panel-tabs is-hidden-touch">

                    <router-link v-for="p in products" v-bind:key="p.id"
                                 v-bind:class="{'is-active':$route.params.productId===p.id}"
                                 :to="{name:'products', params:{productId:p.id}}">{{p.name}}
                    </router-link>
                </div>
                <div class="panel-block is-hidden-desktop is-block">
                    <div class="field">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select :value="$route.params.productId" @change="$router.push({name:'products', params:{productId:$event.target.value}})">
                                    <option :value="p.id" :key="p.id" v-for="p in products">{{p.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-block" style="background: rgb(235, 235, 235);">
                    <b>Варијанти:</b>
                    <router-link :to="{name:'createVariant', params:{productId:$route.params.productId}}" class="button is-success is-small" style="margin-left:auto" v-if="product">
                        Нова варијанта
                    </router-link>
                </div>
                <div v-if="product">
                    <ProductVariantListItem v-for="v in product.variants" v-bind:key="v.id" :product="product" :variant="v"/>
                </div>

                <div class="panel-block" v-if="productStats" style="background: rgb(235, 235, 235);">
                    <b>Залиха</b>
                    <div class="tags has-addons" v-if="productStats" style="margin-left: auto">
                        <span class="tag is-dark is-small">{{productStats.quantity.toLocaleString("mk")}} став.</span>
                        <span class="tag is-success is-small">{{productStats.total.toLocaleString("mk")}} ден.</span>
                    </div>
                </div>
                <div class="panel-block" v-if="productStats && productStats.mQuantity" style="background: rgb(235, 235, 235);">
                    <b>Во минус</b>
                    <div class="tags has-addons" v-if="productStats" style="margin-left: auto">
                        <span class="tag is-dark is-small">{{productStats.mQuantity.toLocaleString("mk")}} став.</span>
                        <span class="tag is-danger is-small">{{productStats.mTotal.toLocaleString("mk")}} ден.</span>
                    </div>
                </div>
                <div class="panel-block" style="background: rgb(235, 235, 235);">
                    <b>Опис</b>
                </div>
                <div class="panel-block">
                    <p>{{product && product.description}}</p>
                </div>
                <router-view></router-view>
            </article>
        </div>
    </section>
</template>

<script>

    import ProductVariantListItem from "../../components/Products/Variants/ProductVariantListItem";

    export default {
        name: "Products",
        components: {ProductVariantListItem},
        methods: {
            copyProducts(){
                let copy = "Моментална листа на производи: \n\n";
                this.products.forEach(p=>{
                    const can_order = p.variants.filter(v=> v.can_order);
                    if(can_order.length)copy+=p.name+":\n"

                    can_order.forEach(v=>{
                        const variant = "  - "+v.type.name+"-"+v.size.name+" | "+v.price+" ден.\n";
                        copy+=variant;
                    });
                })

                this.$copyText(copy).then(() => {
                    this.$toast({message: "Успешно копирани производи", type: "is-success"})
                })
            },

            makeDefaultRoute(){
                const val = this.products;
                if(!this.$route.params.productId && val.length){
                    this.$router.replace({
                        name: "products",
                        params: {productId: val[0].id}
                    });
                }
            }

        },
        computed: {
            productStats:function(){
                if(!this.product) return null;
                const variants =  this.product.variants.filter(a=> a.available > 0);
                const mVariants =  this.product.variants.filter(a=> a.available < 0);
                return {
                    quantity:variants.reduce((sum,a)=> sum+a.available,0),
                    total:variants.reduce((sum,a)=> sum+a.available*a.price,0),
                    mQuantity:mVariants.reduce((sum,a)=> sum+Math.abs(a.available),0),
                    mTotal:mVariants.reduce((sum,a)=> sum+Math.abs(a.available)*a.price,0),
                }
            },
            product: function () {
                return this.$store.getters.getProductById(this.$route.params.productId);
            },
            products: function () {
                return this.$store.getters.getProducts
            },

        },
        watch: {
            products: function () {
               this.makeDefaultRoute();
            },
            $route: function () {
                this.makeDefaultRoute();
            }
        },
        created() {
            this.makeDefaultRoute();
            this.$store.dispatch("getProducts");
        }
    }
</script>
<style>
</style>