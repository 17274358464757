<template>
    <div>
        <Loading v-if="loading"/>
        <div v-else class="notification is-light"  :class="{'is-success' : ok, 'is-danger':!ok}">
            <p v-if="ok">Сите производи ги имате за пакување!</p>
            <div v-else>
                <ul>
                    <li v-for="(val,key) in negativeQuantity" :key="key">
                        {{variant(key).fullName}} за: {{val}} ставки
                    </li>
                </ul>
                <a @click="selectOnlyAvailable()" >Селектирај ги само нарачките за кои има залиха</a>
            </div>

        </div>
        <div v-if="deleted.length" class="field is-grouped is-grouped-multiline">
            <div class="control" v-for="order in deleted" :key="order.id" >
                    <span class="tag is-danger is-light">
                        {{order.user.name}} -
                        <a @click="returnOrder(order)">врати</a>
                    </span>
            </div>
        </div>
    </div>


</template>

<script>
    import Loading from "../Loading";
    export default {
        name: "CanPackStats",
        components: {Loading},
        props: {
            query:Object,
        },
        data(){
            return {
                loading:true,
                orders:[],
                deleted:[]
            }
        },
        methods:{
            fetchOrders() {
                this.$http.get("/orders"+this.$urify({...this.query, print:true}))
                    .then(a => {
                        this.orders = a.data.data.orders.filter(a=> a.current_status === 'SUBMITTED');
                        this.loading = false;
                    })

            },
            returnOrder(order){
                this.deleted.splice(this.deleted.indexOf(order), 1);
                this.orders.push(order);
            },
            selectOnlyAvailable(){

                while (Object.keys(this.negativeQuantity).length){
                    const toDelete = this.orders.filter(order=>
                        order.items.some(item=>
                            Object.keys(this.negativeQuantity).some(variant => parseInt(variant) === item.variant_id)))
                    .sort((a,b)=> a.created_at > b.created_at ? 1 : -1).pop();
                    this.deleted.push(toDelete);
                    this.orders.splice(this.orders.indexOf(toDelete), 1)
                }

            }

        },
        computed: {
            variant: function () {
                return id => this.$store.getters.getVariant(id)
            },

            groupVariantsOfSelectedOrders: function () {
                let groupedVariants = {};
                this.orders.forEach(order => {
                    order.items.forEach(variant => {
                        if (groupedVariants[variant.variant_id]) groupedVariants[variant.variant_id] += variant.quantity;
                        else groupedVariants[variant.variant_id] = variant.quantity;
                    })
                })
                return groupedVariants;
            },
            negativeQuantity: function () {
                let r = {};
                for (const [key, value] of Object.entries(this.groupVariantsOfSelectedOrders)) {
                    const val = this.variant(key).available_to_pack - value;
                    if (val < 0) r[key] = val;
                }
                return r;
            },
            ok:function () {
                return !Object.keys(this.negativeQuantity).length;
            },

        },
        created(){
            this.fetchOrders()
        },
        watch:{
            deleted: function (val) {
                this.$emit("notIds", val.map(a=> a.id))
            }
        }
    }
</script>
