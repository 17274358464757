import store from "../store/store";
import router from "../router/router";
import * as bulmaToast from "bulma-toast";
import {errorBag} from "../assets/functions";
import axios from "axios";

let api = axios.create({
    // baseURL: "http://192.168.1.17:8001/api/v1",
    baseURL: process.env.NODE_ENV === 'production' ? "https://api.dnp.mk/api/v1":"http://dnp_backend.test/api/v1",
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=utf-8'
    }
})
api.interceptors.request.use(
    config => {
        const token = localStorage.getItem("user-token");
        if (token) {
            config.headers.common["Authorization"] = token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
api.defaults.timeout = 15000;



api.interceptors.response.use(
    response => {
        if (response.status >= 200 && response.status <= 206) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 401:
                    return new Promise(function () {

                        store.dispatch("logout")
                        router.push("/login")
                        throw error;
                    });
                case 422:
                    bulmaToast.toast({ message: errorBag(error.response.data), type: "is-danger" });
                    break;
                case 404:
                    bulmaToast.toast({ message: "Страницата не е пронајдена", type: "is-danger" });
                    break;
                case 400:
                    bulmaToast.toast({ message: error.response.data.error, type: "is-danger" });

            }
        }else{
            // bulmaToast.toast({ message: "Проблем со интернет", type: "is-danger" });
        }
    },
);
api.CancelToken = axios.CancelToken;
api.isCancel = axios.isCancel;
export default api
