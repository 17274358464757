<template>
    <Modal title="Измени корисник"
           @close="$router.replace({name:'viewClient', params:{clientId: $route.params.clientId}})">
        <div v-if="user">

            <div class="field">
                <label class="label">Име и презиме</label>
                <div class="control">
                    <input class="input" :value="user.name" @input="handleNameChange" type="text"
                           placeholder="Име и презиме">
                </div>
            </div>
            <div class="field">
                <label class="label">Почетна сума</label>
                <div class="control">
                    <input class="input" v-model="user.start_spent" type="number"
                           placeholder="Почетна сума">
                </div>
            </div>
            <div class="field">
                <label class="label">Електронска пошта</label>
                <div class="control">
                    <input class="input" inputmode="email" v-model="user.email" type="email"
                           placeholder="Електронска пошта">
                </div>
            </div>
            <div class="field">
                <label class="label">Нова лозинка</label>
                <div class="control">
                    <input class="input" v-model="user.password" type="password"
                           placeholder="Лозинка">
                </div>
                </div>
            <div class="field">
                <div class="control">
                    <input class="input" v-model="user.password_confirmation" type="password"
                           placeholder="Повторете лозинка">
                </div>
            </div>
            <div class="field">
                <div class="control">
                    <input class="is-checkradio " id="is_admin" type="checkbox"
                           v-model="user.is_admin">
                    <label for="is_admin">Дали е админ</label>
                </div>
            </div>

        </div>
        <Loading v-else variant="is-white"/>

        <template v-slot:footer>
            <button class="button is-success" v-on:click="saveUser" :disabled="!user">Зачувај</button>
        </template>
    </Modal>
</template>

<script>
    import Loading from "../Loading";

    export default {
        name: "EditClientModal",
        components: {Loading},
        data() {
            return {
                user: null,
                loading: true,
            };
        },
        methods: {
            handleNameChange(e){
                let sel = e.target.selectionStart;
                this.user.name = this.$l2c(this.$rbn(e.target.value)).trim()
                this.$nextTick(()=>{
                    e.target.selectionStart = e.target.selectionEnd = sel;
                })
            },
            saveUser() {
                const id = this.$route.params.clientId;
                this.$http.patch("/users/"+ id+"?edit-user", this.user)
                .then(()=>{
                    this.$router.replace({name:'viewClient', params:{clientId: id}})
                    this.$toast({type:"is-success", message:"Успешно го изменивте клиентот"})
                })
            },
        },
        created() {
            this.$http.get("/users/" + this.$route.params.clientId)
                .then(a => {
                    this.user = Object.assign(a.data.data, {password: "", password_confirmation: ""});
                    this.loading = false;
                })
        },
    }
</script>

<style scoped>

</style>