<template functional>
    <a class="panel-block is-block py-4"  @click="listeners['setUserId'](props.client.id)">
        <div class="columns is-gapless is-mobile">
            <div class="column ">
                <p class="title is-6 is-size-4-tablet is-size-6-desktop">{{props.client.name}}</p>
                <p class="subtitle is-6 is-size-4-tablet is-size-6-desktop">
                    <span v-for="add in props.client.addresses" v-bind:key="add.id">
                        {{add | formatCity}}
                        <br />
                    </span>
                </p>
            </div>
            <div class="column is-narrow">
                <ul class="pull-right">
                    <li  v-for="num in props.client.phones" v-bind:key="num.number">
                        <span class="icon is-size-4-tablet is-size-6-desktop"><i class="fa fa-phone"/></span>
                        <span class="is-size-4-tablet is-size-6-desktop">{{num.number | phoneNumber}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </a>
</template>

<script>
    export default {
        name: "SelectClientItem",
        props: {
            client:Object
        }
    }
</script>
