import moment from "moment";

export function errorBag(errors) {
    let err = [];
    Object.values(errors.errors).forEach(a => {
        a.forEach(b => {
            err.push(b);

        })
    })
    return err.join(", ");
}

export const colors = [
    "red", "green", "maroon", "black", "gray", "chocolate", "navy", "yellow", "deepskyblue", "cyan", "lime", "purple", "orange", "salmon", "pink",
    "darkred", "olive", "lightblue", "slateblue", "darkmagenta", "blue", "red", "green", "maroon", "black", "gray", "chocolate", "navy", "yellow", "deepskyblue", "cyan", "lime", "purple", "orange", "salmon", "pink",
    "darkred", "olive", "lightblue", "slateblue", "darkmagenta", "blue",
];

export const orderStatuses = {
    "CREATED": "Креиран",
    "SUBMITTED": "Поднесен",
    "PACKED": "Спакуван",
    "SHIPPED": "Испратен",
    "DONE": "Завршен",
    "RETURNED": "Вратен",
    "CANCELED": "Откажан",
}
export const linkToCoordsFilter = (address) => {
    return "http://www.google.com/maps/place/" + address.lat + "," + address.long;
}
export const sizeClassFilter = (size) => {
    switch (size) {
        case  '450гр':
            return 'has-text-link';
        default:
            return 'has-text-black';
    }
}
export const openCoords = (address) => {
    return window.open(linkToCoordsFilter(address))
};
export const openAddress = (address) => {
    return window.open(linkToAddressFilter(address));
};
export const linkToAddressFilter = (address) => {
    let ad = "";
    ad += address.street_name ? address.street_name + " " : "";
    ad += address.street_number ? address.street_number + " " : ""
    ad += address.municipality ? address.municipality + ", " : ""
    ad += address.city.name
    return "https://maps.google.com/?q=" + ad;
}
export const shippingMethodFilter = (sm) => {
    return sm.name /*+ (sm.min_price ? " - мин: " + sm.min_price + "ден." : "")*/
}
export const deepCopy = (obj) => {
    if (!obj) return null;
    return JSON.parse(JSON.stringify(obj));
}

export function phoneNumberFilter(value) {
    if (!value) return "";
    value = value.toString();
    if (value.length === 9) {
        return value.substring(0, 3) + "/" + value.substring(3, 6) + "-" + value.substring(6, 9)
    }
    return value;
}

export function formatCityFilter(value) {
    if (!value) return "Нема";
    return (value.municipality ? value.municipality + ", " : "") + value.city.name
}

export function formatStreetFilter(value) {
    if (!value) return "Нема";
    return (value.street_name ? value.street_name : "") + (value.street_number && value.street_name ? " " + value.street_number : "");
}

export function ordersCountFilter(c) {
    if (isNaN(c)) return 0;
    return c + " " + (c === 1 ? "нарачка" : "нарачки")
}

export function clientsCountFilter(c) {
    if (isNaN(c)) return 0;
    return c + " " + (c === 1 ? "клиент" : "клиенти")
}

// export function variantNameFilter(v) {
//     if (!v.size || !v.type) return null;
//     return v.product + " | " + v.size.toLowerCase() + "-" + v.type.toLowerCase();
// }
export function statusByFilter(status) {
    if (!status) return "";
    return orderStatuses[status.status] + " од "
        + status.created_by_user.name.split(" ")[0];
}

export function statusTimeFilter(status) {
    if (!status) return "";
    return moment(status.created_at).format("MMM Do YY HH:mm ч");
}
export function statusTimeNowFilter(status){
    if (!status) return "";
    return moment(status.created_at).fromNow();
}

export function statusFilter(status) {
    if (!status) return "";
    return statusByFilter(status) + " | "
        + statusTimeNowFilter(status)
}
export function linkPhoneNumberFilter(text) {
    return text.replace(/[0-9]{3}[ \-/]{0,2}[0-9]{3}[ \-/]{0,2}[0-9]{3}/, (a)=>"<a href=\"tel:"+a+"\">"+a+"</a>");
}
export function statusClassFilter(status) {
    if (!status) return "";
    switch (status.status) {
        case "CREATED":
        case "SUBMITTED":
            return "is-dark"
        case "PACKED":
            return "is-warning";
        case "SHIPPED":
            return "is-info";
        case "DONE":
            return "is-success";
        case "CANCELED":
        case "RETURNED":
            return "is-danger"
    }

}

export const months = {
    1: "Јануари",
    2: "Февруари",
    3: "Март",
    4: "Април",
    5: "Мај",
    6: "Јуни",
    7: "Јули",
    8: "Август",
    9: "Септември",
    10: "Октомври",
    11: "Ноември",
    12: "Декември",
}

export const mapSettings = {
    zoom: 14
};

export const urify = (query, exclude = []) => {
    let keys = Object.keys(query)
        .filter(a => !exclude.includes(a))
        .map(a => a + "=" + query[a])
        .join("&");
    return keys ? "?"+keys : "";
};

export const asyncLocalStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};

export const rbn = (val) => {
    return val.replaceAll(/(\d)([ /-]+)(\d)/, "$1$3");
};

String.prototype.replaceAll = function (search, replace) {
    return this.replace(new RegExp(search, 'g'), replace)
}
export const l2c = (val) => {
    var a = {
        "A": "А",
        "B": "Б",
        "V": "В",
        "G": "Г",
        "D": "Д",
        "}": "Ѓ",
        "E": "Е",
        "|": "Ж",
        "Z": "З",
        "Y": "Ѕ",
        "I": "И",
        "J": "Ј",
        "K": "К",
        "L": "Л",
        "Q": "Љ",
        "M": "М",
        "N": "Н",
        "W": "Њ",
        "O": "О",
        "P": "П",
        "R": "Р",
        "S": "С",
        "T": "Т",
        '"': "Ќ",
        "U": "У",
        "F": "Ф",
        "H": "Х",
        "C": "Ц",
        ":": "Ч",
        "X": "Џ",
        "{": "Ш",
        "a": "а",
        "b": "б",
        "v": "в",
        "g": "г",
        "d": "д",
        "]": "ѓ",
        "e": "е",
        "\\": "ж",
        "z": "з",
        "y": "ѕ",
        "i": "и",
        "j": "ј",
        "k": "к",
        "l": "л",
        "q": "љ",
        "m": "м",
        "n": "н",
        "w": "њ",
        "o": "о",
        "p": "п",
        "r": "р",
        "s": "с",
        "t": "т",
        "'": "ќ",
        "u": "у",
        "f": "ф",
        "h": "х",
        "c": "ц",
        ";": "ч",
        "x": "џ",
        "[": "ш",
    };
    return val.split('').map(function (char) {
        return a[char] || char;
    }).join("").replaceAll(/ +/, " ");
};
export  const lightOrDark = (color)=>{
    let r,g,b;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
    }
    else {

        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace(
                color.length < 5 && /./g, '$&$&'
            )
        );

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    let hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    return hsp < 140;
}
export const svgMarker = (text = "", color="209CEE") =>{
    // let dot = "";
    // if(important){
    //     dot = '<circle  r="20" cy="33.46751" cx="131.65" stroke="#000" fill="#ff0000"/>';
    // }
    return `data:image/svg+xml;utf-8, \
<svg xmlns="http://www.w3.org/2000/svg"  version="1.1"  x="0px" y="0px" width="38" height="38" viewBox="0 0 263.335 263.335" style="enable-background:new 0 0 263.335 263.335;" xml:space="preserve">
<g>
    <g xmlns="http://www.w3.org/2000/svg">
        <path d="M40.479,159.021c21.032,39.992,49.879,74.22,85.732,101.756c0.656,0.747,1.473,1.382,2.394,1.839   c0.838-0.396,1.57-0.962,2.178-1.647c80.218-61.433,95.861-125.824,96.44-128.34c2.366-9.017,3.57-18.055,3.57-26.864    C237.389,47.429,189.957,0,131.665,0C73.369,0,25.946,47.424,25.946,105.723c0,8.636,1.148,17.469,3.412,26.28" fill="%23${color}"/>
        <text x="50%" y="50%" font-weight="bold" dominant-baseline="middle" text-anchor="middle" font-family="sans-serif"  font-size="7em" fill="${lightOrDark(color) ? "white" : "black"}">${text}</text>
    </g>
    
</g>
</svg>`;
};
