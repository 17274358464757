<template>
    <Modal  title="Додајте производство" @close="close">
        <div class="field">
            <label class="label">Количина</label>
            <div class="control">
                <input class="input" min="0" v-model="data.quantity" type="number"
                       placeholder="0"/>
            </div>
        </div>
        <template v-slot:footer>
            <button class="button is-success" :class="{'is-loading':submitting}" v-on:click="submitProduction">Внеси</button>
        </template>
    </Modal>
</template>

<script>

    export default {
        name: "CreateProduction",
        data(){
            return {
                submitting:false,
                data:{
                    quantity:"",
                    product_id: this.$route.params.productId,
                    variant_id: this.$route.params.variantId
                }
            }
        },
        methods:{
            close(){
                this.$router.replace({name:'viewProduction', params:{productId:this.$route.params.productId, variantId:this.$route.params.variantId}})
            },
            submitProduction() {
                this.submitting = true;
                this.$http.post("/products/" + this.data.product_id + "/variants/" + this.data.variant_id + "/production", this.data)
                    .then(() => {
                        this.$toast({message:"Успешно додадено производство", type:"is-success"})
                        this.$store.dispatch("getProducts")
                        this.close();
                    })
            },
        }
    }
</script>
