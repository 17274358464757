<template>
    <Modal title="Промени начин на достава" @close="close">
        <div class="select is-fullwidth">
            <select v-model="shipping_method_id">
                <option value="0">Сите достави</option>
                <option v-for="sp in shipping_methods" :key="sp.id" :value="sp.id">
                    {{sp.name}}
                </option>
            </select>
        </div>

        <template v-slot:footer>
            <button @click="change" :class="{'is-loading':loading}" :disabled="shipping_method_id === '0'" class="button is-success">
                <span class="icon"><i class="fa fa-truck"/></span>
                <span>Промени</span>
            </button>
        </template>
    </Modal>
</template>

<script>
    export default {
        name: "ChangeMultipleShippingMethod",
        props:["changeShippingMethodModal", "selectedIds"],
        data(){
            return{
                shipping_method_id:'0',
                loading:false,
            }
        },
        methods:{
            close(){
                this.$emit('update:changeShippingMethodModal', false)
            },
            change(){
                this.loading = true;
                this.$http.patch("/orders?set_shipping_method_id="+this.shipping_method_id, {orders: this.selectedIds})
                    .then((a) => {
                        this.loading=false;
                        this.$toast({message: a.data.data.success, type: "is-success"})
                        this.$emit("reload")
                        this.close();
                    });
            }
        },
        computed:{
            shipping_methods: function () {
                return this.$store.getters.getShippingMethods;
            },
        }
    }
</script>

<style scoped>

</style>