<template functional>
    <router-link :to="{name:'viewClient', params:{clientId:props.user.id}}" class="panel-block is-block py-3" style="border-bottom: 1px solid rgb(220,220,220)">
        <div class="columns is-vcentered is-multiline">
            <div class="column">
                <div class="columns is-paddingless is-mobile">
                    <div class="column is-2">
                        {{props.user.id}}
                    </div>
                    <div class="column ">
                        <h5 class="title is-5">{{props.user.name}}</h5>
                    </div>
                    <div class="column  has-text-right-mobile">
                        <a class="is-block" v-for="phone in props.user.phones" :key="phone.number"
                           :href="'tel:'+phone.number" @click.stop>
                                <span class="icon">
                                    <i class="fa fa-phone"></i>
                                </span>
                            <span>{{phone.number |phoneNumber}}</span>
                        </a>
                        <i v-if="!props.user.phones.length">
                            Нема
                        </i>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="columns is-paddingless is-mobile">
                    <div class="column ">
                        <p v-for="add in props.user.addresses" :key="add.id" :title="add | formatStreet">
                            {{add | formatCity}}
                        </p>
                        <i v-if="!props.user.addresses.length">
                            Нема
                        </i>
                    </div>
                    <div class="column ">
                        <span class="icon"><i class="fa fa-money"></i></span>
                        <span>{{parseInt(props.user.start_spent) + (parseInt(props.user.total_spent) || 0 )}} ден.</span>
                    </div>
                    <div class="column  has-text-right">
                        <span class="tag is-success">{{props.user.orders_count | ordersCount}}</span>
                    </div>
                </div>
            </div>

        </div>
    </router-link>

</template>

<script>
    export default {
        name: "ClientListItem",
        props:{
            user: Object
        }
    }
</script>

