<template>
    <div>
        <div class="columns is-mobile is-vcentered avoid-break">
            <PrintOrderUserInfo :id="1" :order="order"/>
            <div class="column is-half my-4 " style="line-height: 1;">
                <p v-if="$route.query.free_delivery_message" class="has-text-weight-bold has-text-centered pb-2">Бесплатна достава ✅</p>

                <table style="font-size:16px;" class="table is-bordered is-striped is-narrow center">
                    <thead>
                    <tr>
                        <th colspan="3" class="has-text-centered"><b>Нарачани се следните производи:</b></th>
                    </tr>
                    </thead>
                    <tbody v-for="size in sizes" :key="size">
                        <tr><td colspan="3"  class="has-text-centered pb-3">
                            <b class="is-size-5"> --- {{size}} --- </b>
                        </td></tr>
                        <tr v-for="item in itemsOfSize(size)" :key="item.variant_id">
                            <td>
                                {{item.displayName}}
                            </td>
                            <td>
                                <b>{{item.quantity}}</b>×{{item.price}}
                            </td>
                            <td>
                                <b>{{item.total.toLocaleString("mk")}} ден.</b>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th>Вкупно ставки:</th>
                        <th colspan="2">{{order.items.reduce((acc, a)=> acc+a.quantity, 0)}} ставки</th>
                    </tr>
                    <tr v-if="order.discount || order.delivery">
                        <td colspan="2">Вкупно:</td>
                        <td>{{order.total.toLocaleString("mk")}} ден.</td>
                    </tr>
                    <tr v-if="order.discount">
                        <td colspan="2">Попуст:</td>
                        <td>-{{order.discount.toLocaleString("mk")}} ден.</td>
                    </tr>
                    <tr v-if="order.delivery">
                        <td colspan="2">Испорака:</td>
                        <td>+{{order.delivery.toLocaleString("mk")}} ден.</td>
                    </tr>
                    <tr>
                        <th colspan="2">За плаќање:</th>
                        <th>{{order.final_total.toLocaleString("mk")}} ден.</th>
                    </tr>
                    </tfoot>
                </table>
                <p class="has-text-centered" style="font-size:14px">
                  <span class="icon"><i class="fa fa-globe"></i></span><b>www.dnp.mk</b><br />
                    <span class="icon"><i class="fa fa-facebook-official"></i></span><b>Домашно направени
                    производи</b><br />
                    <span class="icon"><i class="fa fa-instagram"></i></span><b>homemade_negorci_gevgelija</b><br />

                    <span class="icon"><i class="fa fa-phone-square"></i></span><b>077/736-656</b>
                    <span class="icon"><i class="fa fa-phone-square"></i></span><b>078/989-478</b><br />
                    <i>Ви благодариме на довербата</i> 😊
                </p>
            </div>
        </div>
        <div v-if="$route.query.print_multiple">
        <div class="columns is-mobile avoid-break" v-for="x in Math.ceil((order.packages-1)/2)" :key="x">
            <PrintOrderUserInfo :id="x*2" :order="order"/>
            <PrintOrderUserInfo v-if="x*2+1 <= order.packages" :id="x*2+1" :order="order"/>
        </div>
        </div>
    </div>
</template>

<script>
    import PrintOrderUserInfo from "./PrintOrderUserInfo";

    export default {
        name: "PrintOrder",
        components: {PrintOrderUserInfo},
        props: ["order"],
        computed: {
            variant: function () {
                return id => {
                    return this.$store.getters.getVariant(id);
                }
            },
            sizes: function () {
                return [...new Set(this.order.items.map(a=> this.variant(a.variant_id).size))]
            },
            itemsOfSize: function () {
                return size => {
                    return this.order.items.map(a => {
                        let variant = this.variant(a.variant_id);
                        a.displayName = variant.product+" | "+variant.type;
                        a.size = variant.size;
                        return a;
                    }).filter(a=> a.size === size);
                }
            }
        }
    }
</script>
<style scoped>
    .columns{
        border-bottom:1px solid lightgray;
    }
</style>
