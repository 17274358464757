<template>
    <GoogleMapLoader
            :mapConfig="mapConfig"
            ref="map"
            @mapClick="mapClick"
    >
        <template slot-scope="{ google, map }">
            <GoogleMapMarker
                    v-for="marker in markers"
                    :key="marker.id"
                    :marker="marker"
                    :google="google"
                    :map="map"
            />
            <GoogleMapMarker
                    v-if="selectedLocation"
                    :marker="selectedLocation"
                    :google="google"
                    :map="map"
            />
        </template>
    </GoogleMapLoader>
</template>

<script>
    import GoogleMapLoader from "./GoogleMapLoader";
    import GoogleMapMarker from "./GoogleMapMarker";
    import {mapSettings} from "../../assets/functions";

    export default {
        name: "PickAddressMap",
        components: {GoogleMapMarker, GoogleMapLoader},
        props: ["locations", "address"],
        data() {
            return {
                center: {
                    lat: 41.1841091,
                    lng: 22.4798533
                }
            };
        },
        methods: {
            mapClick(e) {
                if (!this.address.lat && !this.address.long) {
                    this.address.lat = e.latLng.lat().toFixed(5);
                    this.address.long = e.latLng.lng().toFixed(5);
                }
            }
        },
        computed: {
            selectedLocation() {
                if (!this.address.lat || !this.address.long) return null;
                return {
                    id: "GPS",
                    position: {
                        lat: parseFloat(this.address.lat),
                        lng: parseFloat(this.address.long)
                    },
                    label:'✔',
                    iconColor:"35AC5E",
                    optimized:true,
                    zIndex: 10,
                    animation: "DROP",
                    change: (marker) => {
                        let latLng = marker.latLng;
                        let add = this.$deepCopy(this.address);
                        add.lat = parseFloat(latLng.lat().toFixed(5))
                        add.long = parseFloat(latLng.lng().toFixed(5))
                        this.$emit("update:address", add);
                    },
                    draggable: true,
                }
            },
            markers() {
                return this.locations?.map((a) => {
                    return {
                        id: a.id,
                        label:a.house_number,
                        iconColor:"FF3860",
                        infoWindow:`<p>${a.address}</p><p style="font-weight: bold">Извор: ${a.source}</p>`,
                        position: a.position,
                    }
                })
            },
            mapConfig() {
                return {
                    ...mapSettings,
                    disableDefaultUI: true,
                    gestureHandling: "cooperative",
                    center: this.selectedLocation ? this.selectedLocation.position : this.center
                }
            },
        },
        watch: {

            locations: function (loc) {
                let lat = loc.reduce((acc, a)=> acc+a.position.lat, 0.0);
                let lng = loc.reduce((acc, a)=> acc+a.position.lng, 0.0);
                if (loc.length) {
                    this.$refs.map.setCenter({
                        lat: lat/loc.length,
                        lng:lng/loc.length,
                    });
                }
            }
        },
    }
</script>

<style scoped>
    .google-map {
        width: 100%;
        height: 400px !important;
    }
</style>
