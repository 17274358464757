<template>
  <Modal title="Нова варијанта"
         @close="$router.replace({name:'products', params:{productId: $route.params.productId}})">

    <div class="field">
      <label class="label">Амбалажа</label>
      <div class="control">
        <div class="select is-fullwidth">
          <select v-model="variant.size_id" v-bind:disabled="variant.id"
                  class="is-fullwidth">
            <option value="-1">Одбери амбалажа</option>
            <option
                v-for="s in sizes"
                v-bind:value="s.id"
                v-bind:key="s.id"
                v-bind:disabled="product.variants
                                        .filter(a=> a.type_id === variant.type_id).
                                        some(a=> a.size_id  === s.id)"

            >{{ s.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">Асортиман</label>
      <div class="control">
        <div class="select is-fullwidth">
          <select v-model="variant.type_id" v-bind:disabled="variant.id"
                  class="is-fullwidth">
            <option value="-1">Одбери асортиман</option>
            <option
                v-for="t in types"
                v-bind:value="t.id"
                v-bind:key="t.id"
                v-bind:disabled="product.variants
                                        .filter(a=> a.size_id === variant.size_id).
                                        some(a=> a.type_id  === t.id)"
            >{{ t.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="field">
      <label class="label">Цена</label>
      <div class="control">
        <input class="input" min="0" v-model="variant.price" type="number"
               placeholder="100"/>
      </div>
    </div>
    <div class="field">
      <input class="is-checkradio " id="checkbox" type="checkbox"
             v-model="variant.can_order">
      <label for="checkbox">Нови нарачки</label>
    </div>

    <template v-slot:footer>
      <button class="button is-success" v-on:click="submitVariant">Зачувај</button>
    </template>
  </Modal>

</template>

<script>

export default {
  name: "CreateVariantModal",
  data() {
    return {
      variant: {
        product_id: this.$route.params.productId,
        can_order: false,
        can_order_online: true,

        size_id: -1,
        type_id: -1,
        price: 100,
      },
    };
  },
  computed: {
    sizes: function () {
      return this.$store.getters.getSizes
    },
    types: function () {
      return this.$store.getters.getTypes
    },
    product: function () {
      return this.$store.getters.getProductById(this.$route.params.productId);
    },
  },
  methods: {
    submitVariant() {
      this.$store.dispatch("submitNewVariant", this.variant).then(() => {
        this.$router.replace({name: "products", params: {productId: this.$route.params.productId}})
      })
    },

  }
}
</script>
