<template>
  <Modal title="Измени варијанта"
         @close="$router.replace({name:'products', params:{productId: $route.params.productId}})">
    <div v-if="variant">
      <div class="field">
        <label class="label">Амбалажа</label>
        <div class="control">
          <input class="input" :value="variant.size" disabled>
        </div>
      </div>
      <div class="field">
        <label class="label">Асортимани</label>
        <div class="control">
          <input class="input" :value="variant.type" disabled>

        </div>
      </div>

      <div class="field">
        <label class="label">Цена</label>
        <div class="control">
          <input class="input" min="0" v-model="variant.price" type="number"
                 placeholder="100"/>
        </div>
      </div>
      <div class="field">
        <input class="is-checkradio " id="can_order" type="checkbox"
               v-model="variant.can_order">
        <label for="can_order">Нови нарачки</label>
      </div>
      <div class="field">
        <input class="is-checkradio " id="can_order_online" type="checkbox"
               v-model="variant.can_order_online">
        <label for="can_order_online">Нови нарачки (online)</label>
      </div>

      <div class="file">
        <label class="file-label">
          <input class="file-input" type="file" name="resume" @change="changeFile">
          <span class="file-cta">
                    <span class="file-icon">
                        <i class="fa fa-upload"></i>
                    </span>
                    <span class="file-label">
                        Фотографија
                    </span>
                    </span>
        </label>
      </div>
      <img :src="previewImage" v-if="previewImage" style="width: 100px;"/>

    </div>

    <Loading variant="is-white" v-else/>
    <template v-slot:footer>
      <button class="button is-success" v-on:click="submitVariant">Зачувај</button>
    </template>
  </Modal>

</template>

<script>
import Loading from "../../Loading";

export default {
  name: "EditVariantModal",
  components: {Loading},
  data() {
    return {
      variant: null,
      previewImage: null
    };
  },
  computed: {
    getVariant() {
      return this.$store.getters.getVariant(this.$route.params.variantId)
    }
  },
  methods: {
    submitVariant() {
      this.$store.dispatch("editVariant", this.variant).then(() => {
        this.$router.replace({name: "products", params: {productId: this.$route.params.productId}})
      })
    },
    changeFile(event) {
      if (!event.target.files.length) return;
      this.variant.image = event.target.files[0]
      if (this.variant.image) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(this.variant.image)
      }
    }
  },
  watch: {
    getVariant: function (val) {
      if (!this.variant) this.variant = {
        ...this.variant,
        ...this.$deepCopy(val)
      };
    }
  },
  mounted() {
    this.variant = {
      ...this.$deepCopy(this.$store.getters.getVariant(this.$route.params.variantId)),
      image: null
    };
  }
}
</script>
