<template>
  <table class="table is-narrow is-bordered is-fullwidth">
    <thead>
      <tr>
        <th>Варијанта</th>
        <th>Кол.</th>
        <th>Вкупно</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="v in stats.variants" :key="v.variant_id">
        <td>{{ variantName(v.variant_id) }}</td>
        <td>{{ v.quantity }}</td>
        <td>{{ parseInt(v.total).toLocaleString("mk") }} ден.</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th>Вкупно ставки:</th>
        <th colspan="2">
          {{ stats.variants.reduce((acc, a) => acc + parseInt(a.quantity), 0) }}
          ставки
        </th>
      </tr>
      <tr>
        <th>Вкупно:</th>
        <th colspan="2">
          {{ parseInt(stats.total).toLocaleString("mk") }} ден.
        </th>
      </tr>
      <tr>
        <th>Вкупно попуст:</th>
        <th colspan="2">
          {{ parseInt(stats.discount).toLocaleString("mk") }} ден.
        </th>
      </tr>
      <tr>
        <th>Достава</th>
        <th colspan="2">
          {{ parseInt(stats.delivery).toLocaleString("mk") }} ден.
        </th>
      </tr>
      <tr>
        <th>За плаќање</th>
        <th colspan="2">
          {{ parseInt(stats.final_total).toLocaleString("mk") }} ден.
        </th>
      </tr>
      <tr>
        <th>Типс</th>
        <th colspan="2">
          {{ parseInt(stats.tips).toLocaleString("mk") }} ден.
        </th>
      </tr>
    </tfoot>
  </table>
</template>

<script>
export default {
  name: "OrdersStatisticsTable",
  props: ["stats"],
  computed: {
    variantName: function() {
      return (id) => this.$store.getters.getVariantNameById(id);
    },
  },
};
</script>
