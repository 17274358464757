<template>
    <section class="section">
        <div class="container">
            <article class="panel is-danger">
                <div class="panel-heading">
                    {{variant.fullName}}
                    <router-link
                            :to="{name:'createProduction', params:{productId:$route.params.productId, variantId:$route.params.variantId}}"
                            class="button is-success is-light pull-right is-small">
                        <span class="icon"><i class="fa fa-plus"></i></span>
                    </router-link>
                </div>
                <div class="panel-block is-block">
                    <div class="columns">
                        <div class="column has-text-centered-mobile">
                            Вкупно произведено: {{variant.number_produced}} ставки
                        </div>
                        <div class="column has-text-centered-mobile has-text-centered">
                            <b>Моментално на залиха: {{variant.available}} ставки</b>
                        </div>
                        <div class="column has-text-centered-mobile has-text-right">
                            Вкупно продадено: {{variant.number_sold}} ставки
                        </div>
                    </div>
                </div>
                <div class="panel-block is-block">
                    <div class="columns">
                        <div class="column has-text-weight-bold has-text-centered-mobile">
                            Вкупно произведено во година: {{totalInYear}} ставки
                        </div>
                        <div class="column">
                            <div class="control">
                                <div class="select is-fullwidth">
                                    <select
                                            :value="selectedYear"
                                            @change="handleYearChange($event)">
                                        <option v-for="year in years" :key="year" :value="year">{{year}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Loading v-if="loading" height="4"/>

                <div class="panel-block is-block" v-for="p in production" :key="p.id">
                    <div class="columns is-gapless is-mobile is-multiline">
                        <div class="column is-half-mobile">
                            {{p.created_by.name}}
                        </div>
                        <div class="column is-half-mobile has-text-right-mobile">
                            {{p.quantity}} ставки
                        </div>
                        <div class="column has-text-weight-bold">
                            {{p.created_at | moment('HH:mm, Do MMM')}}
                        </div>
                        <div class="column is-narrow">
                            <router-link
                                    :to="{name:'editProduction', params:{productId:$route.params.productId, variantId:$route.params.variantId, productionId:p.id}}"
                                    class="button is-link is-small">
                                <span class="icon is-small"><i class="fa fa-pencil"/></span>
                            </router-link>&nbsp;
                            <router-link
                                    :to="{name:'deleteProduction', params:{productId:$route.params.productId, variantId:$route.params.variantId, productionId:p.id}}"
                                    class="button is-danger is-small">
                                <span class="icon is-small"><i class="fa fa-remove"/></span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </article>
        </div>
        <router-view></router-view>
    </section>

</template>
<script>

    import Loading from "../components/Loading";

    export default {
        name: 'Production',
        components: {Loading},
        methods: {
            getProduction(reset) {
                this.loading = true;
                if(reset)this.production = [];
                this.$http.get(`/products/${this.$route.params.productId}/variants/${this.$route.params.variantId}/production?year=${this.selectedYear}`)
                    .then(resp => {
                        this.production = resp.data.data;
                        this.loading = false;
                    })
            },

            handleYearChange(e){
                const query = this.$deepCopy(this.$route.query)
                query.year = e.target.value;
                if(parseInt(query.year) === new Date().getFullYear()) delete query["year"];
                this.$router.replace({name: 'viewProduction', query})
            }

        },
        data() {
            return {
                production: [],
                loading: true,
            };
        },
        created() {
            this.getProduction(new Date().getFullYear());
        },
        computed: {
            selectedYear() {
                return this.$route.query.year || new Date().getFullYear()
            },
            years() {
                const end = new Date().getFullYear();
                const start = 2019;
                return Array.from({length: end - start + 1}, (v, i) => i + start);
            },
            totalInYear: function () {
                return this.production ? this.production.reduce((acc, a) => acc + a.quantity, 0) : 0
            },

            variant: function () {
                return this.$store.getters.getVariant(this.$route.params.variantId);
            },
        },
        watch: {
            $route(to, fr) {
                if (to.name === fr.name
                    || fr.name === 'editProduction'
                    || fr.name === 'deleteProduction'
                    || fr.name === 'createProduction')
                    this.getProduction(to.name === fr.name)
            }
        }
    }
</script>