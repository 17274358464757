<template>
    <div>
        <div class="tabs is-centered">
            <ul>
                <li :class="{'is-active':!$route.query.page}">
                    <router-link :to="{name:'secured', query:{...$route.query, page:undefined}}">Нарачки</router-link>
                </li>
                <li :class="{'is-active':$route.query.page === 'new-clients'}">

                    <router-link :to="{name:'secured', query:{...$route.query, page:'new-clients'}}">Нови клиенти
                    </router-link>
                </li>
                <!--                <li :class="{'is-active':$route.query.page === 'production'}">-->

                <!--                    <router-link :to="{name:'secured', query:{...$route.query, page:'production'}}">Производство-->
                <!--                    </router-link>-->
                <!--                </li>-->
                <!--                <li :class="{'is-active':$route.query.page === 'sold-quantity'}">-->

                <!--                    <router-link :to="{name:'secured', query:{...$route.query, page:'sold-quantity'}}">Продадено-->
                <!--                        количина-->
                <!--                    </router-link>-->
                <!--                </li>-->
                <li :class="{'is-active':$route.query.page === 'sold-total'}">

                    <router-link :to="{name:'secured', query:{...$route.query, page:'sold-total'}}">
                        Продадено износ
                    </router-link>
                </li>
                <li :class="{'is-active':$route.query.page === 'produced-sold'}">
                    <router-link :to="{name:'secured', query:{...$route.query, page:'produced-sold'}}">
                        Произведено - Продадено
                    </router-link>
                </li>
                <li :class="{'is-active':$route.query.page === 'options'}">
                    <router-link :to="{name:'secured', query:{...$route.query, page:'options'}}">Опции</router-link>
                </li>
            </ul>
        </div>
        <div>
            <div v-if="$route.query.page === 'options'">
                <h4 class="title is-4">Опции</h4>
                <h4 class="subtitle is-4">Групирана статистика</h4>

                <div class="field">
                    <div class="control">
                        <input class="is-checkradio" id="groupedStats"
                               @change="changeGroupedStats" type="checkbox"
                               :checked="groupedStats">
                        <label for="groupedStats">Групирај ги нарачките и клиентите во статистика</label>
                    </div>
                </div>

                <h4 class="subtitle is-4">Градови</h4>

                <div class="field is-grouped is-grouped-multiline">
                    <div class="control" :key="city.id" v-for="city in cities">
                        <input class="is-checkradio" :id="'city_'+city.id"
                               @change="unCheckIt(city.id, 'unCheckedCities')" type="checkbox"
                               :checked="unCheckedCities.indexOf(city.id) === -1">
                        <label :for="'city_'+city.id">{{city.name}}</label>
                    </div>
                </div>
                <h4 class="subtitle is-4">Варианти</h4>

                <div class="field is-grouped is-grouped-multiline" :key="p.id" v-for="p in products">
                    <div class="control" :key="variant.id" v-for="variant in p.variants">
                        <input class="is-checkradio" :id="'variant_'+variant.id"
                               @change="unCheckIt(variant.id, 'uncheckedVariants')" type="checkbox"
                               :checked="uncheckedVariants.indexOf(variant.id) === -1">
                        <label :for="'variant_'+variant.id">{{variantName(variant.id)}}</label>
                    </div>
                </div>
            </div>
            <div v-else-if="$route.query.page === 'produced-sold'">
                <div class="field">
                    <div class="control has-text-centered">
                        <div class="select">
                            <select name="range" v-model="selectedVariant">
                                <option value="-1">Одберете варијанта</option>
                                <optgroup :key="p.id" v-for="p in products" :label="p.name">
                                    <option :key="variant.id" v-for="variant in p.variants" :value="variant.id">
                                        {{variant.size.name+"-"+variant.type.name}}
                                    </option>
                                </optgroup>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <LineGraph :chart-data="dataSet"/>
        </div>
    </div>

</template>

<script>
    import LineGraph from "./Graphs/LineGraph";
    import {months} from "../../assets/functions";

    export default {
        name: "HomeGraphs",
        components: {LineGraph},
        props: ["stats"],
        data() {
            return {
                selectedVariant: -1,
                page: this.$route.query.page,
                unCheckedCities: JSON.parse(localStorage.getItem("unCheckedCities")) || [],
                uncheckedVariants: JSON.parse(localStorage.getItem("uncheckedVariants")) || [],
                groupedStats: localStorage.getItem("groupedStats") === 'true' || false
            };
        },
        methods: {
            changeGroupedStats() {
                this.groupedStats = !this.groupedStats;
                this.$asyncLocalStorage.setItem("groupedStats", this.groupedStats.toString());
                // localStorage.setItem("groupedStats", this.groupedStats.toString());

            },
            unCheckIt(id, type) {
                const ind = this[type].indexOf(id);
                if (ind === -1) {
                    this[type].push(id);
                } else {
                    this[type] = this[type].filter(a => a !== id);
                }
                // localStorage.setItem(type, JSON.stringify(this[type]))
                this.$asyncLocalStorage.setItem(type, JSON.stringify(this[type]))
            },
            isKeyNow(key) {
                switch (this.$route.query.range) {
                    case "day":
                        key = parseInt(key)
                        return {after: key > new Date().getHours()}
                    case "month":
                        key = parseInt(key)
                        return {after: key > new Date().getDate()}
                    case "year":
                        key = parseInt(key)
                        return {after: key > new Date().getMonth()}
                    case "all":
                        key = parseInt(key)
                        return {after: key > new Date().getFullYear()}
                    default:
                        return {after: false};
                }
            },
            groupStats(stats, label) {
                return [stats.reduce((acc, el) => {
                    el.data.forEach((dat, i) => {
                        acc.data[i] = (acc.data[i] ? acc.data[i] : 0) + parseInt(dat)
                    })
                    return acc;
                }, {borderColor: 'red', fill: false, label: label, backgroundColor: 'red', data: []})]
            }
        },
        computed: {
            variantName() {
                return (id) => this.$store.getters.getVariantNameById(id)
            },
            variant() {
                return (id) => this.$store.getters.getVariant(id)
            },
            labels: function () {
                const first = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
                const second = first.concat(["13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]);
                const third = second.concat(["24", "25", "26", "27", "28", "29", "30", "31"])
                switch (this.$route.query.range) {
                    case "day":
                        return {key: second, display: second.map(a => a + ":00"),}
                    case "month":
                        return {key: third, display: third}
                    case "year":
                        return {key: first, display: Object.values(months)};
                    case "all":
                        var years = Array.from(Array(10).keys()).map(a => a + (new Date().getFullYear() - 9)).map(a => a.toString())
                        return {key: years, display: years}
                    case "week":
                    default:
                        var start = this.$moment().startOf("week");
                        var i = 7
                        var arr1 = [];
                        var arr2 = [];
                        do {
                            arr1.push(start.format("MM-DD"))
                            arr2.push(start.format("dddd"))
                            start.add(1, "day")
                            i--;
                        } while (i > 0);
                        return {key: arr1, display: arr2}
                }
            },
            producedSoldDataSet: function () {
                return [{
                    borderColor: "green",
                    fill: false,
                    label: "произведено: " + this.variantName(this.selectedVariant),
                    data: this.labels.key.map(lbl => {
                        const zeroOrNull = (this.isKeyNow(lbl).after ? null : 0)
                        const e = this.stats.production[this.selectedVariant]
                        if (!e) return zeroOrNull;
                        const c = e.find(a => a.part === lbl);
                        return c ? c.total : zeroOrNull
                    })
                }, {
                    borderColor: "red",
                    fill: false,
                    label: "продадено: " + this.variantName(this.selectedVariant),
                    data: this.labels.key.map(lbl => {
                        const zeroOrNull = (this.isKeyNow(lbl).after ? null : 0)
                        const e = this.stats.sold_items[this.selectedVariant];
                        if (!e) return zeroOrNull
                        const c = e.find(a => a.part === lbl);
                        return c ? c.quantity : zeroOrNull
                    })
                }, {
                    borderColor: "yellow",
                    fill: false,
                    label: "залиха: " + this.variantName(this.selectedVariant),
                    data: this.labels.key.map(lbl => {
                        const zeroOrNull = (this.isKeyNow(lbl).after ? null : 0)
                        let variant = this.variant(this.selectedVariant);
                        if (!variant) return zeroOrNull;
                        let quantity = variant.available
                        const sold = this.stats.sold_items[this.selectedVariant];
                        const produced = this.stats.production[this.selectedVariant];
                        if (sold) {
                            quantity += sold.filter(a => a.part > lbl).reduce((acc, a) => acc + parseInt(a.quantity), 0)
                        }
                        if (produced) {
                            quantity -= produced.filter(a => a.part > lbl).reduce((acc, a) => acc + parseInt(a.total), 0)
                        }
                        return this.isKeyNow(lbl).after ? null : quantity;
                    })
                }];
            },
            ordersDataSet: function () {
                let stats = Object.keys(this.stats.orders).filter(city => this.unCheckedCities.indexOf(parseInt(city)) === -1)
                    .map((key, i) => {
                        return {
                            borderColor: this.$colors[i],
                            fill: false,
                            label: this.$store.getters.getCityNameById(key),
                            backgroundColor: this.$colors[i],
                            data: this.labels.key.map(lbl => {
                                const e = this.stats.orders[key].find(a => a.part === lbl);
                                return e ? e.total : 0
                            })
                        }
                    });
                if (this.groupedStats) stats = this.groupStats(stats, "Број нарачки")
                return stats;
            },
            clientsDataSet: function () {
                let stats = Object.keys(this.stats.clients).filter(city => this.unCheckedCities.indexOf(parseInt(city)) === -1)
                    .map((key, i) => {
                        return {
                            borderColor: this.$colors[i],
                            fill: false,
                            label: this.$store.getters.getCityNameById(key),
                            backgroundColor: this.$colors[i],
                            data: this.labels.key.map(lbl => {
                                const e = this.stats.clients[key].find(a => a.part === lbl);
                                return e ? e.total : 0
                            })
                        }
                    })

                if (this.groupedStats) stats = this.groupStats(stats, "Број клиенти")

                return stats
            },

            soldTotalDataSet: function () {
                let stats = Object.keys(this.stats.sold_items).filter(variant => this.uncheckedVariants.indexOf(parseInt(variant)) === -1)
                    .map((key, i) => {
                        return {
                            borderColor: this.$colors[i],
                            fill: false,
                            label: this.$store.getters.getVariantNameById(key),
                            backgroundColor: this.$colors[i],
                            data: this.labels.key.map(lbl => {
                                const e = this.stats.sold_items[key].find(a => a.part === lbl);
                                return e ? e.total : 0
                            })
                        }
                    })
                if(this.groupedStats) stats = this.groupStats(stats, "Продадено ставки")
                return stats;
            },
            dataSet() {
                const obj = {labels: this.labels.display, datasets: []};
                if (!this.stats) return obj;
                switch (this.$route.query.page) {
                    case "sold-total":
                        obj.datasets = this.soldTotalDataSet;
                        break;
                    case "produced-sold":
                        obj.datasets = this.producedSoldDataSet;
                        break
                    // case "sold-quantity":
                    //     return this.soldQuantityDataSet;
                    // case "production":
                    //     return this.productionDataSet;
                    case "new-clients":
                        obj.datasets = this.clientsDataSet;
                        break
                    default:
                        obj.datasets = this.ordersDataSet;
                        break;
                }
                return obj;
            },
            cities: function () {
                return this.$store.getters.getCities;
            },
            products: function () {
                return this.$store.getters.getProducts;
            }
        }
        ,
    }
</script>
