<template>
    <section class="section">
        <div class="container">
            <article class="panel is-danger">
                <div class="panel-heading">
                    Нарачки
                    <div class="pull-right dropdown is-right is-hoverable">
                        <router-link v-if="shippingMethod && shippingMethod.labeled"
                                     :to="{name:'map', query:$route.query}"
                                     class="button is-link is-light is-small mr-2"><span class="icon"><i
                                class="fa fa-map"></i></span></router-link>
                        <div class="dropdown-trigger">
                            <button class="button is-small is-success is-light" aria-haspopup="true"
                                    aria-controls="dropdown-menu">
                                <span>Опции</span>
                                <span class="icon is-small"><i class="fa fa-angle-down"
                                                               aria-hidden="true"/></span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">

                                <a @click="autoOrder" v-if="orderingPossible" class="dropdown-item">
                                    Подреди автоматски
                                </a>
                                <hr v-if="orderingPossible" class="dropdown-divider">

                                <a @click="printModal = true" class="dropdown-item">
                                    Испечати за пакување
                                </a>
                                <router-link :to="{name:'printOrdersTable', query:query}"
                                             class="dropdown-item">
                                    Испечати табела
                                </router-link>
                                <a @click="deliveryDocumentModal=true"
                                   class="dropdown-item">
                                    Испечати испратница
                                </a>
                                <hr class="dropdown-divider">
                                <div v-if="selectedIds.length">
                                    <a @click="setStatus('PACKED')" class="dropdown-item">
                                        Постави спакувани
                                    </a>
                                    <a @click="setStatus('SHIPPED')" class="dropdown-item">
                                        Постави испратени
                                    </a>
                                    <a @click="setStatus('DONE')" class="dropdown-item">
                                        Постави завршени
                                    </a>
                                    <hr class="dropdown-divider">
                                </div>
                                <hr class="dropdown-divider" v-if="selectedIds.length">
                                <a @click="setOrdering(true)" v-if="orderingPossible"
                                   class="dropdown-item">
                                    Напредни опции
                                </a>
                                <a v-if="selectedIds.length" @click="changeShippingMethodModal = true"
                                   class="dropdown-item">
                                    Промени начин на достава
                                </a>
                                <a @click="showOrdersNumbers=true" class="dropdown-item">
                                    Земи броеви
                                </a>
                                <hr class="dropdown-divider">
                                <a @click="orderStatsProducts = true"
                                   class="dropdown-item">
                                    Статистика по производ
                                </a>
                                <a @click="orderStatsCities = true"
                                   class="dropdown-item">
                                    Статистика по град
                                </a>
                            </div>
                        </div>
                    </div>
                    <a @click="showOrdering = false" v-if="showOrdering"
                       class="button is-danger is-light mr-1 is-small pull-right">
                        <span class="icon"><i class="fa fa-remove"></i></span>
                    </a>
                    <a @click="setOrdering(false)" v-if="showOrdering"
                       class="button is-success mr-1 is-small pull-right">
                        Зачувај
                    </a>
                </div>
                <div class="panel-block is-block ">
                    <div class="columns is-gapless is-mobile is-multiline is-ventered">
                        <div class="column is-narrow-tablet is-full-mobile">
                            <div class="field is-hidden-mobile">
                                <input class="is-checkradio" id="checkAll" @change="checkAll" type="checkbox">
                                <label for="checkAll" class="pl-4"></label>
                            </div>

                            <a class="button is-fullwidth is-hidden-tablet is-small" @click="checkAll">
                                {{checkNext ? "Селектирај ги сите" : "Деселектирај ги сите"}}
                            </a>
                        </div>
                        <div class="column is-full-mobile">
                            <div class="field has-addons">
                                <div class="control is-expanded">
                                    <input :value="$route.query.search"
                                           @input="handleSearchQueryChanged($event)"
                                           placeholder="Пребарај огласи" class="input">
                                </div>
                                <div class="control" v-if="$route.query.search">
                                    <button class="button is-danger " @click="handleSearchQueryChanged(null)">
                                        <span class="icon"><i class="fa fa-times"></i></span>
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div class="column is-half-mobile">
                            <div class="field has-addons">
                                <div class="control is-expanded">
                                    <div class="select is-fullwidth">
                                        <select :value="$route.query.variant_id || 0"
                                                @change="handleVariantChanged">
                                            <option value="0">Сите производи</option>
                                            <optgroup :key="p.id" v-for="p in products" :label="p.name">
                                                <option :key="variant.id" v-for="variant in p.variants"
                                                        :value="variant.id">
                                                    {{variant.size.name+"-"+variant.type.name}}
                                                </option>
                                            </optgroup>
                                        </select>
                                    </div>
                                </div>
                                <div class="control" v-if="$route.query.variant_id">
                                    <button class="button is-danger " @click="handleVariantChanged(null)">
                                        <span class="icon"><i class="fa fa-times"></i></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="column is-half-mobile">
                            <div class="field has-addons">
                                <div class="control is-expanded">
                                    <div class="select is-fullwidth">
                                        <select :value="$route.query.city_id || 0" @change="handleCityChanged">
                                            <option value="0">Сите градови</option>
                                            <option v-for="city in cities" :key="city.id" :value="city.id">
                                                {{city.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="control" v-if="$route.query.city_id">
                                    <button class="button is-danger " @click="handleCityChanged(null)">
                                        <span class="icon"><i class="fa fa-times"></i></span>
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div class="column is-half-mobile">
                            <div class="field has-addons">
                                <div class="control is-expanded">
                                    <div class="select is-fullwidth">
                                        <select :value="$route.query.status || 0" @change="handleStatusChanged">
                                            <option value="0">Сите статуси</option>
                                            <option v-for="(status,key) in statuses" :key="key" :value="key">
                                                {{status}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="control" v-if="$route.query.status">
                                    <button class="button is-danger " @click="handleStatusChanged(null)">
                                        <span class="icon"><i class="fa fa-times"></i></span>
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div class="column is-half-mobile">
                            <div class="field has-addons">
                                <div class="control is-expanded">
                                    <div class="select is-fullwidth">
                                        <select :value="$route.query.shipping_method_id || 0"
                                                @change="handleShippingMethodChanged">
                                            <option value="0">Сите достави</option>
                                            <option v-for="sp in shipping_methods" :key="sp.id" :value="sp.id">
                                                {{sp.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="control" v-if="$route.query.shipping_method_id">
                                    <button class="button is-danger " @click="handleShippingMethodChanged(null)">
                                        <span class="icon"><i class="fa fa-times"></i></span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="panel-block" v-if="loading">
                    <Loading variant="is-white"/>
                </div>
                <OrderListItem
                        v-for="order in orders"
                        v-bind:key="order.id"
                        :order.sync="order"
                        :showOrdering="showOrdering"
                        :orderingPossible="orderingPossible"
                        @setDelivery="(val)=> order.delivery=val"
                        @setShippingOrder="(val) => order.shipping_order = val"
                        @toggle="order.selected = !order.selected"
                />
                <div class="panel-block" v-if="!loading && orders.length">
                    Вкупно {{totalItems | ordersCount}}
                </div>

                <div class="panel-block" v-if="!loading && orders.length ===0">
                    Нема пронајдено нарачки
                </div>
                <div class="panel-block" v-if="has_more && !loading">
                    <button v-on:click="loadMore" class="button is-primary  is-fullwidth">Вчитај повеќе</button>
                </div>
            </article>
        </div>
        <router-view></router-view>

        <PrintModal :queryParent="query" v-if="printModal" :printModal.sync="printModal"/>

        <ChangeMultipleShippingMethodModal
                :changeShippingMethodModal.sync="changeShippingMethodModal"
                :selectedIds="selectedIds"
                @reload="fetchOrders"
                v-if="changeShippingMethodModal"/>
        <ClientsNumbersModal v-if="showOrdersNumbers" :show.sync="showOrdersNumbers" :query="query" endpoint="orders"/>
        <OrdersStatisticsCitiesModal v-if="orderStatsCities" :show.sync="orderStatsCities" :query="query"/>
        <OrdersStatisticsProductsModal v-if="orderStatsProducts" :show.sync="orderStatsProducts" :query="query"/>
        <DeliveryDocumentModal v-if="deliveryDocumentModal" :show.sync="deliveryDocumentModal" :query="query"/>
    </section>
</template>

<script>
    import OrderListItem from "../../components/Orders/OrderListItem";
    import Loading from "../../components/Loading";
    import PrintModal from "../../components/Orders/PrintModal";
    import ChangeMultipleShippingMethodModal from "../../components/Orders/ChangeMultipleShippingMethodModal";
    import ClientsNumbersModal from "../../components/Clients/ClientsNumbersModal";
    import OrdersStatisticsCitiesModal from "../../components/Orders/OrdersStatisticsCitiesModal";
    import OrdersStatisticsProductsModal from "../../components/Orders/OrdersStatisticsProductsModal";
    import DeliveryDocumentModal from "../../components/Orders/DeliveryDocumentModal";

    export default {
        name: "Orders",
        components: {
            DeliveryDocumentModal,
            OrdersStatisticsProductsModal,
            OrdersStatisticsCitiesModal,
            ClientsNumbersModal,
            ChangeMultipleShippingMethodModal, PrintModal, Loading, OrderListItem
        },
        data() {
            const cache = JSON.parse(localStorage.getItem("ordersCache"));

            return {
                deliveryDocumentModal: false,
                orderStatsCities: false,
                orderStatsProducts: false,
                showOrdersNumbers: false,
                printModal: false,
                changeShippingMethodModal: false,
                setOrderStatusModal: false,
                orders: cache || [],
                loading: true,
                has_more: false,
                cancelRequest: null,
                checkNext: true,
                totalItems: 0,
                timeoutQuery: null,
                showOrdering: false,
                page: 1
            };
        },
        methods: {
            autoOrder() {
                const sm = this.$route.query.shipping_method_id;
                if (!sm) return;
                this.$http.patch("/orders?auto-order-shipping-method=" + sm)
                    .then(a => {
                        this.$toast({message: a.data.data.success, type: "is-success"})
                        this.fetchOrders();
                    })
            },
            setStatus(status) {
                this.$http.patch("/orders?set-status=" + status, {orders: this.selectedIds})
                    .then((a) => {
                        this.$toast({message: a.data.data.success, type: "is-success"})
                        this.fetchOrders();
                    });
            },
            setOrdering(val) {
                this.showOrdering = val;
                //save order
                if (!val) {
                    const payload = this.orders.map(a => {
                        return {
                            id: a.id,
                            shipping_order: a.shipping_order,
                            delivery: a.delivery
                        };
                    });

                    this.$http.patch("/orders?order-edit-packages", {orders: payload})
                        .then((a) => {

                            this.$toast({message: a.data.data.success, type: "is-success"})
                            this.fetchOrders();
                        });
                }
            },
            fetchOrders: function (timeout = false, reset = true) {
                if (reset) this.page = 1;
                this.loading = true;

                if (this.cancelRequest) this.cancelRequest.cancel();
                clearTimeout(this.timeoutQuery);
                this.timeoutQuery = setTimeout(() => {
                    this.cancelRequest = this.$http.CancelToken.source();
                        const url = this.$urify({...this.$route.query, page: this.page});
                    this.$http.get("/orders" + url, {cancelToken: this.cancelRequest.token})
                        .then(a => {
                            if (!a) return;
                            this.loading = false;
                            a.data.data.forEach(b => b.selected = false)
                            if (reset)
                                this.orders = a.data.data;
                            else
                                this.orders = this.orders.concat(a.data.data)

                            this.$asyncLocalStorage.setItem("ordersCache", JSON.stringify(this.orders.slice(0, 50)))

                            // localStorage.setItem("ordersCache", JSON.stringify(this.orders.slice(0, 50)))
                            this.totalItems = a.data.total;
                            this.has_more = a.data.last_page > a.data.current_page;
                        })
                }, (timeout ? 300 : 0))

            },
            loadMore() {
                this.page += 1;
                this.fetchOrders(false, false);
            },
            checkAll() {
                this.orders.forEach(a => a.selected = this.checkNext)
                this.checkNext = !this.checkNext;
            },
            handleCityChanged(e) {
                const id = e ? e.target.value : "0";
                let copy = this.$deepCopy(this.$route.query);
                copy["city_id"] = id;
                if (isNaN(id) || id === "0") delete copy["city_id"];
                this.$router.push({name: 'orders', query: copy})
            },
            handleShippingMethodChanged(e) {
                const id = e ? e.target.value : "0";
                let copy = this.$deepCopy(this.$route.query);
                copy["shipping_method_id"] = id;
                if (isNaN(id) || id === "0") delete copy["shipping_method_id"];
                this.$router.push({name: 'orders', query: copy})
            },
            handleVariantChanged(e) {
                const id = e ? e.target.value : "0";
                let copy = this.$deepCopy(this.$route.query);
                copy["variant_id"] = id;
                if (isNaN(id) || id === "0") delete copy["variant_id"];
                this.$router.push({name: 'orders', query: copy})
            },
            handleStatusChanged(e) {
                const id = e ? e.target.value : "0";
                let copy = this.$deepCopy(this.$route.query);
                copy["status"] = id;
                if (id === "0") delete copy["status"];
                this.$router.push({name: 'orders', query: copy})
            },
            handleSearchQueryChanged(e) {

                const val = this.$l2c(this.$rbn(e ? e.target.value : ''));
                let copy = this.$deepCopy(this.$route.query);
                const changeRoute = copy["search"];
                copy["search"] = val;
                if (!val) delete copy["search"]
                let start = 0;
                if (e) start = e.target.selectionStart;
                //
                if (changeRoute)
                    this.$router.replace({name: 'orders', query: copy})
                else
                    this.$router.push({name: 'orders', query: copy})
                if (e)
                    this.$nextTick(() => {
                        e.target.selectionStart = e.target.selectionEnd = start;
                    })
            },
        },
        computed: {
            statuses: function () {
                let st = this.$deepCopy(this.$orderStatuses);
                delete st["CREATED"];
                return st
            },
            cities: function () {
                return this.$store.getters.getCities;
            },
            shipping_methods: function () {
                return this.$store.getters.getShippingMethods;
            },
            selectedIds: function () {
                return this.orders.filter(a => a.selected).map(a => a.id);
            },
            shippingMethod: function () {
                return this.$store.getters.getShippingMethodById(this.$route.query.shipping_method_id)
            },
            orderingPossible: function () {
                return !!this.$route.query.shipping_method_id && Object.keys(this.$route.query).length === 1 && this.shippingMethod && this.shippingMethod.labeled
            },
            products: function () {
                return this.$store.getters.getProducts;
            },
            query() {
                let query = this.$deepCopy(this.$route.query)

                if (this.selectedIds.length) {
                    query = {ids: this.selectedIds.join(',')}
                }
                return query;
            }
        },
        created() {
            this.fetchOrders();

        },
        watch: {
            $route: function (to, fr) {
                if (to.name === fr.name) {
                    this.fetchOrders(to.query.search !== fr.query.search);
                }
                this.showOrdering = false;
            },
            orderingPossible: function (val) {
                if (!val) this.showOrdering = false;
            }
        }
    }
</script>
