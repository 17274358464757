<script>
    import {Bar} from "vue-chartjs";

    export default {
        name: "UserVariantChart",
        extends: Bar,
        props: ["chartData"],
        data: () => ({
            options: {
                responsive: true,
                maintainAspectRatio: false
            }
        }),
        computed: {
            variant: function () {
                return id => {
                    return this.$store.getters.getVariant(id);
                }
            },
        },
        mounted() {
            const obj = {labels: [], datasets: []};
            obj.labels = this.chartData.map(a => {
                const variant = this.variant(a.variant_id)
                return variant ? variant.fullName : null;
            });
            obj.datasets.push({
                label: "Количина",
                backgroundColor: "red",
                data: this.chartData.map(a => a.quantity)
            })

            this.renderChart(obj, this.options);
        }
    }
</script>
