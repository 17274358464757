<template>
  <section class="section">
    <div class="container">
      <article class="panel is-danger">
        <div class="panel-heading is-flex" v-if="!loading">
          <div class="text-truncate is-flex-grow-1">
            {{ user.name }}
          </div>

          <div class="dropdown is-right is-hoverable">
            <div class="dropdown-trigger">
              <button
                class="button is-small is-success is-light"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
              >
                <span>Опции</span>
                <span class="icon is-small"
                  ><i class="fa fa-angle-down" aria-hidden="true"
                /></span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <router-link
                  :to="{ name: 'editClient', params: { clientId: user.id } }"
                  class="dropdown-item"
                >
                  Измени корисник
                </router-link>
                <a
                  v-if="user.black_list"
                  @click="patchUser({ black_list: false })"
                  class="dropdown-item"
                >
                  Избриши од црна листа
                </a>
                <a
                  v-else
                  class="dropdown-item"
                  @click="patchUser({ black_list: true })"
                >
                  Постави на црна листа
                </a>
                <a
                  v-if="user.allow_messages"
                  @click="patchUser({ allow_messages: false })"
                  class="dropdown-item"
                >
                  Не сака да добива пораки
                </a>
                <a
                  v-else
                  class="dropdown-item"
                  @click="patchUser({ allow_messages: true })"
                >
                  Сака да добива пораки
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-heading" v-else>
          <Loading variant="is-danger" />
        </div>
        <div class="panel-block is-block" v-if="user.owes_amount">
          <article class="message is-danger">
            <div class="message-body">
              Овој клиент должни вкупно <b>{{ user.owes_amount }} ден.</b>
            </div>
          </article>
        </div>
        <div class="panel-block is-block" v-if="user.black_list">
          <article class="message is-dark">
            <div class="message-body">
              Овој клиент е на црната листа.
            </div>
          </article>
        </div>
        <div class="columns is-multiline is-gapless">
          <div class="column is-full-touch">
            <p class="panel-block " style="background: rgb(235, 235, 235);">
              <b>Телефони:</b>
              <button
                v-on:click="showNewPhone = !showNewPhone"
                class="button is-link is-small is-rounded"
                style="margin-left: auto"
              >
                <span class="icon is-small"><i class="fa fa-plus"/></span>
                <span v-if="showNewPhone">Затвори</span>
                <span v-else>Нов</span>
              </button>
            </p>
            <AddClientNumber
              :user="user"
              @reload="fetchClient"
              v-if="showNewPhone"
            />

            <ListPhoneItem
              :number="phone.number"
              :user="user"
              class="panel-block"
              v-bind:key="phone.number"
              v-for="phone in user.phones"
              @reload="fetchClient"
            />

            <div
              v-if="user.phones.length === 0 && !showNewPhone"
              class="panel-block"
            >
              Нема телефонски броеви
            </div>
            <div class="panel-block" style="background: rgb(235, 235, 235);">
              <b>Адреси:</b>
              <button
                v-on:click="showNewAddress = !showNewAddress"
                class="button is-link is-small is-rounded"
                style="margin-left: auto"
              >
                <span class="icon is-small"><i class="fa fa-plus"/></span>
                <span v-if="showNewAddress">Затвори</span>
                <span v-else>Нова</span>
              </button>
            </div>
            <AddClientAddress
              v-if="showNewAddress"
              :url="'/users/' + user.id + '/addresses'"
              @setState="fetchClient"
            />

            <AddressListItem
              @setState="fetchClient"
              v-for="add in user.addresses"
              v-bind:key="add.id"
              :address="add"
              :linkable="true"
              :user="user"
            />

            <div
              v-if="user.addresses.length === 0 && !showNewAddress"
              class="panel-block"
            >
              Нема адреси
            </div>
            <div class="panel-block" style="background: rgb(235, 235, 235);">
              <b>Опис на клиентот:</b>
              <button
                class="button is-small is-rounded is-link"
                style="margin-left: auto"
                @click="editDescription = !editDescription"
              >
                <span class="icon"><i class="fa fa-edit"></i></span>
                <span v-if="editDescription">Затвори</span>
                <span v-else>Измени</span>
              </button>
            </div>
            <div class="panel-block is-block">
              <div class="field" v-if="editDescription">
                <div class="control">
                  <textarea
                    rows="2"
                    :value="user.description"
                    @input="
                      $event.target.value = $l2c($event.target.value);
                      descriptionInput = $event.target.value;
                    "
                    class="textarea"
                    placeholder="Коментар за клиент"
                  ></textarea>
                </div>
              </div>
              <div class="field" v-if="editDescription">
                <div class="control">
                  <button
                    @click="updateUserDescription"
                    class="button is-success"
                  >
                    <span class="icon"><i class="fa fa-save"></i></span>
                    <span>Зачувај опис</span>
                  </button>
                </div>
              </div>
              <p v-else>
                Опис:<br />
                <b v-if="user.description">{{ user.description }}</b>
                <i v-else>Нема</i>
              </p>
            </div>
          </div>
          <div class="column is-full-touch">
            <div class="panel-block" style="background: rgb(235, 235, 235);">
              Последни 10 нарачки:
              <router-link
                :to="{ name: 'orders', query: { client_id: user.id } }"
                class="button is-link is-small"
                style="margin-left:auto;"
              >
                Види ги сите нарачки
              </router-link>
            </div>
            <div v-if="user.last_orders.length && !loading">
              <ClientOrderListItem
                v-for="order in user.last_orders"
                :order="order"
                :key="order.id"
              />
            </div>
            <div class="panel-block" v-else-if="loading">
              <Loading variant="is-white" />
            </div>
            <div class="panel-block" v-else>
              <i>Корисникот се уште нема нарачки</i>
            </div>
          </div>
        </div>
        <div
          class="panel-block is-block"
          style="background: rgb(235, 235, 235);"
        >
          <b>Статистика:</b>
          <p class="pull-right tags are-small has-addons">
            <span class="tag is-success" v-if="!loading && user.tips"
              >+{{ loading ? 0 : user.tips }} ден</span
            >
            <span class="tag is-link"
              >{{
                loading
                  ? 0
                  : user.variant_statistics.reduce(
                      (acc, a) => acc + parseInt(a.quantity),
                      0
                    )
              }}
              ставки</span
            >
            <span class="tag is-link"
              >{{
                loading
                  ? 0
                  : user.start_spent +
                    user.variant_statistics.reduce(
                      (acc, a) => acc + parseInt(a.total),
                      0
                    )
              }}
              ден.</span
            >
          </p>
        </div>
        <div class="panel-block is-block ">
          <div class="columns is-multiline">
            <div class="column is-full-touch">
              <UserVariantChart
                v-if="!loading"
                :chartData="user.variant_statistics"
              />
            </div>
            <div class="column is-full-touch">
              <UserOrdersChart
                v-if="!loading"
                :chartData="user.order_statistics"
              />
            </div>
          </div>
        </div>
      </article>
    </div>
    <router-view></router-view>
  </section>
</template>

<script>
import AddClientNumber from "../../components/Clients/AddClientNumber";
import AddClientAddress from "../../components/Clients/Address/AddClientAddress";
import ListPhoneItem from "../../components/Clients/ListPhoneItem";
import UserOrdersChart from "../../components/Clients/UserOrdersChart";
import UserVariantChart from "../../components/Clients/UserVariantChart";
import ClientOrderListItem from "../../components/Clients/ClientOrderListItem";
import AddressListItem from "../../components/Clients/Address/AddressListItem";
import Loading from "../../components/Loading";

export default {
  name: "ViewClient",
  components: {
    Loading,
    AddressListItem,
    ClientOrderListItem,
    ListPhoneItem,
    AddClientAddress,
    AddClientNumber,
    UserOrdersChart,
    UserVariantChart,
  },
  data() {
    return {
      loading: true,
      descLoading: false,
      user: {
        name: "Се вчитува...",
        phones: [],
        addresses: [],
        order_statistics: [],
        last_orders: [],
        variant_statistics: [],
      },
      descriptionInput: "",
      editDescription: false,
      cancelUpdate: null,
      showNewPhone: false,
      showNewAddress: false,
    };
  },
  methods: {
    fetchClient() {
      this.$http.get("/users/" + this.$route.params.clientId).then((a) => {
        this.user = a.data.data;
        this.loading = false;
        this.showNewPhone = false;
        this.showNewAddress = false;
      });
    },
    updateUserDescription() {
      this.patchUser({ description: this.descriptionInput });
      this.editDescription = false;
    },
    patchUser(obj) {
      return this.$http.patch("/users/" + this.user.id, obj).then((a) => {
        this.user = a.data.data;
        this.$toast({ message: "Успешно зачувано!", type: "is-success" });
      });
    },
  },
  computed: {
    cities: function() {
      return this.$store.getters.getCities;
    },
  },
  watch: {
    $route(to, fr) {
      if (to.name === fr.name || fr.name === "editClient") this.fetchClient();
    },
  },
  created() {
    this.fetchClient();
  },
};
</script>
