<template>
    <OrderInfoBox v-if="marker.order" :order="marker.order" :show="false" />
</template>
<script>


    import OrderInfoBox from "./OrderInfoBox";
    export default {
        components: {OrderInfoBox},
        props: {
            google: {
                type: Object,
                required: true
            },
            map: {
                type: Object,
                required: true
            },
            marker: {
                type: Object,
                required: true
            }
        },
        watch: {
            marker: {
                deep: true,
                handler(val) {
                    this.changeIcon(val)
                    this.object.setPosition(new this.google.maps.LatLng({
                        lat: val.position.lat,
                        lng: val.position.lng
                    }));
                }
            }
        },
        methods: {
            changeIcon(marker) {
                let icon = null;
                if (marker.icon) icon = {url: marker.icon}
                else if (marker.label && marker.iconColor) {
                    icon = {
                        url: this.$svgMarker(marker.label, marker.iconColor)
                    }
                }
                this.object.setIcon(icon);
            }
        },
        data() {
            return {
                object: null,
            }
        },
        beforeDestroy() {
            this.object.setMap(null);
        },
        mounted() {
            let obj = {
                position: new this.google.maps.LatLng(this.marker.position),
                marker: this.marker,
                map: this.map,
                defaultMarker: 0.5,
            };
            if (this.marker.draggable) Object.assign(obj, {draggable: true})
            if (this.marker.optimized) Object.assign(obj, {optimized: true})
            if (this.marker.zIndex) Object.assign(obj, {zIndex: this.marker.zIndex})
            if (this.marker.icon) Object.assign(obj, {icon: this.marker.icon})
            else if (this.marker.label && this.marker.iconColor) {
                Object.assign(obj, {
                    icon: {
                        url: this.$svgMarker(this.marker.label, this.marker.iconColor)
                    }
                })
            } else if (this.marker.label) Object.assign(obj, {label: this.marker.label.toString()})
            if (this.marker.title) Object.assign(obj, {title: this.marker.title.toString()})
            if (this.marker.animation) Object.assign(obj, {animation: this.google.maps.Animation[this.marker.animation]})

            this.object = new this.google.maps.Marker(obj)
            if (this.marker.change) this.google.maps.event.addListener(this.object, 'dragend', this.marker.change);
            if (this.marker.infoWindow || this.marker.orderInfoWindow) {

                this.object.addListener("click", (e) => {
                    const infoWindow = new this.google.maps.InfoWindow({
                        // content: this.marker.infoWindow
                        content: this.marker.orderInfoWindow ? this.$children[0].$el.innerHTML : this.marker.infoWindow
                    });
                    infoWindow.setPosition(e.latLng);
                    infoWindow.open(this.map)
                })
            }
            if (this.marker.center) this.map.setCenter(this.marker.position)
        }
    }
</script>
