<template>
  <Modal title="Броеви" @close="close">
    <div v-if="!loading">
      <input type="number"  class="input" step="5" :value="chunks" @input="chunks=parseInt($event.target.value)" min="20"
             :max="numbers.length"/>

      <label  class="has-text-centered is-block mt-2" v-for="block in blocks" :key="block">
        <span v-if="blocks !== 1">Дел {{block}} - <a @click="onCopy(numbersText(block-1))">Копирај дел</a></span>
        <textarea   rows="10" class="textarea" :value="numbersText(block-1)" readonly></textarea>
      </label>
    </div>



    <Loading v-else variant="is-white"/>
    <template v-slot:footer>
      <button v-on:click="onCopy(null)" class="button is-success">
        <span class="icon"><i class="fa fa-copy"/></span>
        <span>Копирај ги сите</span>
      </button>
    </template>
  </Modal>
</template>

<script>
import Loading from "../Loading";

export default {
  name: "ClientsNumbersModal",
  components: {Loading},
  props: {
    endpoint: String,
    query: Object,
  },
  data() {
    return {
      numbers: [],
      loading: true,
      chunks: 20,
    };
  },
  computed: {
    numbersText() {
      return (page)=> this.numbers.slice(page*this.chunks, page*this.chunks+this.chunks).filter(a => a.startsWith("07")).join(", ")
    },
    allNumbers(){
      return this.numbers.filter(a => a.startsWith("07")).join(", ");
    },
    blocks(){
      return Math.ceil(this.numbers.length/(this.chunks|| 20))
    }
  },
  methods: {
    close() {
      this.$emit("update:show", false);
    },
    onCopy(toCopy) {
      this.$copyText(toCopy || this.allNumbers).then(() => {
        this.$toast({type:"is-success", message:"Успешно копирано"});
        if(!toCopy)this.close();
      })
    }
  },
  mounted() {
    const url = this.$urify(Object.assign({}, this.query, {phones: true}), ["page"]);
    this.$http.get("/" + this.endpoint + url)
        .then(a => {
          this.numbers = a.data.data;
          this.loading = false
          this.chunks = this.numbers.length;

        });
  }
}
</script>
