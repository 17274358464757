<template>
    <div class="column is-6-desktop is-6-tablet is-4-widescreen" v-if="allAvailable || variant.can_order || quantity">
        <div class="label">
            <div class="tags has-addons is-flex-wrap-nowrap">
                 <span class="tag is-medium is-size-5-tablet is-size-6-desktop"  :class="{'is-success' : quantity && newQuantity >= 20, 'is-danger': quantity &&  newQuantity < 0, 'is-warning': quantity &&  newQuantity < 20 && newQuantity >= 0}">
                {{variant.size.name.toLowerCase()}}-{{variant.type.name.toLowerCase()}}{{(20 > newQuantity && quantity  ? " ("+(newQuantity)+")" : "")}}

            </span>
                <span class="pull-right is-light  tag is-success is-size-6-tablet is-size-7-desktop  "
                      v-if="quantity">
                    <span>{{quantity * price}}</span>
            </span>
            </div>

        </div>
        <div class="field has-addons ">
            <p class="control ">
                <button class="button is-danger is-size-5-tablet is-size-6-desktop  " v-on:click="$emit('setItemQuantity', variant, -1)" :disabled="!can_edit"><span
                        class="icon"><i class="fa fa-minus"/></span>
                </button>
            </p>
            <p class="control is-expanded">
                <input class="input is-size-5-tablet is-size-6-desktop" v-bind:class="{'is-success': quantity}"
                       min="0" :value="quantity"
                       v-on:input="$emit('setItem','quantity', $event.target.value, variant)"
                       type="number"
                       max="10000"
                       placeholder="Количина"
                       :disabled="!can_edit">
            </p>
            <p class="control is-expanded ">
                <input class="input is-size-5-tablet is-size-6-desktop  " v-bind:class="{'is-success': quantity}"
                       min="0" :value="price"
                       max="1000"
                       v-on:input="$emit('setItem','price', $event.target.value, variant)"
                       type="number" placeholder="Цена"
                       :disabled="!can_edit">
            </p>
            <p class="control">
                <button class="button is-success is-size-5-tablet is-size-6-desktop  " v-on:click="$emit('setItemQuantity', variant, 1)" :disabled="!can_edit"><span
                        class="icon"><i class="fa fa-plus"/></span>
                </button>

            </p>

        </div>
    </div>
</template>

<script>
    export default {
        name: "EditOrderVariant",
        props: ["variant", "quantity", "price", "can_edit", "itemCache", "allAvailable"],
        computed:{
            newQuantity:function () {
                return this.variant.available+ (this.itemCache ? this.itemCache.quantity : 0) - this.quantity;
            }
        }
    }
</script>
